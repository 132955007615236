import { Table } from "antd";
import { useEffect, useState } from "react";
import { ContainerDiv } from "./styles";

export default function ChainTable({ data, columns, loading, isHeaderShow }) {
	const [pagination, setPagination] = useState({
		current: 1,
	});

	const fetchData = async (params = {}) => {
		await fetch(params.pagination.current);

		setPagination({
			...params.pagination,
		});
	};

	useEffect(() => {
		if (!data) return;
		setPagination({
			...pagination,
			total: data.totalCount,
			pageSize: data.pageSize,
			current: data.pageNumber,
			showTotal: (total) => `Total ${total} items`,
		});
	}, [data]);

	const handleTableChange = (newPagination, filters, sorter) => {
		fetchData({
			sortField: sorter.field,
			sortOrder: sorter.order,
			pagination: newPagination,
			...filters,
		});
	};

	return (
		<ContainerDiv>
			<Table
				showHeader={isHeaderShow}
				columns={columns}
				dataSource={data}
				pagination={false}
				onChange={handleTableChange}
				rowKey={(record) => record.id}
				loading={loading}
			/>
		</ContainerDiv>
	);
}

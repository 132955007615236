import React, { useEffect, useState } from "react";
import Image from "../../common/image/Image";
import Layout from "../../layout/base/Layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Drawer } from "antd";
import { RiDashboardLine } from "react-icons/ri";
import { AiOutlineLineChart } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import { MdOutlineBusiness } from "react-icons/md";
import { useAuth } from "../../../contexts/authContext";
import Aside from "../../common/aside/Aside";
import { useCart } from "../../../contexts/cartContext";
import UserIcon from "../../../assets/images/user-solid.svg";
import { SettingOutlined, SnippetsOutlined } from "@ant-design/icons";
import MarketLogoIcon from "../../../assets/images/logo-box.png";
import FooterContainer from "../../containers/layout/FooterContainer";
import { COLORS, SIZES, Typo } from "../../layout/theme";
import {
	get_client_by_id,
	get_laboratories_for_header,
} from "../../../api/endpoints/clients";
import { useScrollDirection } from "../../../hooks/useScrollDirection";
import { get_total_campaigns_by_region } from "../../../api/endpoints/campaigns";
import PermissionCheckVisibility from "../../common/permission-check-visibility/PermissionCheckVisibility";
import { useBook } from "../../../contexts/bookContext";
import moment from "moment";
import {
	DropdownLink,
	LaboratorySidebarLink,
	SidebarLabel,
} from "./components/styles";
import { checkRolPermission } from "../../../utils/checkRolPermission";
import { useFilter } from "../../../contexts/filterContext";
import MarketHeader from "./components/market-header/MarketHeader";
import PharmacyHeader from "./components/pharmacy-header/PharmacyHeader";
import LaboratoryHeader from "./components/laboratory-header/LaboratoryHeader";
import AsideBarSubMenuAdminOneMarket from "./components/AsideBarSubMenuAdminOneMarket";
import { checkOneMarketMenu } from "./components/AsideBarAdminOneMarketData";

export default function LayoutContainer({ children }) {
	const {
		userdata,
		ready,
		logout,
		requestsQty,
		mobileRequestsQty,
		ordersQty,
		toValidateQty,
		changePhoto,
		setPhotoKey,
		browserInfo,
	} = useAuth();
	const { setUserRol, sudoLabUser, sudoLabClient } = useFilter();
	const { qty, templateProducts } = useCart();
	const { bookQty } = useBook();
	const [showDrawer, setShowDrawer] = useState(false);
	const [photo, setPhoto] = useState(false);
	const [pharmaName, setPharmaName] = useState(null);
	const [clientSlug, setClientSlug] = useState(null);
	const [clientPhotoUrl, setClientPhotoUrl] = useState(null);
	const [campaignRegion, setCampaignRegion] = useState(null);
	const scrollDirection = useScrollDirection();
	const location = useLocation();
	const navigate = useNavigate();
	const [reportSubNav, setReportSubnav] = useState(false);
	const [mobileSubNav, setMobileSubnav] = useState(false);
	const [mobileActive, setMobileActive] = useState(false);
	const [eventSubNav, setEventSubnav] = useState(false);
	const [laboratoryAccountSubNav, setLaboratoryAccountSubnav] = useState(false);
	const [expoActive, setExpoActive] = useState(false);
	const [discountForUserActive, setDiscountForUserActive] = useState(false);
	const [contractZoneType, setContractZoneType] = useState(null);
	const [webActive, setWebActive] = useState(false);
	const [webSellerSubNav, setWebSellerSubnav] = useState(false);
	const [webSellerActive, setWebSellerActive] = useState(false);
	const [couponSubNav, setCouponSubnav] = useState(false);
	const [couponActive, setCouponActive] = useState(false);
	const [laboratoriesForHeader, setLaboratoriesForHeader] = useState(null);

	async function signOut() {
		await logout();
		setShowDrawer(false);
		navigate("/");
		window.location.reload();
	}

	const fetch_photo = async (id) => {
		const res = await get_client_by_id(id);

		const now = moment().milliseconds();

		setPhoto({
			url: res?.userPhoto + `?k=${now}`,
			key: now,
		});
		setPhotoKey(now);
		setPharmaName(res.client.businessName);
		setClientSlug(res.client.slug);
		setClientPhotoUrl(res.client?.photoUrl);
		setMobileActive(res.client?.contract?.activeMobileApp);
		setExpoActive(res.client?.contract?.activeExpoSection);
		setWebSellerActive(res.client?.contract?.activeWebSeller);
		setCouponActive(res.client?.contract?.activeCouponSection);
		setWebActive(res.client?.contract?.activeClientWeb);
		setDiscountForUserActive(res.client?.contract?.activeDiscountsForUsers);
		setContractZoneType(res.client?.contract?.mobileUsersZoneType);
		setUserRol(
			checkRolPermission(userdata, {
				...res.client,
				isOneMarket: userdata.isOneMarket,
			})
		);
	};

	useEffect(() => {
		fetch_photo(userdata.clientId);
	}, [changePhoto]);

	useEffect(() => {
		const fetch_campaign_region = async () => {
			const res = await get_total_campaigns_by_region();
			setCampaignRegion(res);
		};

		fetch_campaign_region();
	}, []);

	useEffect(() => {
		const fetch_laboratories_for_header = async () => {
			const res = await get_laboratories_for_header();
			setLaboratoriesForHeader(res?.items);
		};

		fetch_laboratories_for_header();
	}, []);

	const showReportSubnav = () => setReportSubnav((prev) => !prev);
	const showMobileSubnav = () => setMobileSubnav((prev) => !prev);
	const showEventSubnav = () => setEventSubnav((prev) => !prev);
	const showWebSellerSubnav = () => setWebSellerSubnav((prev) => !prev);
	const showLaboratoryAccountSubnav = () =>
		setLaboratoryAccountSubnav((prev) => !prev);
	const showCouponSubnav = () => setCouponSubnav((prev) => !prev);

	return (
		<Layout>
			{!ready ? (
				"Loading..."
			) : userdata.isOneMarket ? (
				<MarketHeader
					scrollDirection={scrollDirection}
					userdata={userdata}
					location={location}
					templateProducts={templateProducts}
					bookQty={bookQty}
					qty={qty}
					setShowDrawer={setShowDrawer}
					showDrawer={showDrawer}
					browserInfo={browserInfo}
					laboratoriesForHeader={laboratoriesForHeader}
				/>
			) : userdata.isSeller ? (
				<LaboratoryHeader
					scrollDirection={scrollDirection}
					userdata={userdata}
					campaignRegion={campaignRegion}
					templateProducts={templateProducts}
					location={location}
					bookQty={bookQty}
					qty={qty}
					setShowDrawer={setShowDrawer}
					showDrawer={showDrawer}
					browserInfo={browserInfo}
					clientPhotoUrl={clientPhotoUrl}
					clientSlug={clientSlug}
				/>
			) : (
				<PharmacyHeader
					scrollDirection={scrollDirection}
					// userdata={userdata}
					// campaignRegion={campaignRegion}
					// location={location}
					// clientSlug={clientSlug}
					// pharmaName={pharmaName}
					// templateProducts={templateProducts}
					// bookQty={bookQty}
					// qty={qty}
					setShowDrawer={setShowDrawer}
					showDrawer={showDrawer}
					browserInfo={browserInfo}
				/>
			)}
			<Layout.Main>{children}</Layout.Main>
			<Drawer
				title=""
				headerStyle={{ display: "none" }}
				placement="right"
				onClose={() => setShowDrawer(false)}
				visible={showDrawer}
				zIndex={998}
			>
				<Aside>
					{!ready ? (
						"Loading..."
					) : userdata.isOneMarket && !sudoLabClient && !sudoLabUser ? (
						<Aside.Actions>
							<Aside.Separator>
								<Link to="/profile" onClick={() => setShowDrawer(false)}>
									<Layout.HeaderAction
										style={{
											width: "150px",
											wordBreak: "break-word",
											left: "-12px",
										}}
									>
										<Layout.HeaderUserIconContainer>
											<Layout.HeaderUserIcon>
												<Image
													src={photo.url ? photo.url : UserIcon}
													alt="User"
													style={{
														width: "36px",
														height: "36px",
														borderRadius: "50%",
													}}
													key={photo.key}
												/>
											</Layout.HeaderUserIcon>
											<Layout.HeaderUserParagraph>
												<Layout.HeaderSpan>
													{userdata?.firstName}
												</Layout.HeaderSpan>
												<Layout.HeaderSpan>
													<Typo type="primary" level={7} lineHeight={0}>
														OneMarket
													</Typo>
												</Layout.HeaderSpan>
											</Layout.HeaderUserParagraph>
										</Layout.HeaderUserIconContainer>
									</Layout.HeaderAction>
								</Link>
								<Aside.Action> </Aside.Action>
								<Aside.Action> </Aside.Action>
								{checkOneMarketMenu(userdata)?.map((item, index) => {
									return (
										<AsideBarSubMenuAdminOneMarket
											item={item}
											key={index}
											setShowDrawer={setShowDrawer}
											requestsQty={requestsQty}
											mobileRequestsQty={mobileRequestsQty}
											ordersQty={ordersQty}
											toValidateQty={toValidateQty}
										/>
									);
								})}
								<Aside.Action> </Aside.Action>
								<Aside.Action> </Aside.Action>
								<Link to="/">
									<Aside.Action onClick={() => signOut()}>
										<BiLogOut color={COLORS.Primary} />
										Cerrar Sesión
									</Aside.Action>
								</Link>
							</Aside.Separator>
						</Aside.Actions>
					) : !ready ? (
						"Loading..."
					) : userdata.isSeller || (sudoLabClient && sudoLabUser) ? (
						<Aside.Actions>
							<Aside.Separator>
								<Link to="/profile" onClick={() => setShowDrawer(false)}>
									<Layout.HeaderAction
										style={{
											width: "150px",
											wordBreak: "break-word",
											left: "-12px",
										}}
									>
										<Layout.HeaderUserIconContainer>
											<Layout.HeaderUserIcon>
												<Image
													src={
														sudoLabClient
															? sudoLabClient.client.photoUrl
															: photo
															? photo
															: UserIcon
													}
													alt="User"
													style={{
														width: "36px",
														height: "36px",
														borderRadius: "50%",
													}}
												/>
											</Layout.HeaderUserIcon>
											<Layout.HeaderUserParagraph>
												<Layout.HeaderSpan>
													{sudoLabUser
														? sudoLabUser.firstName
														: userdata?.firstName}
												</Layout.HeaderSpan>
												<Layout.HeaderSpan>
													<Typo type="primary" level={7} lineHeight={0}>
														{sudoLabClient
															? sudoLabClient.client.name
															: pharmaName}
													</Typo>
												</Layout.HeaderSpan>
											</Layout.HeaderUserParagraph>
										</Layout.HeaderUserIconContainer>
									</Layout.HeaderAction>
								</Link>
								<Aside.Action> </Aside.Action>
								<Aside.Action> </Aside.Action>
								<PermissionCheckVisibility
									permission={
										sudoLabUser ||
										(userdata.isSeller &&
											(userdata.p_dashboard ||
												userdata.p_shop ||
												userdata.p_mobile) &&
											userdata.p_sales)
									}
								>
									<Link
										to="/admin/dashboard"
										style={{ color: COLORS.Secondary }}
									>
										<Aside.Action onClick={() => setShowDrawer(false)}>
											<AiOutlineLineChart color={COLORS.Primary} />
											Dashboard
										</Aside.Action>
									</Link>
								</PermissionCheckVisibility>
								<PermissionCheckVisibility
									permission={
										sudoLabUser ||
										(userdata.isSeller &&
											userdata.p_dashboard &&
											userdata.p_sales &&
											(mobileActive || expoActive || webSellerActive))
									}
								>
									<Link to="/erp" style={{ color: COLORS.Secondary }}>
										<Aside.Action onClick={() => setShowDrawer(false)}>
											<MdOutlineBusiness style={{ color: COLORS.Primary }} />
											ERP
										</Aside.Action>
									</Link>
								</PermissionCheckVisibility>
								<PermissionCheckVisibility
									permission={
										(sudoLabUser &&
											!sudoLabUser.p_dashboard &&
											sudoLabUser.p_sales &&
											sudoLabUser.p_shop) ||
										(sudoLabUser &&
											!sudoLabUser.p_client &&
											!sudoLabUser.p_users &&
											!sudoLabUser.p_products &&
											!sudoLabUser.p_dashboard &&
											sudoLabUser.p_sales &&
											!sudoLabUser.p_shop &&
											sudoLabUser.p_mobile) ||
										(userdata.isSeller &&
											!userdata.p_dashboard &&
											userdata.p_sales &&
											userdata.p_shop) ||
										(userdata.isSeller &&
											!userdata.p_client &&
											!userdata.p_users &&
											!userdata.p_products &&
											!userdata.p_dashboard &&
											userdata.p_sales &&
											!userdata.p_shop &&
											userdata.p_mobile)
									}
								>
									<Link to="/admin/ventas" style={{ color: COLORS.Secondary }}>
										<Aside.Action onClick={() => setShowDrawer(false)}>
											<AiOutlineLineChart color={COLORS.Primary} />
											Ventas
										</Aside.Action>
									</Link>
								</PermissionCheckVisibility>
								<PermissionCheckVisibility
									permission={sudoLabUser || (userdata && userdata.isSeller)}
								>
									<Link to="/orderStates" style={{ color: COLORS.Secondary }}>
										<Aside.Action onClick={() => setShowDrawer(false)}>
											<SnippetsOutlined style={{ color: COLORS.Primary }} />
											Estado Ordenes
										</Aside.Action>
									</Link>
								</PermissionCheckVisibility>
								<Aside.Action> </Aside.Action>
								<Aside.Action> </Aside.Action>
								<Link to="#" style={{ color: COLORS.Secondary }}>
									<Aside.Action onClick={() => signOut()}>
										<BiLogOut color={COLORS.Primary} />
										Cerrar Sesión
									</Aside.Action>
								</Link>
							</Aside.Separator>
							<Aside.Action> </Aside.Action>
							<Aside.Action> </Aside.Action>
							<Aside.Action> </Aside.Action>
							<Aside.Action> </Aside.Action>
							<Aside.Action> </Aside.Action>
							<Aside.Action> </Aside.Action>
							<Aside.Action> </Aside.Action>
							<PermissionCheckVisibility
								permission={
									sudoLabUser || (userdata.isSeller && userdata.p_client)
								}
							>
								<Link to="/generalConfig" style={{ color: COLORS.Secondary }}>
									<Aside.Action onClick={() => setShowDrawer(false)}>
										<SettingOutlined style={{ color: COLORS.Primary }} />
										Configuración General
									</Aside.Action>
								</Link>
							</PermissionCheckVisibility>
						</Aside.Actions>
					) : (
						<Aside.Actions>
							<Aside.Separator>
								<Link to="#">
									<Aside.Action onClick={() => signOut()}>
										<BiLogOut color={COLORS.Primary} />
										Cerrar Sesión
									</Aside.Action>
								</Link>
							</Aside.Separator>
						</Aside.Actions>
					)}
				</Aside>
			</Drawer>
			<FooterContainer img={MarketLogoIcon}></FooterContainer>
		</Layout>
	);
}

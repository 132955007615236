import React, { useState } from "react";
import {
	Container,
	ModuleContainer,
	Section,
	StyledLi,
	StyledUl,
} from "./styles";
import {
	MdBusinessCenter,
	MdOutlineBusiness,
	MdOutlineChecklist,
	MdPanoramaPhotosphere,
} from "react-icons/md";
import { COLORS, Typo } from "../../../../../layout/theme";
import { Link } from "react-router-dom";
import {
	ApartmentOutlined,
	ClusterOutlined,
	CopyOutlined,
	DatabaseOutlined,
	ExperimentOutlined,
	GlobalOutlined,
	GoldOutlined,
	IdcardOutlined,
	LockOutlined,
	SearchOutlined,
	TeamOutlined,
} from "@ant-design/icons";
import { CONSTANTS } from "../../../../../../utils/constants";
import { Badge } from "antd";
import { BiCheckCircle, BiImport } from "react-icons/bi";
import { IoGitNetworkOutline } from "react-icons/io5";

export default function ModuleOneTransfer({
	userdata,
	userRol,
	toValidateQty,
}) {
	const [showSubMenu, setShowSubMenu] = useState(null);

	return (
		<Section>
			<ModuleContainer>
				<Container>
					<MdOutlineBusiness style={{ color: COLORS.Secondary }} size={50} />
					<Typo type="Grey" level={4}>
						Checkpos Transfer
					</Typo>
					<Typo type="mediumGrey" weight="light">
						Administra el sistema y los datos del negocio
					</Typo>
				</Container>
				<StyledUl>
					{userdata.p_client && userdata.p_products && userdata.p_dashboard && (
						<StyledLi
							onClick={() => setShowSubMenu(CONSTANTS.MODULE_ERP_GENERAL_PDV)}
							style={{ cursor: "pointer" }}
						>
							<Badge
								count={toValidateQty}
								offset={[-182, -4]}
								size="small"
								color={COLORS.Danger}
							>
								<MdPanoramaPhotosphere style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Gestión Tabla PDV General
								</Typo>
							</Badge>
						</StyledLi>
					)}
					{userdata.p_client && userdata.p_users && (
						<StyledLi
							onClick={() =>
								setShowSubMenu(CONSTANTS.MODULE_ONE_TRANSFER_BUSINESS)
							}
							style={{ cursor: "pointer" }}
						>
							<MdBusinessCenter style={{ color: COLORS.Secondary }} />
							<Typo type="secondary" level={13}>
								Negocio
							</Typo>
						</StyledLi>
					)}
					<Link to="/admin/clients?uri=generalConfig">
						<StyledLi>
							<DatabaseOutlined style={{ color: COLORS.Secondary }} />
							<Typo type="secondary" level={13}>
								Clientes
							</Typo>
						</StyledLi>
					</Link>
					<Link to="/admin/marketUsers?uri=generalConfig">
						<StyledLi>
							<IdcardOutlined style={{ color: COLORS.Secondary }} />
							<Typo type="secondary" level={13}>
								Usuarios
							</Typo>
						</StyledLi>
					</Link>
					<Link to="/admin/chains?uri=generalConfig">
						<StyledLi>
							<IoGitNetworkOutline style={{ color: COLORS.Secondary }} />
							<Typo type="secondary" level={13}>
								Cadenas
							</Typo>
						</StyledLi>
					</Link>
				</StyledUl>
			</ModuleContainer>
			{showSubMenu === CONSTANTS.MODULE_ERP_GENERAL_PDV && (
				<ModuleContainer>
					<Container>
						<MdPanoramaPhotosphere
							style={{ color: COLORS.Secondary }}
							size={50}
						/>
						<Typo type="Grey" level={4}>
							Gestión Tabla PDV General
						</Typo>
						<Typo type="mediumGrey" weight="light">
							Sección para administrar tabla referencia de sucursales
						</Typo>
					</Container>
					<StyledUl>
						<Link to="/admin/generalPointsOfSale?uri=generalConfig">
							<StyledLi>
								<MdPanoramaPhotosphere style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Tabla general
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/importGeneralPos?uri=generalConfig">
							<StyledLi>
								<BiImport style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Importador PDV general
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/generalPosConfig?uri=generalConfig">
							<StyledLi>
								<GlobalOutlined style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Gestión PDV general
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/generalPosToValidate?uri=generalConfig">
							<StyledLi>
								<Badge
									count={toValidateQty}
									offset={[-188, -4]}
									size="small"
									color={COLORS.Danger}
								>
									<BiCheckCircle style={{ color: COLORS.Secondary }} />
									<Typo type="secondary" level={13}>
										PDV de solicitudes a validar
									</Typo>
								</Badge>
							</StyledLi>
						</Link>
					</StyledUl>
				</ModuleContainer>
			)}
			{showSubMenu === CONSTANTS.MODULE_ONE_TRANSFER_BUSINESS && (
				<ModuleContainer>
					<Container>
						<MdBusinessCenter style={{ color: COLORS.Secondary }} size={50} />
						<Typo type="Grey" level={4}>
							Negocio
						</Typo>
						<Typo type="mediumGrey" weight="light">
							Sección para administrar variables del negocio
						</Typo>
					</Container>
					<StyledUl>
						<Link to="/admin/distributors?uri=generalConfig">
							<StyledLi>
								<ApartmentOutlined style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Droguerías
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/distributorsIndex?uri=generalConfig">
							<StyledLi>
								<ClusterOutlined style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Distribuidores
								</Typo>
							</StyledLi>
						</Link>
					</StyledUl>
				</ModuleContainer>
			)}
		</Section>
	);
}

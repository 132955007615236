import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import { Link, useSearchParams } from "react-router-dom";
import { ChildrenContainer } from "./styles";
import MarketProductsList from "./product/list/MarketProductsList";

export default function MarketProductsIndex() {
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [selectedClient, setSelectedClient] = useState(null);

	const get_selected_client = () => {
		const clientFromStorage = localStorage.getItem("selectedClient");
		setSelectedClient(clientFromStorage ?? "");
	};

	useEffect(() => {
		get_selected_client();
	}, [selectedClient]);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Productos</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<ChildrenContainer>
					<MarketProductsList
						selectedClient={selectedClient}
						setSelectedClient={setSelectedClient}
						uri={uri}
					/>
				</ChildrenContainer>
			</Main.Body>
		</Main>
	);
}

import {
	FileSearchOutlined,
	MobileOutlined,
	GlobalOutlined,
	SettingOutlined,
} from "@ant-design/icons";
import React from "react";
import { AiOutlineLineChart } from "react-icons/ai";
import { BiCheckCircle, BiImport } from "react-icons/bi";
import {
	RiArrowDownSFill,
	RiArrowUpSFill,
	RiDashboardLine,
} from "react-icons/ri";
import { COLORS } from "../../../layout/theme";

const AsideBarAdminOneMarketData = [
	{
		title: "Reportes",
		path: "#",
		icon: <AiOutlineLineChart color={COLORS.Primary} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Dashboard",
				path: "/admin/dashboard",
				icon: <RiDashboardLine color={COLORS.Primary} />,
			},
		],
	},
	{
		title: "Administración",
		path: "#",
		icon: <RiDashboardLine color={COLORS.Primary} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Auditoría",
				path: "/admin/auditory",
				icon: <BiImport style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Ordenes",
				path: "/admin/marketOrders",
				icon: <AiOutlineLineChart style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Archivos enviados",
				path: "/admin/sendedFiles",
				icon: <FileSearchOutlined style={{ color: COLORS.Primary }} />,
			},
		],
	},
	{
		title: "Solicitudes",
		path: "#",
		icon: <FileSearchOutlined style={{ color: COLORS.Primary }} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Solicitudes Mobile",
				path: "/admin/mobileRequests",
				icon: <MobileOutlined style={{ color: COLORS.Primary }} />,
			},
		],
	},
	{
		title: "Configuración General",
		path: "/generalConfig",
		icon: <SettingOutlined style={{ color: COLORS.Primary }} />,
	},
];

const AsideBarAccountManagerOneMarketData = [
	{
		title: "Administración",
		path: "#",
		icon: <RiDashboardLine color={COLORS.Primary} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,
		subNav: [
			{
				title: "Dashboard",
				path: "/admin/dashboard",
				icon: <RiDashboardLine color={COLORS.Primary} />,
			},
			{
				title: "Auditoría",
				path: "/admin/auditory",
				icon: <BiImport style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Ordenes",
				path: "/admin/marketOrders",
				icon: <AiOutlineLineChart style={{ color: COLORS.Primary }} />,
			},
		],
	},
	{
		title: "Solicitudes",
		path: "#",
		icon: <FileSearchOutlined style={{ color: COLORS.Primary }} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Solicitudes Mobile",
				path: "/admin/mobileRequests",
				icon: <MobileOutlined style={{ color: COLORS.Primary }} />,
			},
		],
	},
	{
		title: "Configuración General",
		path: "/generalConfig",
		icon: <SettingOutlined style={{ color: COLORS.Primary }} />,
	},
];

const AsideBarSellerOneMarketData = [
	{
		title: "Ordenes",
		path: "/admin/marketOrders",
		icon: <AiOutlineLineChart style={{ color: COLORS.Primary }} />,
	},
];

const AsideBarAnalistOneMarketData = [
	{
		title: "Administración",
		path: "#",
		icon: <RiDashboardLine color={COLORS.Primary} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Dashboard",
				path: "/admin/dashboard",
				icon: <RiDashboardLine color={COLORS.Primary} />,
			},
			{
				title: "Auditoría",
				path: "/admin/auditory",
				icon: <BiImport style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Ordenes",
				path: "/admin/marketOrders",
				icon: <AiOutlineLineChart style={{ color: COLORS.Primary }} />,
			},
		],
	},
];

const AsideBarSupportOneMarketData = [
	{
		title: "Administración",
		path: "#",
		icon: <RiDashboardLine color={COLORS.Primary} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Dashboard",
				path: "/admin/dashboard",
				icon: <RiDashboardLine color={COLORS.Primary} />,
			},
			{
				title: "Ordenes",
				path: "/admin/marketOrders",
				icon: <AiOutlineLineChart style={{ color: COLORS.Primary }} />,
			},
		],
	},
	{
		title: "Configuración General",
		path: "/generalConfig",
		icon: <SettingOutlined style={{ color: COLORS.Primary }} />,
	},
];

const AsideBarTelephonistOneMarketData = [
	{
		title: "Gestión PDV general",
		path: "/generalPosConfig",
		icon: <GlobalOutlined style={{ color: COLORS.Primary }} />,
	},
	{
		title: "Datos a validar",
		path: "/generalPosToValidate",
		icon: <BiCheckCircle style={{ color: COLORS.Primary }} />,
	},
];

export function checkOneMarketMenu(userdata) {
	if (
		userdata.p_users &&
		userdata.p_client &&
		userdata.p_dashboard &&
		userdata.p_sales
	) {
		return AsideBarAdminOneMarketData;
	} else if (
		userdata.p_client &&
		!userdata.p_users &&
		userdata.p_products &&
		userdata.p_dashboard &&
		userdata.p_sales &&
		!userdata.p_shop &&
		!userdata.p_mobile
	) {
		return AsideBarAccountManagerOneMarketData;
	} else if (
		!userdata.p_client &&
		!userdata.p_users &&
		!userdata.p_products &&
		userdata.p_dashboard &&
		userdata.p_sales &&
		userdata.p_shop &&
		!userdata.p_mobile
	) {
		return AsideBarSellerOneMarketData;
	} else if (
		!userdata.p_client &&
		!userdata.p_users &&
		!userdata.p_products &&
		userdata.p_dashboard &&
		userdata.p_sales &&
		!userdata.p_shop &&
		!userdata.p_mobile
	) {
		return AsideBarAnalistOneMarketData;
	} else if (
		userdata.p_client &&
		!userdata.p_users &&
		userdata.p_products &&
		!userdata.p_dashboard &&
		userdata.p_sales &&
		!userdata.p_shop &&
		!userdata.p_mobile
	) {
		return AsideBarSupportOneMarketData;
	} else if (
		!userdata.p_client &&
		!userdata.p_users &&
		!userdata.p_products &&
		userdata.p_dashboard &&
		!userdata.p_sales &&
		!userdata.p_shop &&
		userdata.p_mobile
	) {
		return AsideBarTelephonistOneMarketData;
	} else {
		return [];
	}
}

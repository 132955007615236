import styled from "styled-components/macro";
import { Button as ButtonAntd, Table } from "antd";
import { COLORS } from "../../../../../layout/theme";

export const Inner = styled.div`
	min-height: 100vh;
	background-color: rgb(243 244 246 / 1);
	display: grid;
	padding: 30px 20px;
	grid-template-columns: repeat(4, 1fr);
	gap: 20px;
`;
export const BigChartContainer = styled.div`
	grid-row: 2 / 4;
	grid-column: 1 / 4;
`;
export const DoubleChartContainer = styled.div`
	display: flex;
	gap: 20px;
`;
export const VerticalDobleBox = styled.div`
	grid-row: 2 / 4;
	grid-column: 4 / 5;
	${({ second }) => second && "grid-column: 4/5"}
`;

export const Wrapper = styled.div`
	background-color: ${COLORS.LightGrey};
`;

export const AntdButton = styled(ButtonAntd)`
	background-color: ${(props) =>
		props.$active ? COLORS.Primary : COLORS.White};
	color: ${(props) => (props.$active ? COLORS.White : COLORS.Primary)};
	border-color: ${COLORS.Primary};
	border-radius: 5px;
	${(props) =>
		props.height &&
		`
      height: ${props.height}px;
    `}
	${(props) =>
		props.width &&
		`
      width: ${props.width}px;
    `}
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px`}
`;

export const HeaderContainer = styled.div`
	display: flex;
	width: 100%;
	gap: 10px;

	.ant-card-body {
		padding: 14px 24px 24px 24px;
	}
`;

export const Dashboard1Container = styled.div`
	display: flex;
	max-width: 1600px;
	flex-direction: column;
	gap: 20px;
`;

export const FilterContainer = styled.div`
	display: flex;
	gap: 20px;
	padding: 20px;
	background-color: ${COLORS.White};
	border-radius: 10px;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const AntdTable = styled(Table)`
	& .noBackground,
	& .noBackground:hover {
		background-color: ${COLORS.LightGrey} !important;
		border: none;
	}

	.ant-table-thead > tr > th {
		background: ${COLORS.White};
	}

	.ant-table-tbody > tr > td {
		border-bottom: 0px solid ${COLORS.White};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.DarkGrey};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}

	.ant-table-thead th.ant-table-column-has-sorters:hover {
		background: ${COLORS.Secondary};
	}

	.ant-table-column-sorter {
		color: ${COLORS.White};
	}
`;

export const Section = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const TextContainer = styled.div`
	${(props) =>
		props.pointer &&
		`
  cursor: pointer;
`}
	width: 100%;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
`;

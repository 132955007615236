import { Button, Divider, Form, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import {
	CartAntdButton,
	CartCouponButton,
	Container,
	CouponToggleContainer,
	DistributorSelection,
	InfoIva,
	InfoNumber,
	InfoNumberItems,
	InfoSection,
	InfoSubSection,
	InfoSubTitle,
	InfoTitle,
	InfoTitleBuy,
	InfoTitleProductQuantity,
	InfoTitleProductQuantityItems,
	InfoTitleTotal,
	Inner,
	OptionsSection,
	ProductsSection,
	TableContainer,
} from "./styles";
import StepThreeTable from "./components/step-3-table/StepThreeTable";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import {
	create_sale_order,
	get_last_order,
} from "../../../../../../api/endpoints/orders";
import { useCart } from "../../../../../../contexts/cartContext";
import Image from "../../../../../common/image/Image";
import { useNavigate } from "react-router-dom";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import { FaImages } from "react-icons/fa";
import { COLORS, Typo } from "../../../../../layout/theme";
import { get_distributors_name_list } from "../../../../../../api/endpoints/distributors";
import Step2Table from "./components/step-2-table/Step2Table";
import CodeValidator from "../../../../../common/coupon-code-input/CodeValidator";
import { get_pos_code } from "../../../../../../api/endpoints/pointsofsale";
import { MdOutlineLocalOffer } from "react-icons/md";

export default function CartCheckout({
	onSubmitSuccess,
	selectedPointsOfSale,
	products,
	checkoutDetail,
	userdata,
	clientName,
	selector,
}) {
	const [form] = Form.useForm();
	const [submitting, setSubmitting] = useState(false);
	const [totals, setTotals] = useState({
		totalProducts: 0,
		subtotal: 0,
		saved: 0,
		total: 0,
	});
	const { qty, setQty, userId, resetExpoSellerData, resetWebSellerData } =
		useCart();
	const [getLastOrder, setGetLastOrder] = useState(null);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [distributorsNameList, setDistributorsNameList] = useState(null);
	const [tableProducts, setTableProducts] = useState(null);
	const [couponCode, setCouponCode] = useState(null);
	const [coupon, setCoupon] = useState(null);
	const [step2Products, setStep2Products] = useState(products);
	const [posCode, setPosCode] = useState(null);
	const [couponVisible, setCouponVisible] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const fetch_distributors_list = async () => {
			const res = await get_distributors_name_list();
			if (res) {
				const aux = {};
				res.forEach((d) => {
					aux[d.id] = {
						name: d.name,
					};
				});
				setDistributorsNameList(aux);
			}
		};

		const fetch_pos_code = async () => {
			const res = await get_pos_code(selectedPointsOfSale, selector);
			setPosCode(res);
		};

		fetch_distributors_list();
		fetch_pos_code();
	}, []);

	useEffect(() => {
		const fetch_orders = async () => {
			const res = await get_last_order();
			setGetLastOrder(res.orderNumId);
		};
		setLoading(true);
		fetch_orders();
		setLoading(false);
	}, []);

	useEffect(() => {
		if (!step2Products || !distributorsNameList) return;

		const totalProducts = step2Products?.reduce(
			(acc, item) => acc + item.quantity,
			0
		);

		const totalItems = step2Products?.length;

		const subtotal = step2Products?.reduce(
			(acc, item) => acc + item.quantity * item.price,
			0
		);

		const offers = step2Products?.reduce(
			(acc, item) =>
				acc +
				(item.estimatedPrice
					? item.quantity * item.estimatedPrice
					: item.quantity * item.price),
			0
		);

		const saved = Number(
			Math.round(parseFloat(subtotal - offers + "e" + 2)) + "e-" + 2
		).toFixed(2);

		const total = Number(
			Math.round(parseFloat(((subtotal - saved) * 100) / 100 + "e" + 2)) +
				"e-" +
				2
		).toFixed(2);

		setTotals({
			totalProducts,
			totalItems,
			subtotal,
			total,
			saved,
		});

		//Aca es la separación por droguería y los cálculos individuales
		const distributorsInfo = step2Products?.reduce((acc, product) => {
			const distributorId = product.distributorId;

			if (!acc[distributorId]) {
				acc[distributorId] = {
					name: distributorsNameList[distributorId]?.name || "",
					id: distributorId,
					totalProducts: 0,
					totalItems: 0,
					subtotal: 0,
					total: 0,
					saved: 0,
					expandableData: [],
				};
			}

			acc[distributorId].totalProducts += product.quantity;
			acc[distributorId].totalItems++;
			acc[distributorId].subtotal += product.quantity * product.price;

			const offerPrice = product.estimatedPrice || product.price;
			const productSaved = product.quantity * (product.price - offerPrice);

			acc[distributorId].expandableData.push({
				...product,
				offerPrice,
				productSaved,
			});

			return acc;
		}, {});

		Object.values(distributorsInfo).forEach((distributorInfo) => {
			const { expandableData } = distributorInfo;
			distributorInfo.saved = Number(
				Math.round(
					parseFloat(
						expandableData.reduce((acc, item) => acc + item.productSaved, 0) +
							"e" +
							2
					)
				) +
					"e-" +
					2
			).toFixed(2);

			distributorInfo.total = Number(
				Math.round(
					parseFloat(distributorInfo.subtotal - distributorInfo.saved + "e" + 2)
				) +
					"e-" +
					2
			).toFixed(2);
		});

		const tableData = Object.values(distributorsInfo).map(
			(distributorInfo) => ({
				...distributorInfo,
				key: distributorInfo.id,
			})
		);

		setTableProducts(tableData);
	}, [step2Products, distributorsNameList]);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			let _distributors = step2Products?.map((p) => p.distributorId);

			_distributors = _distributors.filter(
				(value, index, self) => index === self.findIndex((t) => t === value)
			);

			const _order = {
				products: step2Products?.map((c) => ({
					productId: c.productId,
					quantity: c.quantity,
					productTitle: c.title,
					price: c.price,
					offerPrice: c.offerPrice,
					estimatedPrice: c.estimatedPrice,
					generalDiscount: c.generalDiscount,
					discount: c.discount,
					campaignDiscount: c.campaignDiscount,
					couponDiscount: c.couponDiscount,
					triggerQty: c.triggerQty,
					triggerDiscount: c.triggerDiscount,
					quantityDiscount: c.quantityDiscount,
					productCommision: c.saleCommision,
					productPhotoUrl: c.photoUrl,
					productEan13: c.ean13,
					distributorId: c.distributorId,
					offerFrom: c.offerFrom,
					offerTo: c.offerTo,
					laboratoryName: c.clientName,
					laboratoryId: c.clientId,
					isComboProduct: c.isComboProduct,
				})),
				pointOfSaleId: selectedPointsOfSale,
				userId: userId,
				couponId: coupon?.id ?? null,
				lastOrderNumber:
					parseInt(getLastOrder) === 180307
						? parseInt(getLastOrder)
						: parseInt(getLastOrder) + 1,
			};

			const result = await create_sale_order(_order);

			setQty(0);

			onSubmitSuccess();

			if (userdata.isSeller && userdata.p_shop && userdata.p_mobile) {
				resetExpoSellerData();
				navigate("/admin/laboratorySellerWeb");
			}
			if (userdata.isSeller && userdata.p_shop && !userdata.p_mobile) {
				resetWebSellerData();
				navigate("/admin/laboratorySellerWeb");
			}
			if (userdata.isSeller && !userdata.p_shop && userdata.p_mobile) {
				resetWebSellerData();
				navigate("/homeMobile");
			}

			for (let index = 0; index < result; index++) {
				openNotificationWithIcon(
					TYPE.SUCCESS,
					`Orden #${getLastOrder + index + 1} confirmada con éxito`,
					`El pedido será enviado a la droguería`
				);
			}
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al confirmar la orden",
				"Un error ocurrio al intentar confirmar la orden, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	if (!step2Products || !selectedPointsOfSale || !getLastOrder)
		return <LoadingSkeleton />;

	return (
		<Form
			form={form}
			name="checkout-cart"
			onFinish={onFinish}
			autoComplete="off"
			requiredMark={false}
			layout="vertical"
		>
			<Inner>
				<ProductsSection>
					<DistributorSelection>
						<TableContainer>
							{tableProducts ? <Step2Table data={tableProducts} /> : <Spin />}
						</TableContainer>
					</DistributorSelection>
				</ProductsSection>
				<InfoSection>
					<Container>
						<InfoSubSection>
							<InfoTitleBuy>CONFIRMAR TRANSFER:</InfoTitleBuy>
						</InfoSubSection>
						<Divider />
						<InfoSubSection
							style={{
								justifyContent: "space-between",
								margin: "0 40px 0 40px",
							}}
						>
							<Typo type="primary" level={9} weight="ExtraBold">
								Droguería:
							</Typo>
							<Typo type="primary" level={9} weight="ExtraBold">
								{tableProducts
									? tableProducts.length > 1
										? "Múltiples"
										: checkoutDetail?.distributor
									: ""}
							</Typo>
						</InfoSubSection>
						<InfoSubSection
							style={{
								justifyContent: "space-between",
								margin: "0 40px 0 40px",
							}}
						>
							<Typo type="muted" level={9}>
								Punto de venta:
							</Typo>
							<Typo type="muted" level={9}>
								{checkoutDetail?.pointOfSale}
							</Typo>
						</InfoSubSection>
						{tableProducts && tableProducts.length === 1 && (
							<InfoSubSection
								style={{
									justifyContent: "space-between",
									margin: "0 40px 0 40px",
								}}
							>
								<Typo type="muted" level={9}>
									Código cliente:
								</Typo>
								<Typo type="muted" level={9}>
									{posCode}
								</Typo>
							</InfoSubSection>
						)}
						{userdata.isOneMarket && (
							<InfoSubSection
								style={{
									justifyContent: "space-between",
									margin: "10px 40px 0 40px",
								}}
							>
								<Typo type="primary" level={9} weight="ExtraBold">
									Farmacia:
								</Typo>
								<Typo type="primary" level={9} weight="ExtraBold">
									{clientName}
								</Typo>
							</InfoSubSection>
						)}
						<Divider />
						<InfoSubSection
							style={{
								justifyContent: "space-between",
								margin: "0 40px 0 40px",
							}}
						>
							<InfoTitleProductQuantity>
								Cantidad de productos:
							</InfoTitleProductQuantity>
							<InfoNumber>{totals.totalItems}</InfoNumber>
						</InfoSubSection>
						<InfoSubSection
							style={{
								justifyContent: "space-between",
								margin: "20px 40px 0px 40px",
							}}
						>
							<InfoTitleProductQuantityItems>
								Cantidad de unidades:
							</InfoTitleProductQuantityItems>
							<InfoNumberItems>{totals.totalProducts}</InfoNumberItems>
						</InfoSubSection>
						<Divider />
						<InfoSubSection
							style={{
								justifyContent: "space-between",
								margin: "0px 40px 30px 40px",
							}}
						>
							<InfoSubTitle>SUBTOTAL:</InfoSubTitle>
							<InfoSubTitle>
								$ {numberWithDotAndCommas(totals.subtotal.toFixed(2))}
							</InfoSubTitle>
						</InfoSubSection>
						<InfoSubSection
							style={{
								justifyContent: "space-between",
								margin: "0 40px 0 40px",
							}}
						>
							<InfoTitle>AHORRO:</InfoTitle>
							<InfoTitle>$ {numberWithDotAndCommas(totals.saved)}</InfoTitle>
						</InfoSubSection>
						<Divider />
						<InfoSubSection
							style={{
								justifyContent: "space-between",
								margin: "0px 40px 30px 40px",
							}}
						>
							<InfoTitleTotal>TOTAL:</InfoTitleTotal>
							<InfoTitleTotal>
								$ {numberWithDotAndCommas(totals.total)}
							</InfoTitleTotal>
						</InfoSubSection>
						<Divider />
						<OptionsSection>
							{couponVisible ? (
								<CouponToggleContainer style={{ paddingRight: 20 }}>
									<CodeValidator
										form={form}
										products={step2Products}
										setProducts={setStep2Products}
										code={couponCode}
										setCode={setCouponCode}
										coupon={coupon}
										setCoupon={setCoupon}
									/>
								</CouponToggleContainer>
							) : (
								<CouponToggleContainer
									onClick={() => setCouponVisible((prev) => !prev)}
								>
									<MdOutlineLocalOffer
										size={20}
										style={{ color: COLORS.Primary }}
									/>
									<Typo type="primary" level={6}>
										Tengo un cupón de descuento
									</Typo>
								</CouponToggleContainer>
							)}
						</OptionsSection>
						<Divider />
						<InfoIva translate="no">IVA no incluido</InfoIva>
						<InfoIva translate="no">
							Los precios PVP, PSD y PSL son de referencia y pueden variar
						</InfoIva>
						<InfoIva>dependiendo de la droguería seleccionada.</InfoIva>
						<InfoSubSection>
							<Form.Item>
								<CartAntdButton
									type="Primary"
									color="white"
									bg="Primary"
									htmlType="submit"
									loading={submitting}
									style={{ width: "200px" }}
								>
									Confirmar y enviar pedido
								</CartAntdButton>
							</Form.Item>
						</InfoSubSection>
					</Container>
				</InfoSection>
			</Inner>
		</Form>
	);
}

import React, { useState, useEffect } from "react";
import {
	useParams,
	Link,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import {
	Form,
	Input,
	Select,
	Row,
	Col,
	message,
	Modal,
	Button,
	Space,
} from "antd";
import {
	InnerForm,
	ProductData,
	PublishSettings,
	InfoContainer,
	RightContainer,
	Container,
	TopContainer,
	AntdButton,
	ConfigContainer,
	Section,
} from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Main from "../../../../layout/main/Main";
import COLORS from "../../../../layout/theme/colors/Colors";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import {
	get_distributor_by_id,
	update_distributor,
	update_distributor_config,
} from "../../../../../api/endpoints/distributors";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import { Typo } from "../../../../layout/theme";
import UploadFileImage from "../../../../common/upload-file-with-image/UploadFileImage";
import {
	CheckOutlined,
	CloseCircleOutlined,
	DeleteOutlined,
} from "@ant-design/icons";
import { CONSTANTS } from "../../../../../utils/constants";
import {
	delete_distributor_email,
	get_emails_by_distributorId,
	update_distributor_email,
} from "../../../../../api/endpoints/emails";
import {
	get_mobile_laboratories_list,
	get_pharmacies_names_list,
} from "../../../../../api/endpoints/clients";
import ConfigSection from "./components/config-section/ConfigSection";
import { useFilter } from "../../../../../contexts/filterContext";
import {
	get_chain_by_id,
	update_chain,
} from "../../../../../api/endpoints/chain";

const { Option } = Select;
const GUTTER = 10;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

const states = [
	{
		id: "ACTIVE",
		name: "Activo",
	},
	{
		id: "DELETED",
		name: "Inactivo",
	},
];

export default function UpdateChain() {
	const { id } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const { configType, setConfigType } = useFilter();

	const [submitting, setSubmitting] = useState(false);
	const [imageUrl, setImageUrl] = useState();
	const [initialValues, setInitialValues] = useState(null);
	const [updated, setUpdated] = useState(false);
	const [clientsForChain, setClientsForChain] = useState(null);
	const [clients, setClients] = useState(null);
	const [key, setKey] = useState(null);

	const fetch_chain_by_id = async () => {
		const data = await get_chain_by_id(id);
		const formData = {
			title: data.title,
			state: data.isActive ? "ACTIVE" : "DELETED",
			file: data.photoUrl,
		};

		setClientsForChain(data.clients?.map((r) => r.id));

		setImageUrl(data.photoUrl);

		setInitialValues(formData);
	};

	const fetch_pharmacies_list = async (term) => {
		const res = await get_pharmacies_names_list(term);

		setClients(
			res.map((r) => ({
				label: r.name,
				value: r.id,
			}))
		);
	};

	useEffect(() => {
		fetch_pharmacies_list();
	}, []);

	useEffect(() => {
		if (id) {
			fetch_chain_by_id();
			fetch_pharmacies_list();
		}
	}, [updated]);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const postData = {
				title: values?.title,
				clients: clientsForChain,
			};

			const chainFormData = new FormData();

			chainFormData.append(
				"file",
				values.file?.fileList?.slice(-1)[0]?.originFileObj
			);

			chainFormData.append("data", JSON.stringify(postData));

			await update_chain(id, chainFormData);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"cadena editada con éxito",
				"La cadena se editó con éxito"
			);
			setUpdated((prev) => !prev);
			// navigate(`/admin/distributors?uri=${uri}`);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al editar la cadena",
				"Un error ocurrió al intentar editar la cadena, contacte con soporte a soporte@onetransfer.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	if (!id) navigate("/admin/chains?uri=" + uri);

	if (!initialValues) return <LoadingSkeleton />;

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/distributors?uri=" + uri);
			},
		});
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to={"/admin/chains?uri=" + uri}>Cadenas</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Editar cadena</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Form
					form={form}
					name="basic"
					onFinish={onFinish}
					autoComplete="off"
					requiredMark={false}
					layout="vertical"
					initialValues={initialValues}
				>
					<InnerForm>
						<ProductData>
							<Space
								direction="vertical"
								size="middle"
								style={{ display: "flex" }}
							>
								<Row gutter={GUTTER}>
									<Col span={24}>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Nombre
												</label>
											}
											name="title"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(
														/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
													),
													message:
														"No se aceptan caracteres especiales. Solo letras y numeros",
												},
											]}
										>
											<Input placeholder="Nombre" />
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={GUTTER}>
									<Col span={6}>
										<Form.Item
											name="file"
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Logo de la cadena
												</label>
											}
											rules={[]}
										>
											<UploadFileImage
												onChange={handleImageChange}
												urlImage={imageUrl}
												imageHeight="100%"
												imageWidth="100%"
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={GUTTER}>
									<Col md={12} xl={12}>
										<Section>
											<Typo type="primary" level={6}>
												Farmacias
											</Typo>
											<Select
												showSearch
												mode="multiple"
												placeholder="Buscar cliente"
												optionFilterProp="children"
												onChange={(e) => setClientsForChain(e)}
												value={
													clientsForChain?.length > 0 ? clientsForChain : []
												}
												filterOption={(input, option) =>
													(option?.label ?? "")
														.toLowerCase()
														.includes(input.toLowerCase())
												}
												options={clients}
											/>
										</Section>
									</Col>
								</Row>
							</Space>
						</ProductData>
						<PublishSettings>
							<Space
								direction="vertical"
								size="middle"
								style={{ display: "flex" }}
							>
								<Form.Item>
									<SignInTemplate.AntdButton
										type="Primary"
										color="white"
										bg="Primary"
										htmlType="submit"
										loading={submitting}
										block
									>
										Guardar
									</SignInTemplate.AntdButton>
								</Form.Item>
								<SignInTemplate.AntdButton
									type="Primary"
									loading={submitting}
									onClick={handleBack}
									block
								>
									<Typo type="danger" level={6}>
										Volver sin guardar
									</Typo>
								</SignInTemplate.AntdButton>
							</Space>
						</PublishSettings>
					</InnerForm>
				</Form>
			</Main.Body>
		</Main>
	);
}

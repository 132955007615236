import React, { useState } from "react";
import Box from "../box/Box";
import { TbActivityHeartbeat } from "react-icons/tb";
import { Typo } from "../../../../../layout/theme";
import {
	BiBox,
	BiBuildings,
	BiDollar,
	BiCoinStack,
	BiPurchaseTag,
} from "react-icons/bi";
import { MdSafetyDivider } from "react-icons/md";
import {
	BigChartContainer,
	Container,
	Dashboard1Container,
	DoubleChartContainer,
	FilterContainer,
	HeaderContainer,
} from "./styles";
import { Button, DatePicker, Select } from "antd";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import { CONSTANTS } from "../../../../../../utils/constants";
import DebounceSelectSeller from "../../../../../common/debounce-select-seller/DebounceSelectSeller";

export default function SaleDashboard({
	dashboard,
	optionsPie,
	timeSalesGraph,
	timePharmacyOrdersGraph,
	timeOrderGraph,
	distributorSalesGraph,
	timeUnitsGraph,
	distributors,
	selectDistributor,
	provinces,
	selectProvince,
	handleDateChange,
	deleteFilters,
	isLoading,
	productSalesGraph,
	productUnitsGraph,
	selectedDistributor,
	selectedProvince,
	rangeDate,
	selectOrdersInfo,
	ordersInfoSelector,
	selectDistributorsInfo,
	distributorsInfoSelector,
	optionsPie2,
	regionSalesGraph,
	productGraphSelector,
	selectProductGraph,
	optionsPie3,
	selectedPointOfSale,
	selectPointOfSale,
	pointsOfSale,
	selectedCategory,
	selectCategory,
	categories,
	categorySalesGraph,
	categoryUnitsGraph,
	categoryGraphSelector,
	selectCategoryGraph,
	selectedLocality,
	selectLocality,
	localities,
	selectedChain,
	selectChain,
	chains,
	localitiesSalesGraph,
	userdata,
	sellers,
	selectSeller,
	selectedSeller,
	timeCategoriesSalesGraph,
	timeCategoriesUnitsGraph,
	categoryGraphSelector2,
	selectCategoryGraph2,
	timeHistoricSalesGraph,
	selectSalesInfo,
	salesInfoSelector,
}) {
	return (
		<Dashboard1Container>
			<FilterContainer>
				<Container>
					<Typo type="primary" level={6}>
						Fecha
					</Typo>
					{rangeDate ? (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							value={rangeDate}
							onChange={(e) => handleDateChange(e)}
						/>
					) : (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							onChange={(e) => handleDateChange(e)}
							placeholder={["Desde", "Hasta"]}
						/>
					)}
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Droguería
					</Typo>
					<Select
						showSearch
						// allowClear
						placeholder="Droguería"
						optionFilterProp="children"
						value={selectedDistributor}
						// defaultValue={selectedDistributor ?? null}
						onChange={(e, o) => selectDistributor(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={distributors}
						style={{ width: 150 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Cadena
					</Typo>
					<Select
						showSearch
						placeholder="Cadena"
						optionFilterProp="children"
						value={selectedChain}
						onChange={(e, o) => selectChain(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={chains}
						style={{ width: 120 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Punto de venta
					</Typo>
					<Select
						showSearch
						// allowClear
						placeholder="Punto de venta"
						optionFilterProp="children"
						value={selectedPointOfSale}
						// defaultValue={selectedProvince ?? null}
						onChange={(e, o) => selectPointOfSale(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={pointsOfSale}
						style={{ width: 160 }}
					/>
				</Container>
				{selectedProvince ? (
					<Container>
						<Typo type="primary" level={6}>
							Localidad
						</Typo>
						<Select
							showSearch
							// allowClear
							placeholder="Localidad"
							optionFilterProp="children"
							value={selectedLocality}
							// defaultValue={selectedProvince ?? null}
							onChange={(e, o) => selectLocality(e, o)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={localities}
							style={{ width: 160 }}
						/>
					</Container>
				) : (
					<Container>
						<Typo type="primary" level={6}>
							Provincia
						</Typo>
						<Select
							showSearch
							// allowClear
							placeholder="Provincia"
							optionFilterProp="children"
							value={selectedProvince}
							// defaultValue={selectedProvince ?? null}
							onChange={(e, o) => selectProvince(e, o)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={provinces}
							style={{ width: 160 }}
						/>
					</Container>
				)}
				{/* <Container>
					<Typo type="primary" level={6}>
						Tipo de venta
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Tipo de venta"
						optionFilterProp="children"
						value={selectedTypeOfSale}
						// defaultValue={selectedLaboratory ?? null}
						onChange={(e, o) => handleTypeOfSale(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={typeOfSales}
						style={{ width: 180 }}
					/>
				</Container> */}
				<Container>
					<Typo type="primary" level={6}>
						Categoria
					</Typo>
					<Select
						showSearch
						// allowClear
						placeholder="Categoria"
						optionFilterProp="children"
						value={selectedCategory}
						// defaultValue={selectedProvince ?? null}
						onChange={(e, o) => selectCategory(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={categories}
						style={{ width: 160 }}
					/>
				</Container>
				{!(!userdata.p_shop && userdata.p_mobile) && (
					<Container>
						<Typo type="primary" level={6}>
							Vendedor
						</Typo>
						<DebounceSelectSeller
							fetchCallback={sellers}
							placeholder="Vendedor"
							onValuesChange={selectSeller}
							initialValues={selectedSeller}
						/>
					</Container>
				)}
				<Container style={{ justifyContent: "flex-end", width: "120px" }}>
					<Button type="primary" onClick={deleteFilters}>
						Borrar filtros
					</Button>
				</Container>
			</FilterContainer>
			<HeaderContainer>
				<Box
					data={numberWithDotAndCommas((dashboard?.totalYearSales).toFixed(0))}
					dataSuffix={"%"}
					secondData={(
						(dashboard?.salesWithOffer * 100) /
						dashboard?.totalYearSales
					).toFixed(2)}
					thirdData={(
						(dashboard?.salesWithoutOffer * 100) /
						dashboard?.totalYearSales
					).toFixed(2)}
					monthName={CONSTANTS.CALENDAR_MONTHS[dashboard?.actualMonth]}
					graphData={timeSalesGraph}
					isLoading={isLoading}
					type="headerBoxLaboratory"
					title={
						selectSalesInfo === "SALES" ? (
							<Typo texto="light" type="primary">
								Total ventas
							</Typo>
						) : selectSalesInfo === "UNITS" ? (
							<Typo texto="light" type="primary">
								Peso ventas
							</Typo>
						) : (
							""
						)
					}
					headerSubTitle2={"Mes Actual: "}
					selectSalesInfo={salesInfoSelector}
					salesInfo={selectSalesInfo}
					iconOption={<BiDollar size={20} />}
					iconOption2={<BiPurchaseTag size={20} />}
				/>
				<Box
					data={
						ordersInfoSelector === "SALES"
							? dashboard?.totalOrders
							: dashboard?.totalYearUnits
					}
					subData={
						ordersInfoSelector === "SALES"
							? dashboard?.actualMonthOrders > 0
								? dashboard?.actualMonthOrders
								: null
							: dashboard?.unitsMonthInfo > 0
							? dashboard?.unitsMonthInfo
							: null
					}
					monthName={CONSTANTS.CALENDAR_MONTHS[dashboard?.actualMonth]}
					graphData={timeOrderGraph}
					secondGraphData={timeUnitsGraph}
					isLoading={isLoading}
					type="headerBoxSales"
					prefix={<BiBox />}
					selectOrdersInfo={selectOrdersInfo}
					ordersInfo={ordersInfoSelector}
					iconOption={<BiBox size={20} />}
					iconOption2={<BiCoinStack size={20} />}
					title={
						<Typo texto="light" type="primary">
							{ordersInfoSelector === "SALES"
								? "Total pedidos"
								: ordersInfoSelector === "UNITS"
								? "Total unidades"
								: ""}
						</Typo>
					}
				/>
				{selectedProvince ? (
					<Box
						type="regionHeaderBox"
						graphData={localitiesSalesGraph}
						isLoading={isLoading}
						title={
							<Typo texto="light" type="primary">
								Ventas por localidad
							</Typo>
						}
					/>
				) : (
					<Box
						type="regionHeaderBox"
						graphData={regionSalesGraph}
						isLoading={isLoading}
						title={
							<Typo texto="light" type="primary">
								Ventas por región
							</Typo>
						}
					/>
				)}
				{/*     //Grafico comparativo
				{!selectedTypeOfSale && (
					<Box
						monthName={CONSTANTS.CALENDAR_MONTHS[dashboard?.actualMonth]}
						graphData={timeVSSalesGraph}
						secondGraphData={timeVSUnitsGraph}
						thirdGraphData={timeVSOrdersGraph}
						isLoading={isLoading}
						type="headerBoxVSSales"
						prefix={<BiBox />}
						selectOrdersInfo={selectVSInfo}
						ordersInfo={VSInfoSelector}
						iconOption={<BiDollar size={20} />}
						iconOption2={<BiCoinStack size={20} />}
						iconOption3={<BiBox size={20} />}
						title={
							<Typo texto="light" type="primary">
								{VSInfoSelector === "SALES"
									? "Ventas VS Mobile"
									: VSInfoSelector === "UNITS"
									? "Unidades VS Mobile"
									: VSInfoSelector === "ORDERS"
									? "Ordenes VS Mobile"
									: ""}
							</Typo>
						}
					/>
				)} */}
			</HeaderContainer>
			<DoubleChartContainer>
				<Box
					graphData={timeHistoricSalesGraph}
					isLoading={isLoading}
					type="timeHeaderBox"
					title={
						<Typo texto="light" type="primary">
							Historico $/Un.
						</Typo>
					}
				/>
				<Box
					type="pieHeaderBoxSales"
					options={optionsPie}
					option2={optionsPie2}
					option3={optionsPie3}
					isLoading={isLoading}
					prefix={<MdSafetyDivider />}
					selectDistributorsInfo={selectDistributorsInfo}
					distributorsInfo={distributorsInfoSelector}
					iconOption={<BiDollar size={20} />}
					iconOption2={<BiBox size={20} />}
					iconOption3={<BiCoinStack size={20} />}
					title={
						<Typo texto="light" type="primary">
							{distributorsInfoSelector === "ORDERS"
								? "Droguerías: pedidos"
								: distributorsInfoSelector === "SALES"
								? "Droguerías: ventas"
								: distributorsInfoSelector === "UNITS"
								? "Droguerías: unidades"
								: ""}
						</Typo>
					}
				/>
			</DoubleChartContainer>
			<BigChartContainer>
				<Box
					type="timeChartMultipleCategories"
					options={timeCategoriesSalesGraph}
					iconOption={<BiDollar size={20} />}
					option2={timeCategoriesUnitsGraph}
					iconOption2={<BiCoinStack size={20} />}
					selectProductGraph={selectCategoryGraph2}
					productGraph={categoryGraphSelector2}
					isLoading={isLoading}
					title={
						<Typo texto="light" type="primary">
							{categoryGraphSelector2 === "SALES"
								? "Histórico ventas por categoría"
								: categoryGraphSelector2 === "UNITS"
								? "Histórico unidades por categoría"
								: ""}
						</Typo>
					}
				/>
			</BigChartContainer>
			{!selectedCategory && (
				<BigChartContainer>
					<Box
						type="timeChartMultipleProduct"
						options={categorySalesGraph}
						iconOption={<BiDollar size={20} />}
						option2={categoryUnitsGraph}
						iconOption2={<BiCoinStack size={20} />}
						selectProductGraph={selectCategoryGraph}
						productGraph={categoryGraphSelector}
						isLoading={isLoading}
						title={
							<Typo texto="light" type="primary">
								{categoryGraphSelector === "SALES"
									? "Ventas por categoría"
									: categoryGraphSelector === "UNITS"
									? "Unidades por categoría"
									: ""}
							</Typo>
						}
					/>
				</BigChartContainer>
			)}
			<BigChartContainer>
				<Box
					type="timeChartMultipleProduct"
					options={productSalesGraph}
					iconOption={<BiDollar size={20} />}
					option2={productUnitsGraph}
					iconOption2={<BiCoinStack size={20} />}
					selectProductGraph={selectProductGraph}
					productGraph={productGraphSelector}
					isLoading={isLoading}
					title={
						<Typo texto="light" type="primary">
							{productGraphSelector === "SALES"
								? "Ventas por producto"
								: productGraphSelector === "UNITS"
								? "Unidades por producto"
								: ""}
						</Typo>
					}
				/>
			</BigChartContainer>
		</Dashboard1Container>
	);
}

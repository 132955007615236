import { Badge, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
	AntdButton,
	ButtonContainer,
	Container,
	Inner,
	Section,
} from "./styles";
import Main from "../../../layout/main/Main";
import { COLORS, Typo } from "../../../layout/theme";
import { useFilter } from "../../../../contexts/filterContext";
import { CONSTANTS } from "../../../../utils/constants";
import ModuleOneTransfer from "./components/module-one-transfer/ModuleOneTransfer";
import ModuleErp from "./components/module-erp/ModuleErp";
import ModuleMarketplace from "./components/module-marketplace/ModuleMarketplace";
import { useAuth } from "../../../../contexts/authContext";

const { Title, Paragraph } = Typography;

export default function MarketGeneralConfig({ userdata }) {
	const { generalConfigOT, setGeneralConfigOT, userRol } = useFilter();
	const { toValidateQty } = useAuth();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Configuración General
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
				<Container>
					<Section>
						<Title style={{ color: COLORS.Primary, marginTop: 30 }} level={3}>
							Configuración General
						</Title>
						<Paragraph>
							<blockquote>
								En esta sección podrás configurar el sistema
							</blockquote>
						</Paragraph>
					</Section>
					<ButtonContainer>
						{((userdata.p_client && userdata.p_users) ||
							(userdata.p_client && !userdata.p_users)) && (
							<Badge
								count={toValidateQty}
								offset={[-2, -4]}
								size="small"
								color={COLORS.Danger}
							>
								<AntdButton
									type="primary"
									bg="Primary"
									color="White"
									style={{ fontWeight: "600" }}
									width={180}
									height={48}
									fontSize={12}
									onClick={() =>
										setGeneralConfigOT(CONSTANTS.MODULE_ONE_TRANSFER)
									}
									$active={generalConfigOT === CONSTANTS.MODULE_ONE_TRANSFER}
								>
									CHECKPOS TRANSFER
								</AntdButton>
							</Badge>
						)}
						<AntdButton
							type="primary"
							bg="Primary"
							color="White"
							style={{ fontWeight: "600", marginLeft: "20px" }}
							width={180}
							s
							height={48}
							fontSize={12}
							onClick={() => setGeneralConfigOT(CONSTANTS.MODULE_ERP)}
							$active={generalConfigOT === CONSTANTS.MODULE_ERP}
						>
							ERP
						</AntdButton>
					</ButtonContainer>
				</Container>
			</Main.Header>

			<Inner>
				{generalConfigOT === CONSTANTS.MODULE_ONE_TRANSFER &&
					((userdata.p_client && userdata.p_users) ||
						(userdata.p_client && !userdata.p_users)) && (
						<ModuleOneTransfer
							userdata={userdata}
							userRol={userRol}
							toValidateQty={toValidateQty}
						/>
					)}
				{generalConfigOT === CONSTANTS.MODULE_ERP && (
					<ModuleErp
						userdata={userdata}
						userRol={userRol}
						toValidateQty={toValidateQty}
					/>
				)}
			</Inner>
		</Main>
	);
}

import axiosInstance from "../axiosInstance";

const CONTROLLER = "brands";

const get_brands = async (search, clientId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?search=${search || ""}&clientId=${clientId || ""}`
	);
	return response.data;
};

const get_brand_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetById/${id}`);
	return response.data;
};

const get_brands_page = async (search, state, page, clientId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPage?search=${
			search || ""
		}&state=${state}&pageNumber=${page}&clientId=${clientId || ""}`
	);
	return response.data;
};

const get_brands_header_info = async (search, clientId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetBrandsHeader?search=${search || ""}&clientId=${
			clientId || ""
		}`
	);
	return response.data;
};

const update_brand = async (id, brand) => {
	const response = await axiosInstance.put(`${CONTROLLER}/${id}`, brand);
	return response.data;
};

const update_brand_state = async (state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateBrandStatus`,
		state
	);
	return response.data;
};

const create_brand = async (brand) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, brand, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const get_market_brands = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetTotal?search=${search || ""}`
	);
	return response.data;
};

const get_brand_by_client_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetByClientId/${id}`);
	return response.data;
};

const get_brands_name_list = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetNameList`);
	return response.data;
};

const get_brands_name_list_filtered = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetNameListFiltered`);
	return response.data;
};

const get_brands_for_visit_report = async (clientId, brandId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetTotalBrandsShort`,
		{
			params: {
				brandId: brandId,
				clientId: clientId,
			},
		}
	);
	return response.data;
};

export {
	get_brands,
	get_brand_by_id,
	get_brands_header_info,
	update_brand,
	create_brand,
	get_brands_page,
	get_market_brands,
	get_brand_by_client_id,
	update_brand_state,
	get_brands_name_list,
	get_brands_for_visit_report,
	get_brands_name_list_filtered,
};

import styled from "styled-components";
import { Checkbox } from "antd";
import { COLORS } from "../../../../layout/theme";

export const InnerForm = styled.div`
	display: flex;
	gap: 50px;
`;
export const ProductData = styled.div`
	flex: 1 1 65%;
	background-color: ${COLORS.White};
	border-radius: 10px;
	padding: 2rem;
`;
export const PublishSettings = styled.div`
	flex: 1 1 35%;
	background-color: ${COLORS.White};
	border-radius: 10px;
	padding: 2rem;
	max-height: 180px;
`;
export const DistributorImage = styled.img`
	width: 40px;
	height: 40px;
	object-fit: contain;
`;
export const PreviewImage = styled.img`
	width: 150px;
	height: 150px;
	object-fit: contain;
`;
export const Container = styled.div``;

export const AntdCheckbox = styled(Checkbox)``;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

import axiosInstance from "../axiosInstance";

const CONTROLLER = "initiative";

const get_initiatives = async (search, clientId, state) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?search=${search}&clientId=${clientId}&state=${state}`
	);
	return response.data;
};

const get_initiative_by_id = async (id) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetInitiativeById/${id}`
	);
	return response.data;
};

const get_initiatives_header_info = async (clientId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetInitiativesHeader?clientId=${clientId}`
	);
	return response.data;
};

const update_initiative = async (id, data) => {
	const response = await axiosInstance.put(`${CONTROLLER}/${id}`, data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const update_initiative_state = async (state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateInitiativeState`,
		state
	);
	return response.data;
};

const create_initiative = async (data) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

export {
	get_initiatives,
	get_initiatives_header_info,
	create_initiative,
	get_initiative_by_id,
	update_initiative,
	update_initiative_state,
};

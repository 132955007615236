import React, { useState, useEffect } from "react";
import {
	useParams,
	Link,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import {
	Form,
	Input,
	Select,
	Row,
	Col,
	Space,
	message,
	Modal,
	Button,
	Checkbox,
} from "antd";
import {
	ConfigContainer,
	Container,
	FileStructureBackground,
	InfoContainer,
	InnerForm,
	ProductData,
	PublishSettings,
	RightContainer,
	TopContainer,
} from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import Main from "../../../../../layout/main/Main";
import COLORS from "../../../../../layout/theme/colors/Colors";
import SignInTemplate from "../../../../../layout/sign-in-template/SignInTemplate";

import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import {
	get_megaDistributor_by_id,
	update_mega_distributor_config,
	update_megaDistributor,
} from "../../../../../../api/endpoints/megaDistributors";
import UploadFileImage from "../../../../../common/upload-file-with-image/UploadFileImage";
import { Typo } from "../../../../../layout/theme";
import {
	CheckOutlined,
	CloseCircleOutlined,
	DeleteOutlined,
} from "@ant-design/icons";
import { CONSTANTS } from "../../../../../../utils/constants";
import MegaDistributorsCode from "../../../../../common/megaDistributors-code/MegaDistributorsCode";
import {
	delete_mega_distributor_email,
	get_emails_by_megaDistributorId,
	update_mega_distributor_email,
} from "../../../../../../api/endpoints/megaDistributorEmails";
import ConfigSection from "./components/config-section/ConfigSection";

const { Option } = Select;
const GUTTER = 10;

const states = [
	{
		id: "ACTIVE",
		name: "Activo",
	},
	{
		id: "DELETED",
		name: "Inactivo",
	},
];

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

export default function UpdateMegaDistributor() {
	const { id } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");

	const [submitting, setSubmitting] = useState(false);
	const [imageUrl, setImageUrl] = useState();
	const [initialValues, setInitialValues] = useState(null);
	const [availableTypesOfFiles, setAvailableTypesOfFiles] = useState(null);
	const [availableSendTypes, setAvailableSendTypes] = useState(null);
	const [distributors, setDistributors] = useState(null);
	const [copyModalOpen, setCopyModalOpen] = useState(false);
	const [flag, setFlag] = useState(false);
	const [key, setKey] = useState(null);
	const [mailID, setMailID] = useState(null);
	const [show, setShow] = useState(false);
	const [newEmail, setNewEmail] = useState(null);
	const [copyEmails, setCopyEmails] = useState(null);
	const [selectedColumnsMobile, setSelectedColumnsMobile] = useState(null);
	const [availableColumnsMobile, setAvailableColumnsMobile] = useState(null);
	const [updated, setUpdated] = useState(false);
	const [activeMobile, setActiveMobile] = useState(false);
	const [activeWeb, setActiveWeb] = useState(false);

	const fetch_megaDistributor_by_id = async () => {
		const data = await get_megaDistributor_by_id(id);
		const formData = {
			title: data.name,
			state: data.isActive ? "ACTIVE" : "DELETED",
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			send: JSON.parse(data.typeOfArchive)?.send ?? "",
			format: JSON.parse(data.typeOfArchive)?.format ?? "",
			server: JSON.parse(data.typeOfArchive)?.server ?? "",
			port: JSON.parse(data.typeOfArchive)?.port ?? "",
			user: JSON.parse(data.typeOfArchive)?.user ?? "",
			password: JSON.parse(data.typeOfArchive)?.password ?? "",
			folder: JSON.parse(data.typeOfArchive)?.folder ?? "",
			columnsToSend: data.columnsToSend
				? data.columnsToSend.split(";").map((colum) => ({
						value: colum,
						label: CONSTANTS.MEGA_DISTRIBUTORS_COLUMNS.find(
							(col) => col.value === colum
						)?.label,
				  }))
				: [],
			mobileEmail: data.mobileEmail,
			sendMobile: JSON.parse(data.mobileTypeOfArchive)?.send ?? "",
			formatMobile: JSON.parse(data.mobileTypeOfArchive)?.format ?? "",
			serverMobile: JSON.parse(data.mobileTypeOfArchive)?.server ?? "",
			portMobile: JSON.parse(data.mobileTypeOfArchive)?.port ?? "",
			userMobile: JSON.parse(data.mobileTypeOfArchive)?.user ?? "",
			passwordMobile: JSON.parse(data.mobileTypeOfArchive)?.password ?? "",
			folderMobile: JSON.parse(data.mobileTypeOfArchive)?.folder ?? "",
			mobileColumnsToSend: data.mobileColumnsToSend
				? data.mobileColumnsToSend.split(";").map((colum) => ({
						value: colum,
						label: CONSTANTS.DISTRIBUTORS_COLUMNS.find(
							(col) => col.value === colum
						)?.label,
				  }))
				: [],
			distributors: data.distributorCodes?.map((i) => ({
				key: i.distributorId,
				id: i.distributorId,
				code: i.code,
				officeCode: i.distributorOfficeCode,
			})),
		};

		setImageUrl(data.photoUrl);

		setSelectedColumnsMobile(
			data.mobileColumnsToSend
				? data.mobileColumnsToSend.split(";").map((colum) => ({
						value: colum,
						label: CONSTANTS.MEGA_DISTRIBUTORS_COLUMNS.find(
							(col) => col.value === colum
						)?.label,
				  }))
				: new Array(CONSTANTS.MEGA_DISTRIBUTORS_COLUMNS.length).fill("")
		);

		setAvailableColumnsMobile(
			data.mobileColumnsToSend ? [] : CONSTANTS.MEGA_DISTRIBUTORS_COLUMNS
		);

		setActiveWeb(data.activeWeb);

		setActiveMobile(data.activeMobile);

		setAvailableTypesOfFiles(CONSTANTS.TYPE_OF_FILE);

		setAvailableSendTypes(CONSTANTS.TYPE_OF_SEND);

		setInitialValues(formData);
	};

	useEffect(() => {
		if (id) {
			fetch_megaDistributor_by_id();
		}
	}, [updated]);

	const fetch_copy_emails = async () => {
		const res = await get_emails_by_megaDistributorId(id);
		setCopyEmails(res?.copyEmails);
	};

	useEffect(() => {
		if (id) {
			fetch_copy_emails();
		}
	}, [flag]);

	const columns = [
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Nombre de Contacto</div>
			),
			dataIndex: "name",
		},
		{
			title: () => <div style={{ color: COLORS.White }}>@Mail</div>,
			dataIndex: "mail",
			render: (value, r) => (
				<div
					onClick={() => handleModifyMail(r.id)}
					onMouseOver={() => setKey(r)}
					style={{ cursor: "pointer" }}
				>
					{mailID === r.id && show ? (
						<Container onMouseLeave={() => setShow(false)}>
							<Input
								type="mail"
								style={{}}
								min={0}
								max={4}
								step={1}
								defaultValue={value}
								onChange={(e) => setNewEmail(e.target.value)}
							/>
							<Button
								onClick={() => handleEmailSave(r.id, newEmail, r.name)}
								icon={<CheckOutlined />}
							/>
							<Button
								onClick={() => handleEmailDelete(r.id)}
								icon={<DeleteOutlined style={{ color: COLORS.Danger }} />}
							/>
						</Container>
					) : (
						<Typo type="muted" fontWeight={600} fontSize={14}>
							{value}
						</Typo>
					)}
				</div>
			),
		},
	];

	const handleModifyMail = (id) => {
		setMailID(id);
		setShow(true);
	};

	const handleEmailSave = async (id, mail, name) => {
		const updateData = { name: name, mail: mail, id: id };
		await update_mega_distributor_email(key.id, updateData);
		setFlag((prev) => !prev);
		setShow(false);
	};

	const handleEmailDelete = async (id) => {
		await delete_mega_distributor_email(id);
		setFlag((prev) => !prev);
		setShow(false);
	};

	const onFinish = async (values) => {
		try {
			if (!selectedColumnsMobile?.some((column) => column.value !== "")) {
				openNotificationWithIcon(
					TYPE.ERROR,
					"Debe existir una estructura de archivo mobile para poder activar el Distribuidor"
				);
				setSubmitting(false);
				return;
			}

			setSubmitting(true);

			const newDistributor = distributors.map((d) =>
				d.checked === true
					? d.code === undefined || d.code.trim() === ""
						? { ...d, code: null, officeCode: null }
						: { ...d, code: d.code.trim(), officeCode: d.officeCode.trim() }
					: { ...d, code: null, officeCode: null }
			);

			const nonEmptyColumnsMobile = selectedColumnsMobile.filter(
				(column) => column.value !== ""
			);
			const columnsToSendMobile = nonEmptyColumnsMobile
				.map((colum) => colum.value)
				.join(";");

			const sendInfoMobile = {
				send: values.sendMobile,
				format: values.formatMobile,
				server: values.serverMobile ?? "",
				port: values.portMobile ?? "",
				user: values.userMobile ?? "",
				password: values.passwordMobile ?? "",
				folder: values.folderMobile ?? "",
			};

			const postData = {
				id: id,
				state: values.state,
				title: values.title,
				firstName: values.firstName,
				lastName: values.lastName,
				activeMobile: activeMobile,
				activeWeb: activeWeb,
				distributors: newDistributor.map((d, i) => {
					return {
						id: d.id,
						code: d.code,
						officeCode: d.officeCode,
						key: `k_${i}`,
					};
				}),
			};

			const configDataMobile = {
				id: id,
				mobileEmail: values.mobileEmail,
				mobileColumnsToSend: columnsToSendMobile,
				mobileTypeOfArchive: JSON.stringify(sendInfoMobile),
			};

			const distributorFormData = new FormData();

			distributorFormData.append(
				"file",
				values.file?.fileList?.slice(-1)[0]?.originFileObj
			);

			distributorFormData.append("data", JSON.stringify(postData));

			await update_megaDistributor(id, distributorFormData);

			await update_mega_distributor_config(id, configDataMobile);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Distribuidor editado con éxito",
				"El distribuidor se editó con éxito"
			);
			setUpdated((prev) => !prev);
			// navigate(`/admin/distributorsIndex?uri=${uri}`);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al editar el distribuidor",
				"Un error ocurrió al intentar editar el distribuidor, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	if (!id) navigate("/admin/distributorsIndex?uri=" + uri);

	if (!initialValues) return <LoadingSkeleton />;

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/distributorsIndex?uri=" + uri);
			},
		});
	};

	const handleColumnChange = (
		index,
		value,
		obj,
		state1,
		state2,
		selected,
		available
	) => {
		const updatedSelectedColumns = [...selected];
		updatedSelectedColumns[index] = { value: value, label: obj.children };

		state1(updatedSelectedColumns);

		const updatedAvailableColumns = available.filter(
			(column) => column.value !== value
		);

		state2(updatedAvailableColumns);
	};

	const handleResetMobile = () => {
		const initialValues = new Array(
			CONSTANTS.MEGA_DISTRIBUTORS_COLUMNS.length
		).fill({ value: "", label: "" });
		setSelectedColumnsMobile(initialValues);

		setAvailableColumnsMobile(CONSTANTS.MEGA_DISTRIBUTORS_COLUMNS);

		// form.resetFields();
	};

	const handleIsActiveMobile = (e) => {
		setActiveMobile(e.target.checked);
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to={"/admin/distributorsIndex?uri=" + uri}>
							Distribuidores
						</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Editar distribuidor
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Form
					form={form}
					name="basic"
					onFinish={onFinish}
					autoComplete="off"
					requiredMark={false}
					layout="vertical"
					initialValues={initialValues}
				>
					<InnerForm>
						<TopContainer>
							<ProductData>
								<InfoContainer>
									<Row gutter={GUTTER}>
										<Col span={6}>
											<Form.Item
												name="file"
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Logo del distribuidor
													</label>
												}
											>
												<UploadFileImage
													onChange={handleImageChange}
													urlImage={imageUrl}
													imageHeight="100%"
													imageWidth="100%"
												/>
											</Form.Item>
										</Col>
										<Col span={18}>
											<Row gutter={GUTTER}>
												<Col span={6}>
													<Form.Item
														label={
															<label
																style={{
																	color: COLORS.Primary,
																	fontWeight: "600",
																}}
															>
																Distribuidor
															</label>
														}
														name="title"
														rules={[
															{
																required: true,
																message: "Este campo es obligatorio",
															},
															{
																pattern: new RegExp(
																	/^(?![-.\(\)\s]*$)[-.\(\)a-zA-Z0-9\s]*$/
																),
																message:
																	"No se aceptan caracteres especiales. Solo letras y numeros",
															},
														]}
													>
														<Input placeholder="Nombre" />
													</Form.Item>
												</Col>
												<Col span={6}>
													<Form.Item
														label={
															<label
																style={{
																	color: COLORS.Primary,
																	fontWeight: "600",
																}}
															>
																Nombre contacto
															</label>
														}
														name="firstName"
														rules={[
															{
																required: true,
																message: "Este campo es obligatorio",
															},
															{
																pattern: new RegExp(
																	/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/
																),
																message:
																	"No se aceptan caracteres especiales. Solo letras y numeros",
															},
														]}
													>
														<Input placeholder="Nombre" />
													</Form.Item>
												</Col>
												<Col span={6}>
													<Form.Item
														label={
															<label
																style={{
																	color: COLORS.Primary,
																	fontWeight: "600",
																}}
															>
																Apellido contacto
															</label>
														}
														name="lastName"
														rules={[
															{
																required: true,
																message: "Este campo es obligatorio",
															},
															{
																pattern: new RegExp(
																	/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/
																),
																message:
																	"No se aceptan caracteres especiales. Solo letras y numeros",
															},
														]}
													>
														<Input placeholder="Nombre" />
													</Form.Item>
												</Col>
											</Row>
											<Row gutter={GUTTER}></Row>
											<Row gutter={GUTTER}>
												<Col span={8}>
													<Form.Item>
														<Checkbox
															onChange={handleIsActiveMobile}
															checked={activeMobile}
														>
															<Typo type="secondary" level={6}>
																Activo en Checkpos
															</Typo>
														</Checkbox>
													</Form.Item>
												</Col>
												<Col span={8}></Col>
											</Row>
										</Col>
									</Row>
								</InfoContainer>
							</ProductData>
							<RightContainer>
								<PublishSettings>
									<Space
										direction="vertical"
										size="middle"
										style={{ display: "flex" }}
									>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Estado de la droguería
												</label>
											}
											name="state"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											<Select
												allowClear={true}
												placeholder="Estado de la droguería"
											>
												{states.map((item) => (
													<Option key={item.id} value={item.id}>
														{item.name}
													</Option>
												))}
											</Select>
										</Form.Item>

										<Form.Item>
											<SignInTemplate.AntdButton
												type="Primary"
												color="white"
												bg="Primary"
												htmlType="submit"
												loading={submitting}
												block
											>
												Guardar Cambios
											</SignInTemplate.AntdButton>
										</Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											loading={submitting}
											onClick={handleBack}
											block
										>
											<Typo type="danger" level={6}>
												Volver sin guardar
											</Typo>
										</SignInTemplate.AntdButton>
									</Space>
								</PublishSettings>
							</RightContainer>
						</TopContainer>
						<ConfigContainer>
							<ConfigSection
								availableSendTypes={availableSendTypes}
								availableTypesOfFiles={availableTypesOfFiles}
								state={copyModalOpen}
								setState={setCopyModalOpen}
								copyEmails={copyEmails}
								columns={columns}
								id={id}
								setFlag={setFlag}
								handleReset={handleResetMobile}
								selectedColumns={selectedColumnsMobile}
								handleColumnChange={handleColumnChange}
								setSelectedColumns={setSelectedColumnsMobile}
								setAvailableColumns={setAvailableColumnsMobile}
								availableColumns={availableColumnsMobile}
								configType={CONSTANTS.CONFIG_MOBILE}
							/>
						</ConfigContainer>
						<FileStructureBackground>
							<Typo level={5} type="darkmuted">
								Ingrese el código interno del distribuidor para cada droguería
							</Typo>
							<MegaDistributorsCode
								initialValues={initialValues?.distributors}
								onChange={setDistributors}
							/>
						</FileStructureBackground>
					</InnerForm>
				</Form>
			</Main.Body>
		</Main>
	);
}

import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import { Link, useSearchParams } from "react-router-dom";
import {
	ChildrenContainer,
	SelectContainer,
	Section,
	Container,
} from "./styles";
import { Select } from "antd";
import { Typo } from "../../../layout/theme";
import { get_clients_users } from "../../../../api/endpoints/clients";
import AntdSelect from "../../../common/antd-select/AntdSelect";
import UserRouteList from "./list/UserRouteList";

export default function MarketUserRoutesPage() {
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [clientType, setClientType] = useState("LABORATORIO");
	const [data, setData] = useState(null);
	const [selectedClient, setSelectedClient] = useState(null);
	const [loading, setLoading] = useState(false);
	const [clientOptions, setClientOptions] = useState(null);
	const [mobileUsers, setMobileUsers] = useState(null);
	const [selectedUser, setSelectedUser] = useState(null);
	const [userOptions, setUserOptions] = useState(null);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const clientTypes = [
		{
			id: 1,
			name: "Laboratorio",
			value: "LABORATORIO",
		},
		{
			id: 2,
			name: "CheckPOS Transfer",
			value: "CHECKPOSTRANSFER",
		},
	];

	useEffect(() => {
		const fetch_clients = async () => {
			const res = await get_clients_users();
			setData(res);
		};
		setLoading(true);
		fetch_clients();
		setLoading(false);
	}, []);

	const handleClientTypeSelect = (value) => {
		setSelectedClient(null);
		setSelectedUser(null);
		switch (value) {
			case "LABORATORIO":
				setClientType(
					data
						?.filter(
							(c) =>
								!c.isOneMarket &&
								c.isSeller &&
								c.isApproved &&
								c.contract?.activeMobileApp
						)
						.sort((a, b) => a.title - b.title)
				);
				setClientOptions(
					data
						?.filter(
							(c) =>
								!c.isOneMarket &&
								c.isSeller &&
								c.isApproved &&
								c.contract?.activeMobileApp
						)
						.sort((a, b) => a.title - b.title)
						.map((r) => ({
							value: r.id,
							label: r.name,
						}))
				);
				break;
			case "CHECKPOSTRANSFER":
				setClientType(data?.filter((c) => c.isOneMarket));
				setClientOptions(
					data
						?.filter((c) => c.isOneMarket)
						.sort((a, b) => a.title - b.title)
						.map((r) => ({
							value: r.id,
							label: r.name,
						}))
				);
				break;

			default:
				break;
		}
	};

	const handleClientSelect = (value) => {
		const _data = clientType.filter((c) => c.id === value);
		setSelectedClient(_data);
		setMobileUsers(
			_data[0]?.users
				.filter((u) => u.p_mobile && !u.p_shop)
				.sort((a, b) => a.firstName - b.firstName)
		);
		setUserOptions(
			_data[0]?.users
				.filter((u) => u.p_mobile && !u.p_shop)
				.sort((a, b) => a.firstName - b.firstName)
				.map((r) => ({
					value: r.id,
					label: r.firstName + " " + r.lastName,
				}))
		);
	};

	const handleUserSelect = (value) => {
		setSelectedUser(mobileUsers.filter((u) => u.id === value));
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Rutas de Usuario
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<SelectContainer style={{ flexBasis: 0 }}>
					<Container>
						<Section>
							<Typo type="primary" level={6}>
								Tipo de Cuenta
							</Typo>
							<AntdSelect
								onChange={handleClientTypeSelect}
								style={{ width: 160 }}
								loading={loading || !data}
								disabled={!data}
							>
								{clientTypes?.map((item) => (
									<Select.Option key={item.id} value={item.value}>
										{item.name}
									</Select.Option>
								))}
							</AntdSelect>
						</Section>
						<Section>
							{clientType && (
								<Typo type="primary" level={6}>
									Cuentas
								</Typo>
							)}
							{clientType && (
								<Select
									showSearch
									placeholder="Elegi un cliente"
									optionFilterProp="children"
									onChange={handleClientSelect}
									value={selectedClient ? selectedClient[0].name : "..."}
									filterOption={(input, option) =>
										(option?.label ?? "")
											.toLowerCase()
											.includes(input.toLowerCase())
									}
									options={clientOptions}
									style={{ width: 180 }}
								/>
							)}
						</Section>
						<Section>
							{selectedClient && (
								<Typo type="primary" level={6}>
									Usuario
								</Typo>
							)}
							{selectedClient && (
								<Select
									showSearch
									placeholder="Elegi un usuario"
									optionFilterProp="children"
									onChange={handleUserSelect}
									value={
										selectedUser
											? selectedUser[0].firstName +
											  " " +
											  selectedUser[0].lastName
											: "..."
									}
									filterOption={(input, option) =>
										(option?.label ?? "")
											.toLowerCase()
											.includes(input.toLowerCase())
									}
									options={userOptions}
									style={{ width: 180 }}
								/>
							)}
						</Section>
					</Container>
				</SelectContainer>
				{selectedUser && (
					<ChildrenContainer>
						<UserRouteList
							clientId={selectedClient[0].id}
							client={selectedClient[0]}
							userId={selectedUser[0].id}
							user={selectedUser[0]}
							userType="MOBILE"
						/>
					</ChildrenContainer>
				)}
			</Main.Body>
		</Main>
	);
}

import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import { Link, useSearchParams } from "react-router-dom";
import { ButtonContainer, ChildrenContainer, AntdButton } from "./styles";
import { get_client_by_id } from "../../../../api/endpoints/clients";
import UsersList from "./list/UsersList";
import { useAuth } from "../../../../contexts/authContext";
import { useFilter } from "../../../../contexts/filterContext";
import { CONSTANTS } from "../../../../utils/constants";

export default function UsersPage({ clientId }) {
	const { adminUsersType, setAdminUsersType } = useFilter();
	const [selectedClient, setSelectedClient] = useState(null);
	const { user, userdata, updateStartGuideProgress } = useAuth();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");

	const get_filter = () => {
		setAdminUsersType(
			localStorage.getItem("marketUsersIndexFilter")
				? localStorage.getItem("marketUsersIndexFilter")
				: "WEBUSERS"
		);
	};

	useEffect(() => {
		if (!userdata.isSeller && !userdata.isOneMarket) get_filter();
	}, []);

	useEffect(() => {
		const fetch_clients = async () => {
			const res = await get_client_by_id(clientId);
			setSelectedClient(res.client);
		};
		fetch_clients();
	}, []);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Usuarios</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				{selectedClient && selectedClient.contract?.activeMobileApp && !uri && (
					<ButtonContainer>
						{/* <AntdButton
							type="primary"
							bg="Primary"
							color="White"
							style={{ fontWeight: "600", marginLeft: "20px" }}
							width={220}
							height={48}
							fontSize={12}
							onClick={() => setAdminUsersType(CONSTANTS.USERS_WEB)}
							$active={adminUsersType === CONSTANTS.USERS_WEB}
						>
							Usuarios Web
						</AntdButton> */}
						{selectedClient.contract?.activeMobileApp && (
							<AntdButton
								type="primary"
								bg="Primary"
								color="White"
								style={{ fontWeight: "600", marginLeft: "20px" }}
								width={220}
								height={48}
								fontSize={12}
								onClick={() => setAdminUsersType(CONSTANTS.USERS_MOBILE)}
								$active={adminUsersType === CONSTANTS.USERS_MOBILE}
							>
								Usuarios
							</AntdButton>
						)}
					</ButtonContainer>
				)}

				{selectedClient && (
					<ChildrenContainer>
						{/* {adminUsersType === CONSTANTS.USERS_WEB && (
							<UsersList
								usersData={selectedClient.users}
								clientId={clientId}
								client={selectedClient}
								userType="WEB"
								user={user}
								userdata={userdata}
								updateStartGuideProgress={updateStartGuideProgress}
								guideProgress={4}
							/>
						)} */}
						{adminUsersType === CONSTANTS.USERS_MOBILE && (
							<UsersList
								usersData={selectedClient.users}
								clientId={clientId}
								client={selectedClient}
								userType="MOBILE"
								user={user}
								userdata={userdata}
								updateStartGuideProgress={updateStartGuideProgress}
								guideProgress={4}
							/>
						)}
					</ChildrenContainer>
				)}
			</Main.Body>
		</Main>
	);
}

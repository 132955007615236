import axiosInstance from "../axiosInstance";

const CONTROLLER = "chain";

const get_chains = async (search, state) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?search=${search || ""}&state=${state}`
	);
	return response.data;
};

const get_chain_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetChainById/${id}`);
	return response.data;
};

const get_chains_header_info = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetChainsHeader`);
	return response.data;
};

const update_chain = async (id, data) => {
	const response = await axiosInstance.put(`${CONTROLLER}/${id}`, data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const update_chain_state = async (state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateChainStatus`,
		state
	);
	return response.data;
};

const create_chain = async (data) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

export {
	create_chain,
	get_chains,
	get_chains_header_info,
	update_chain_state,
	get_chain_by_id,
	update_chain,
};

import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import { useMemo, useRef, useState, useEffect } from "react";
import { Section } from "./styles";

function DebounceSelect({
	fetchOptions,
	debounceTimeout = 400,
	initialOptions = [],
	...props
}) {
	const [fetching, setFetching] = useState(false);
	const [options, setOptions] = useState(
		initialOptions.length > 0 ? initialOptions : fetchOptions
	);

	useEffect(() => {
		if (initialOptions.length > 0) return;
		setFetching(true);
		setOptions(fetchOptions);
		setFetching(false);
	}, []);

	const debounceFetcher = useMemo(() => {
		const loadOptions = (value) => {
			// setOptions([]);
			setFetching(true);
			setOptions(fetchOptions);
			setFetching(false);
		};

		return debounce(loadOptions, debounceTimeout);
	}, [fetchOptions, debounceTimeout]);

	return (
		<Select
			labelInValue
			filterOption={false}
			onSearch={debounceFetcher}
			notFoundContent={fetching ? <Spin size="small" /> : null}
			{...props}
			options={options}
		/>
	);
}

export default function DebounceSelectSeller({
	fetchCallback,
	placeholder,
	onValuesChange,
	initialValues,
	disabled,
}) {
	const initialValueArray =
		initialValues.length > 0 ? initialValues.split(",") : [];

	const initialOptions =
		initialValueArray.length > 0
			? fetchCallback?.filter((option) =>
					initialValueArray.includes(option.value.toString())
			  )
			: fetchCallback;

	return (
		<Section>
			<DebounceSelect
				mode="multiple"
				value={initialValueArray}
				placeholder={placeholder}
				fetchOptions={fetchCallback}
				onChange={onValuesChange}
				style={{ width: 180 }}
				initialOptions={initialOptions}
				disabled={disabled}
				maxTagCount={1}
				maxTagTextLength={6}
			/>
		</Section>
	);
}

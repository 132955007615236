import React, { useEffect, useState } from "react";
import {
	Col,
	DatePicker,
	Form,
	Input,
	message,
	Row,
	Select,
	Steps,
} from "antd";
import {
	Link,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Main from "../../../../layout/main/Main";
import {
	InnerForm,
	NewMarketing,
	InfoContainer,
	Inner,
	DataContainer,
	AntdModal,
	Section,
} from "./styles";
import moment from "moment";
import { get_provinces } from "../../../../../api/endpoints/region";
import { get_clients_name_list_filtered_expo } from "../../../../../api/endpoints/clients";
import { useAuth } from "../../../../../contexts/authContext";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import {
	get_initiative_by_id,
	update_initiative,
} from "../../../../../api/endpoints/initiative";
import AddProductsInitiativeTable from "../../../../common/add-products-initiative-table/AddProductsInitiativeTable";
import ProductCard from "./components/product-card/ProductCard";
import { COLORS } from "../../../../layout/theme";
import UploadFileImage from "../../../../common/upload-file-with-image/UploadFileImage";

const { Step } = Steps;
const GUTTER = 10;
const { RangePicker } = DatePicker;

export default function LaboratoryUpdateInitiative({ clientId }) {
	const [form] = Form.useForm();
	const { id } = useParams();
	const { user } = useAuth();
	const [imageUrl, setImageUrl] = useState();
	const [imageToSend, setImageToSend] = useState(null);
	const [initialValues, setInitialValues] = useState(null);
	const [clients, setClients] = useState(null);
	const [idProvincia, setIdProvincia] = useState(null);
	const [listadoProvincias, setListadoProvincias] = useState(null);
	const [listadoLocalidades, setListadoLocalidades] = useState(null);
	const [provincia, setProvincia] = useState(null);
	const [localidad, setLocalidad] = useState();
	const [submitting, setSubmitting] = useState(false);
	const [updateEventSteps, setUpdateEventSteps] = useState(1);
	const [hasChanges, setHasChanges] = useState(false);
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [showProductsModal, setShowProductsModal] = useState(false);
	const [productsForInitiative, setProductsForInitiative] = useState([]);

	const navigate = useNavigate();

	useEffect(() => {
		const fetch_market_client_list = async () => {
			const res = await get_clients_name_list_filtered_expo();
			setClients(
				res
					?.filter((c) => c.isSeller)
					?.map((r) => ({
						value: r.id,
						label: r.name,
						isApproved: r.isApproved,
					}))
			);
		};

		fetch_market_client_list();
	}, []);

	useEffect(() => {
		const fetch_region_1_data = async () => {
			const res = await get_provinces();

			setListadoProvincias(
				res
					.map((p) => ({
						id: p.id,
						name: p.title,
						localities: p.localities.map((l) => ({
							localityId: l.locality.id,
							localityName: l.locality.title,
						})),
					}))
					.sort((a, b) => {
						const titleA = a.name.toLowerCase();
						const titleB = b.name.toLowerCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}

						return 0;
					})
			);

			setListadoLocalidades(res.filter((p) => p.id === idProvincia));
		};

		fetch_region_1_data();
	}, [idProvincia]);

	const getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => callback(reader.result));
		reader.readAsDataURL(img);
	};

	const disabledDate = (current) => {
		return current && current < moment().add(-1, "days").endOf(-1, "day");
	};

	const fetch_initiative_by_id = async (id) => {
		const res = await get_initiative_by_id(id);
		const init = {
			title: res.title,
			publish: [moment(res.publishFrom), moment(res.publishTo)],
			products: res.products,
		};
		setInitialValues(init);
		setImageUrl(res.photoUrl);
		setProductsForInitiative(
			res.products.map((i) => ({
				id: i.productId,
				title: i.productTitle,
				presentationName: i.productPresentation,
				ean13: i.productEan13,
				photoUrl: i.productPhotoUrl,
				key: i.productId,
			}))
		);

		setProvincia(
			res.provinceId
				? {
						key: res.provinceId,
						value: res.provinceId,
						label: res.provinceName,
				  }
				: null
		);

		setLocalidad(
			res.localityId
				? [
						{
							key: res.localityId,
							value: res.localityId,
							label: res.localityName,
						},
				  ]
				: undefined
		);
		setIdProvincia(res.provinceId ? res.provinceId : null);
	};

	useEffect(() => {
		fetch_initiative_by_id(id);
	}, []);

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
			setImageToSend(info.fileList[info.fileList.length - 1].originFileObj);
		} catch (error) {
			console.log(error);
		}
	};

	const onFinish = async (values) => {
		try {
			const postData = {
				title: values?.title,
				clientId: clientId,
				photoUrl: imageUrl,
				products: productsForInitiative.map((p) => ({
					productId: p.id,
				})),
				publishFrom: initialValues.publish[0].format("YYYY-MM-DD"),
				publishTo: initialValues.publish[1].format("YYYY-MM-DD"),
				region: localidad?.value
					? `ARG-${idProvincia}-${localidad.value}`
					: idProvincia
					? `ARG-${idProvincia}-`
					: null,
				provinceId: idProvincia,
				localityId: localidad?.value ?? null,
			};

			setSubmitting(true);

			const formData = new FormData();

			formData.append("data", JSON.stringify(postData));
			formData.append("file", imageToSend);

			const res = await update_initiative(id, formData);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Iniciativa actualizada con exito",
				"La iniciativa se actualizo con exito"
			);
			navigate(`/admin/laboratoryInitiatives?uri=${uri}`);
		} catch (err) {
			console.log(err);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al actualizar el evento",
				"Un error ocurrio al intentar actualizar el evento, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleProvinciasSelect = (value, object) => {
		setProvincia(object);
		setIdProvincia(value);
	};

	const handleLocalidadSelect = (value, objectList) => {
		setLocalidad(objectList);
	};

	const handleModalOk = () => {
		setShowProductsModal(false);
	};

	const handleModalCancel = () => {
		setShowProductsModal(false);
	};

	if (!initialValues) return <p>Cargando...</p>;

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to={"/admin/laboratoryInitiatives?uri=" + uri}>
							Iniciativas
						</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Actualizar Iniciativa
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<NewMarketing>
					<Form
						form={form}
						name="create-initiative"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
					>
						<InnerForm>
							<DataContainer>
								<Row gutter={GUTTER} style={{ width: "100%" }}>
									<Col md={6} xl={6}>
										<Form.Item
											label={
												<label
													style={{
														color: COLORS.Primary,
														fontWeight: "600",
													}}
												>
													Imagen
												</label>
											}
											name="file"
										>
											<UploadFileImage
												onChange={handleImageChange}
												urlImage={imageUrl}
												imageHeight="100%"
												imageWidth="100%"
											/>
										</Form.Item>
									</Col>
									<Col md={12} xl={12}>
										<Row gutter={GUTTER}>
											<Col md={12} xl={12}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Nombre de iniciativa
														</label>
													}
													name="title"
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
														{
															pattern: new RegExp(
																/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
															),
															message:
																"No se aceptan caracteres especiales. Solo letras y numeros",
														},
													]}
												>
													<Input placeholder="Nombre" />
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Duracion
														</label>
													}
													name="publish"
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
													]}
												>
													<RangePicker
														disabled
														disabledDate={disabledDate}
														format="DD/MM/YYYY"
														style={{
															width: "100%",
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={GUTTER}>
											<Col span={12}>
												<Form.Item
													label="Provincia"
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
													]}
												>
													<Select
														onChange={handleProvinciasSelect}
														value={provincia}
													>
														{listadoProvincias
															?.sort((a, b) => {
																const titleA = a.name.toLowerCase();
																const titleB = b.name.toLowerCase();
																if (titleA < titleB) {
																	return -1;
																}
																if (titleA > titleB) {
																	return 1;
																}

																return 0;
															})
															.map((p) => (
																<Select.Option key={p.id} value={p.id}>
																	{p.name}
																</Select.Option>
															))}
													</Select>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item
													label={provincia ? "Localidad" : ""}
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
													]}
												>
													{provincia && listadoLocalidades ? (
														<Select
															allowClear={true}
															placeholder="Elegir Localidad"
															onClear={() => setLocalidad(undefined)}
															onChange={handleLocalidadSelect}
															value={localidad}
														>
															{idProvincia &&
																listadoLocalidades[0]?.localities
																	.sort((a, b) => {
																		const titleA =
																			a.locality.title.toLowerCase();
																		const titleB =
																			b.locality.title.toLowerCase();
																		if (titleA < titleB) {
																			return -1;
																		}
																		if (titleA > titleB) {
																			return 1;
																		}

																		return 0;
																	})
																	.map((p) => (
																		<Select.Option
																			key={p.locality.id}
																			value={p.locality.id}
																		>
																			{p.locality.title}
																		</Select.Option>
																	))}
														</Select>
													) : (
														""
													)}
												</Form.Item>
											</Col>
										</Row>
									</Col>
								</Row>
							</DataContainer>
							<DataContainer>
								<>
									<SignInTemplate.AntdButton
										type="Primary"
										color="white"
										bg="Primary"
										onClick={() => setShowProductsModal(true)}
										loading={submitting}
										style={{ width: "200px" }}
									>
										Asignar productos
									</SignInTemplate.AntdButton>
									<Section>
										{productsForInitiative?.map((p, idx) => (
											<ProductCard key={idx} product={p} />
										))}
									</Section>
								</>
							</DataContainer>
							<AntdModal
								visible={showProductsModal}
								onOk={handleModalOk}
								onCancel={handleModalCancel}
								width={1200}
								centered={true}
								footer={null}
								destroyOnClose={true}
							>
								<InfoContainer>
									<AddProductsInitiativeTable
										templateProducts={productsForInitiative}
										setTemplateProducts={setProductsForInitiative}
										userId={user.uid}
										laboratoryId={clientId}
										isMultiLab={false}
									/>
								</InfoContainer>
							</AntdModal>
							<DataContainer style={{ alignItems: "center" }}>
								<SignInTemplate.AntdButton
									type="Primary"
									color="white"
									bg="Primary"
									htmlType="submit"
									loading={submitting}
									style={{ width: "200px" }}
								>
									Guardar cambios
								</SignInTemplate.AntdButton>
							</DataContainer>
						</InnerForm>
					</Form>
				</NewMarketing>
			</Main.Body>
		</Main>
	);
}

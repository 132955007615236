import React, { useState } from "react";
import {
	Container,
	ModuleContainer,
	Section,
	StyledLi,
	StyledUl,
} from "./styles";
import {
	MdFormatListBulleted,
	MdMobileScreenShare,
	MdOutlineChecklist,
	MdOutlineDeveloperMode,
	MdOutlinePlace,
	MdPanoramaPhotosphere,
} from "react-icons/md";
import { COLORS, Typo } from "../../../../../layout/theme";
import { Link } from "react-router-dom";
import {
	CalendarOutlined,
	GlobalOutlined,
	IdcardOutlined,
	SettingOutlined,
	SnippetsOutlined,
	UserSwitchOutlined,
} from "@ant-design/icons";
import { BiCheckCircle, BiCoinStack, BiImport } from "react-icons/bi";
import { TbDeviceMobile, TbTransferOut } from "react-icons/tb";
import { FaHeadset } from "react-icons/fa";
import { CONSTANTS } from "../../../../../../utils/constants";
import { Badge } from "antd";

export default function ModuleErp({ userdata, userRol, toValidateQty }) {
	const [showSubMenu, setShowSubMenu] = useState(null);

	return (
		<Section>
			<ModuleContainer>
				<Container>
					<MdOutlineDeveloperMode
						style={{ color: COLORS.Secondary }}
						size={50}
					/>
					<Typo type="Grey" level={4}>
						ERP
					</Typo>
					<Typo type="mediumGrey" weight="light">
						Administra las aplicaciones
					</Typo>
				</Container>
				<StyledUl>
					<StyledLi
						onClick={() =>
							setShowSubMenu(CONSTANTS.MODULE_MARKETPLACE_PRODUCTS)
						}
						style={{ cursor: "pointer" }}
					>
						<MdFormatListBulleted color={COLORS.Secondary} />
						<Typo type="secondary" level={13}>
							Gestión de productos
						</Typo>
					</StyledLi>
					<Link to="/admin/marketRoutes?uri=generalConfig">
						<StyledLi>
							<MdOutlinePlace color={COLORS.Secondary} />
							<Typo type="secondary" level={13}>
								Rutas laboratorios
							</Typo>
						</StyledLi>
					</Link>
					<StyledLi
						onClick={() => setShowSubMenu(CONSTANTS.MODULE_ERP_MOBILE)}
						style={{ cursor: "pointer" }}
					>
						<MdMobileScreenShare style={{ color: COLORS.Secondary }} />
						<Typo type="secondary" level={13}>
							Mobile
						</Typo>
					</StyledLi>
					<Link to="/admin/marketWebSellers?uri=generalConfig">
						<StyledLi>
							<FaHeadset color={COLORS.Secondary} />
							<Typo type="secondary" level={13}>
								Dinámicas callcenter
							</Typo>
						</StyledLi>
					</Link>
					<Link to="/admin/expoEventOT?uri=generalConfig">
						<StyledLi>
							<CalendarOutlined style={{ color: COLORS.Secondary }} />
							<Typo type="secondary" level={13}>
								Eventos
							</Typo>
						</StyledLi>
					</Link>
					<Link to="/admin/distributorResponseCT?uri=generalConfig">
						<StyledLi>
							<SnippetsOutlined style={{ color: COLORS.Secondary }} />
							<Typo type="secondary" level={13}>
								Carga detalle pedidos
							</Typo>
						</StyledLi>
					</Link>
					<Link to="/admin/orderStateCT?uri=generalConfig">
						<StyledLi>
							<TbTransferOut style={{ color: COLORS.Secondary }} />
							<Typo type="secondary" level={13}>
								Estado general pedidos
							</Typo>
						</StyledLi>
					</Link>
				</StyledUl>
			</ModuleContainer>
			{showSubMenu === CONSTANTS.MODULE_MARKETPLACE_PRODUCTS && (
				<ModuleContainer>
					<Container>
						<MdFormatListBulleted
							style={{ color: COLORS.Secondary }}
							size={50}
						/>
						<Typo type="Grey" level={4}>
							Gestión de Productos
						</Typo>
						<Typo type="mediumGrey" weight="light">
							Sección para administrar productos y ofertas del marketplace
						</Typo>
					</Container>
					<StyledUl>
						<Link to="/admin/marketProductsIndex?uri=generalConfig">
							<StyledLi>
								<MdOutlineChecklist color={COLORS.Secondary} />
								<Typo type="secondary" level={13}>
									Productos
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/import?uri=generalConfig">
							<StyledLi>
								<BiImport style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Importadores
								</Typo>
							</StyledLi>
						</Link>
					</StyledUl>
				</ModuleContainer>
			)}
			{showSubMenu === CONSTANTS.MODULE_ERP_MOBILE && (
				<ModuleContainer>
					<Container>
						<MdMobileScreenShare
							style={{ color: COLORS.Secondary }}
							size={50}
						/>
						<Typo type="Grey" level={4}>
							Mobile
						</Typo>
						<Typo type="mediumGrey" weight="light">
							Sección para administrar aplicación mobile
						</Typo>
					</Container>
					<StyledUl>
						<Link to="/admin/marketUserRoutes?uri=generalConfig">
							<StyledLi>
								<UserSwitchOutlined style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Rutas usuarios
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/marketProductDiscounts?uri=generalConfig">
							<StyledLi>
								<BiCoinStack style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Dinámicas Mobile
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/marketUserDiscounts?uri=generalConfig">
							<StyledLi>
								<TbDeviceMobile color={COLORS.Secondary} />
								<Typo type="secondary" level={13}>
									Dinámicas vendedores mobile
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/marketMobileConfigs?uri=generalConfig">
							<StyledLi>
								<SettingOutlined style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Configuraciones mobile
								</Typo>
							</StyledLi>
						</Link>
					</StyledUl>
				</ModuleContainer>
			)}
		</Section>
	);
}

import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, Menu, Space, Modal, Spin } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DropShadow from "../../../../common/drop-shadow/DropShadow";
import Main from "../../../../layout/main/Main";
import {
	Filters,
	Span,
	SpanCointainer,
	CampaignsContainer,
	ActionButton,
	CardContainer,
} from "./styles";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Typo } from "../../../../layout/theme";
import debounce from "lodash/debounce";
import { CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import AntdSearch from "../../../../common/antd-search/AntdSearch";
import {
	get_expo_events,
	get_expo_events_header_info,
	update_expo_event_state,
} from "../../../../../api/endpoints/event";
import EventCardContainer from "../../../../containers/event-card/EventCardContainer";

const { confirm } = Modal;

export default function MarketExpoEventList() {
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const navigate = useNavigate();
	const [events, setEvents] = useState(null);
	const [key, setKey] = useState(null);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [eventsState, setEventsState] = useState("ACTIVE");
	const [selectedClient, setSelectedClient] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const fetch_events = async (search) => {
		setLoading(true);
		try {
			const res = await get_expo_events(
				search,
				selectedClient || "",
				eventsState
			);
			setEvents(res);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const fetch_events_header = async () => {
		const res = await get_expo_events_header_info("");
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_events("");
		fetch_events_header();
	}, [selectedClient, eventsState]);

	const handleInactivate = (key) => {
		confirm({
			title: "¿Seguro que quiere desactivar este evento?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "El evento se desactivara de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "INACTIVE");
			},
		});
	};

	const handleActivate = (key) => {
		confirm({
			title: "¿Seguro que quiere activar este evento?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "El evento se activara de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "ACTIVE");
			},
		});
	};

	const handleDeleted = (key) => {
		confirm({
			title: "¿Seguro que quiere eliminar este evento?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "El evento se eliminara de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "DELETED");
			},
		});
	};

	const handleAction = async (key, state) => {
		try {
			const _state = { id: key, state: state };
			await update_expo_event_state(_state);
			fetch_events("");
			fetch_events_header();
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Evento actualizado con exito",
				"El evento se actualizo con exito"
			);
		} catch (err) {
			console.log(err);
			openNotificationWithIcon(
				TYPE.ERROR,
				"No se puedo actualizar",
				"No se puede actualizar un evento que la fecha de finalizacion es anterior a la de hoy"
			);
		}
	};

	const menuActive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/expoEventOT/${key}?uri=${uri}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleInactivate(key)}>Desactivar</Typo>,
				},
			]}
		/>
	);

	const menuInactive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/expoEventOT/${key}?uri=${uri}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleActivate(key)}>Activar</Typo>,
				},
				{
					key: "3",
					label: <Typo onClick={() => handleDeleted(key)}>Borrar</Typo>,
				},
			]}
		/>
	);

	const menuDeleted = (
		<Menu
			items={[
				{
					key: "1",
					label: <Typo onClick={() => handleDeleted(key)}>Borrar</Typo>,
				},
			]}
		/>
	);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_events(e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	// if (!events) return <SkeletonPublicity />;

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						{uri ? "Eventos" : "Expo"}
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Filters>
					<Space size="large">
						<AntdSearch
							allowClear
							enterButton={true}
							placeholder="Buscar evento"
							onChange={debouncedEventHandler}
							width={400}
						/>
						<DropShadow type="drop">
							<SignInTemplate.AntdButton
								type="primary"
								bg="Primary"
								color="White"
								icon={
									<AiOutlinePlusCircle
										style={{
											top: 2,
											position: "relative",
											marginRight: "5px",
										}}
									/>
								}
								onClick={() => navigate("/admin/expoEventOT/create?uri=" + uri)}
								style={{ fontWeight: "600" }}
								width={220}
								height={38}
								fontSize={12}
							>
								CREAR EVENTO
							</SignInTemplate.AntdButton>
						</DropShadow>
					</Space>
				</Filters>
				<SpanCointainer>
					<Span onClick={() => setEventsState("ACTIVE")}>
						Activos ({headerInfo?.totalActive})
					</Span>
					<Span onClick={() => setEventsState("FUTURE")}>
						Proximos ({headerInfo?.totalFuture})
					</Span>
					<Span onClick={() => setEventsState("FINISHED")}>
						Terminados ({headerInfo?.totalFinished})
					</Span>
					<Span onClick={() => setEventsState("INACTIVE")}>
						Inactivos ({headerInfo?.totalInactive})
					</Span>
					<Span onClick={() => setEventsState("DELETED")}>
						Borrados ({headerInfo?.totalDeleted})
					</Span>
				</SpanCointainer>
				<CampaignsContainer>
					{loading || !events ? (
						<CardContainer>
							<Spin />
						</CardContainer>
					) : (
						events?.map((i) => (
							<CardContainer>
								<EventCardContainer item={i} />
								{eventsState !== "DELETED" && (
									<Dropdown
										overlay={
											eventsState === "ACTIVE"
												? menuActive
												: eventsState === "FUTURE"
												? menuActive
												: eventsState === "DELETED"
												? menuDeleted
												: menuInactive
										}
										placement="bottomRight"
									>
										<ActionButton onMouseOver={() => setKey(i.id)}>
											<BsThreeDotsVertical />
										</ActionButton>
									</Dropdown>
								)}
							</CardContainer>
						))
					)}
				</CampaignsContainer>
			</Main.Body>
		</Main>
	);
}

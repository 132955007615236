import React, { useEffect, useState } from "react";
import {
	AntdTable,
	BodyContainer,
	Container,
	FilterContainer,
	HeaderContainer,
	Section,
	StepContainer,
} from "./styles";
import { COLORS, SIZES, Typo } from "../../../../../../../layout/theme";
import { Button, Select, Spin } from "antd";
import { numberWithDotAndCommas } from "../../../../../../../../utils/numberWithDotAndComas";
import { CONSTANTS } from "../../../../../../../../utils/constants";
import { IoIosArrowRoundBack } from "react-icons/io";
import moment from "moment";
import { download_crm_order_detail } from "../../../../../../../../api/endpoints/orders";
import { BsDownload } from "react-icons/bs";
import CustomAntdTooltip from "../../../../../../../common/custom-antd-tooltip/CustomAntdTooltip";

export default function Step3OrderList({
	stepData,
	handleBackStep,
	handleChangeStep,
}) {
	const [loading, setLoading] = useState(false);
	const [orderList, setOrderList] = useState(stepData?.orders);
	const [selectedDistributor, setSelectedDistributor] = useState(null);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (!selectedDistributor) {
			setOrderList(stepData?.orders);
		} else {
			const filteredOrders = stepData?.orders?.filter(
				(o) => o.distributorId === selectedDistributor
			);
			setOrderList(filteredOrders);
		}
	}, [selectedDistributor]);

	const selectStateToShow = (state) => {
		switch (state) {
			case CONSTANTS.ORDER_STATE_RECEIVED:
				return "Recibida";
			case CONSTANTS.ORDER_STATE_PENDING:
				return "Pendiente";
			case CONSTANTS.ORDER_STATE_ACTIVE:
				return "Activa";
			case CONSTANTS.ORDER_STATE_SENDED:
				return "Enviada";
			case CONSTANTS.ORDER_STATE_CANCELED:
				return "Cancelada";
			case CONSTANTS.ORDER_STATE_PROCESSED:
				return "Procesada";
			case CONSTANTS.ORDER_STATE_BILLED:
				return "Facturada";
			case CONSTANTS.ORDER_STATE_REJECTED:
				return "Rechazada";

			default:
				return;
		}
	};

	const columns = [
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					TRF
				</div>
			),
			width: 80,
			dataIndex: "orderNumId",
			render: (value, record) => (
				<Section
					onClick={() =>
						handleChangeStep(
							record?.id,
							record?.listFromPos
								? CONSTANTS.ERP_MODULE_USERS_STEP_7
								: CONSTANTS.ERP_MODULE_USERS_STEP_4,
							"",
							record?.listFromPos ? record?.id : ""
						)
					}
				>
					<Typo level={6} type="primary">
						{value}
					</Typo>
				</Section>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Droguería
				</div>
			),
			width: 160,
			dataIndex: "distributorName",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Productos
				</div>
			),
			width: 90,
			dataIndex: "qtyItems",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Unidades
				</div>
			),
			width: 90,
			dataIndex: "qtyUnits",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Subtotal
				</div>
			),
			dataIndex: "subtotal",
			render: (value, record) => (
				<Typo level={7} type="muted">
					${numberWithDotAndCommas(value)}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Descuento
				</div>
			),
			dataIndex: "discount",
			render: (value, record) => (
				<Typo level={7} type="muted">
					${numberWithDotAndCommas(value)}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Total
				</div>
			),
			dataIndex: "total",
			render: (value, record) => (
				<Typo level={7} type="muted">
					${numberWithDotAndCommas(value)}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Estado
				</div>
			),
			dataIndex: "orderState",
			render: (value, record) => (
				<>
					{record.orderSubState ? (
						<Typo
							level={7}
							type={
								record.orderSubState === CONSTANTS.ORDER_STATE_RECEIVED
									? "secondary"
									: record.orderSubState === CONSTANTS.ORDER_STATE_PROCESSED
									? "secondary"
									: record.orderSubState === CONSTANTS.ORDER_STATE_BILLED
									? "terciary"
									: record.orderSubState === CONSTANTS.ORDER_STATE_REJECTED
									? "danger"
									: "muted"
							}
						>
							{selectStateToShow(record.orderSubState)}
						</Typo>
					) : (
						<Typo level={7} type="muted">
							{selectStateToShow(value)}
						</Typo>
					)}
				</>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Origen
				</div>
			),
			dataIndex: "typeOfSale",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
	];

	const handleSelectDistributor = (e, o) => {
		setSelectedDistributor(e);
	};

	const handleDeleteFilters = () => {
		setSelectedDistributor(null);
	};

	const handleDownloadFile = async () => {
		setLoading(true);
		try {
			const _orderData = orderList.map((o) => ({
				trf: o?.orderNumId,
				pointOfSaleName: o?.pointOfSaleName,
				pointOfSaleAddress: o?.pointOfSaleAddress,
				distributorName: o?.distributorName,
				orderDate: o?.created,
				orderQtyItems: o?.qtyItems,
				orderQtyUnits: o?.qtyUnits,
				orderSubtotal: o?.subtotal,
				orderTotal: o?.total,
				orderDiscount: o?.discount,
				orderProvince: o?.provinceName,
				orderLocality: o?.localityName,
				orderState: o?.orderState,
			}));

			const _orderProducts = [];

			const res = await download_crm_order_detail({
				orderToExport: _orderData,
				orderProductsToExport: _orderProducts,
			});
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const _fileName =
				"Listado_ordenes_Vendedor_" +
				stepData?.firstName +
				"_" +
				stepData?.lastName +
				"_" +
				moment().format("DD-MM-YYYY") +
				".xlsx";
			link.setAttribute("download", _fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<StepContainer>
			<HeaderContainer style={{ padding: 10, justifyContent: "space-between" }}>
				<Section
					onClick={() => handleBackStep(stepData?.subBackStep ? 5 : 2)}
					style={{
						justifyContent: "start",
						alignItems: "center",
						display: "flex",
					}}
				>
					<IoIosArrowRoundBack size={19} color={COLORS.Secondary} />
					<Typo type="secondary" level={7}>
						Volver
					</Typo>
				</Section>
				<Section>
					<Typo type="dark" level={6}>
						{stepData && stepData.firstName && stepData.lastName
							? "Listado ordenes " +
							  stepData?.firstName +
							  " " +
							  stepData?.lastName
							: "Listado ordenes " + stepData?.name}
					</Typo>
				</Section>
				<Section>
					{loading ? (
						<Spin size="small" />
					) : (
						<CustomAntdTooltip
							placement="left"
							width="105px"
							tooltipContent={[
								{
									body: "Exportar datos",
								},
							]}
						>
							<BsDownload
								size={18}
								color={COLORS.Secondary}
								style={{ cursor: "pointer" }}
								onClick={handleDownloadFile}
							/>
						</CustomAntdTooltip>
					)}
				</Section>
			</HeaderContainer>
			<HeaderContainer>
				<Container>
					<FilterContainer>
						<Typo type="grey" level={6}>
							Droguería
						</Typo>
						<Select
							showSearch
							allowClear
							placeholder="Droguería"
							optionFilterProp="children"
							value={selectedDistributor}
							onChange={(e, o) => handleSelectDistributor(e, o)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={stepData?.distributors}
							style={{ width: 200 }}
						/>
					</FilterContainer>
				</Container>
				<Container style={{ justifyContent: "flex-end", width: "160px" }}>
					<Button type="primary" onClick={handleDeleteFilters}>
						Borrar filtros
					</Button>
				</Container>
			</HeaderContainer>
			<BodyContainer>
				<AntdTable
					dataSource={orderList}
					loading={!stepData}
					columns={columns}
					pagination={false}
					scroll={{ y: "1020px" }}
				/>
			</BodyContainer>
		</StepContainer>
	);
}

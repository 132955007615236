import React from "react";
import Box from "../box/Box";
import { TbActivityHeartbeat } from "react-icons/tb";
import { Typo } from "../../../../../layout/theme";
import { BiBox, BiBuildings, BiDollar, BiCoinStack } from "react-icons/bi";
import { MdSafetyDivider } from "react-icons/md";
import {
	BigChartContainer,
	Container,
	Dashboard1Container,
	DoubleChartContainer,
	FilterContainer,
	HeaderContainer,
} from "./styles";
import { Button, DatePicker, Select, Switch } from "antd";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import { CONSTANTS } from "../../../../../../utils/constants";

export default function Dashboard2({
	dashboard,
	optionsPie,
	timeSalesGraph,
	timePharmacyOrdersGraph,
	timeOrderGraph,
	distributorSalesGraph,
	timeUnitsGraph,
	distributors,
	selectDistributor,
	provinces,
	selectProvince,
	laboratories,
	selectLaboratory,
	handleDateChange,
	deleteFilters,
	isLoading,
	selectedLaboratory,
	laboratorySalesGraph,
	laboratoryUnitsGraph,
	productSalesGraph,
	productUnitsGraph,
	regionSalesGraph,
	selectedDistributor,
	selectedProvince,
	rangeDate,
	selectOrdersInfo,
	ordersInfoSelector,
	selectDistributorsInfo,
	distributorsInfoSelector,
	optionsPie2,
	timeVSSalesGraph,
	timeVSUnitsGraph,
	timeVSOrdersGraph,
	selectVSInfo,
	VSInfoSelector,
	typeOfSales,
	selectedTypeOfSale,
	handleTypeOfSale,
	productGraphSelector,
	selectProductGraph,
	laboratoryGraphSelector,
	selectLaboratoryGraph,
}) {
	return (
		<Dashboard1Container>
			<FilterContainer>
				<Container>
					<Typo type="primary" level={6}>
						Fecha (desde/hasta)
					</Typo>
					{/* <DatePicker.RangePicker onChange={(e) => handleDateChange(e)} /> */}
					{rangeDate ? (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							value={rangeDate}
							onChange={(e) => handleDateChange(e)}
						/>
					) : (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							onChange={(e) => handleDateChange(e)}
						/>
					)}
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Droguería
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Droguería"
						optionFilterProp="children"
						value={selectedDistributor}
						// defaultValue={selectedDistributor ?? null}
						onChange={(e, o) => selectDistributor(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={distributors}
						style={{ width: 180 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Provincia
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Provincia"
						optionFilterProp="children"
						value={selectedProvince}
						// defaultValue={selectedProvince ?? null}
						onChange={(e, o) => selectProvince(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={provinces}
						style={{ width: 220 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Laboratorio
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Laboratorio"
						optionFilterProp="children"
						value={selectedLaboratory}
						// defaultValue={selectedLaboratory ?? null}
						onChange={(e, o) => selectLaboratory(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={laboratories}
						style={{ width: 180 }}
					/>
				</Container>
				{/* <Container>
					<Typo type="primary" level={6}>
						Tipo de venta
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Tipo de venta"
						optionFilterProp="children"
						value={selectedTypeOfSale}
						// defaultValue={selectedLaboratory ?? null}
						onChange={(e, o) => handleTypeOfSale(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={typeOfSales}
						style={{ width: 180 }}
					/>
				</Container> */}
				<Container style={{ justifyContent: "flex-end", width: "160px" }}>
					<Button type="primary" onClick={deleteFilters}>
						Borrar filtros
					</Button>
				</Container>
			</FilterContainer>
			<HeaderContainer>
				<Box
					dataPrefix={"$"}
					data={numberWithDotAndCommas(dashboard?.totalYearSales?.toFixed(0))}
					monthName={CONSTANTS.CALENDAR_MONTHS[dashboard?.actualMonth]}
					graphData={timeSalesGraph}
					isLoading={isLoading}
					type="headerBox"
					prefix={<BiBuildings />}
					title={
						<Typo texto="light" type="primary">
							Total ventas
						</Typo>
					}
				/>
				<Box
					data={dashboard?.totalOrderPharmacies}
					subData={
						dashboard?.actualMonthOrderPharmacies > 0
							? dashboard?.actualMonthOrderPharmacies
							: null
					}
					monthName={CONSTANTS.CALENDAR_MONTHS[dashboard?.actualMonth]}
					graphData={timePharmacyOrdersGraph}
					isLoading={isLoading}
					type="headerBox"
					prefix={<TbActivityHeartbeat />}
					title={
						<Typo texto="light" type="primary">
							Farmacias
						</Typo>
					}
				/>
				<Box
					data={
						ordersInfoSelector === "SALES"
							? dashboard?.totalOrders
							: dashboard?.totalYearUnits
					}
					subData={
						ordersInfoSelector === "SALES"
							? dashboard?.actualMonthOrders > 0
								? dashboard?.actualMonthOrders
								: null
							: dashboard?.unitsMonthInfo > 0
							? dashboard?.unitsMonthInfo
							: null
					}
					monthName={CONSTANTS.CALENDAR_MONTHS[dashboard?.actualMonth]}
					graphData={timeOrderGraph}
					secondGraphData={timeUnitsGraph}
					isLoading={isLoading}
					type="headerBoxSales"
					prefix={<BiBox />}
					selectOrdersInfo={selectOrdersInfo}
					ordersInfo={ordersInfoSelector}
					iconOption={<BiBox size={20} />}
					iconOption2={<BiCoinStack size={20} />}
					title={
						<Typo texto="light" type="primary">
							{ordersInfoSelector === "SALES"
								? "Total pedidos"
								: ordersInfoSelector === "UNITS"
								? "Total unidades"
								: ""}
						</Typo>
					}
				/>
				{/* {!selectedTypeOfSale && (
					<Box
						monthName={CONSTANTS.CALENDAR_MONTHS[dashboard?.actualMonth]}
						graphData={timeVSSalesGraph}
						secondGraphData={timeVSUnitsGraph}
						thirdGraphData={timeVSOrdersGraph}
						isLoading={isLoading}
						type="headerBoxVSSales"
						prefix={<BiBox />}
						selectOrdersInfo={selectVSInfo}
						ordersInfo={VSInfoSelector}
						iconOption={<BiDollar size={20} />}
						iconOption2={<BiCoinStack size={20} />}
						iconOption3={<BiBox size={20} />}
						title={
							<Typo texto="light" type="primary">
								{VSInfoSelector === "SALES"
									? "Ventas VS Mobile"
									: VSInfoSelector === "UNITS"
									? "Unidades VS Mobile"
									: VSInfoSelector === "ORDERS"
									? "Ordenes VS Mobile"
									: ""}
							</Typo>
						}
					/>
				)} */}
			</HeaderContainer>
			<DoubleChartContainer>
				{/* <Box
					type="timeChart"
					options={distributorSalesGraph}
					isLoading={isLoading}
					title={
						<Typo texto="light" type="primary">
							Ventas por droguería
						</Typo>
					}
				/> */}
				<Box
					type="timeChart"
					options={regionSalesGraph}
					isLoading={isLoading}
					title={
						<Typo texto="light" type="primary">
							Ventas por región
						</Typo>
					}
				/>
				<Box
					type="pieHeaderBoxSales"
					options={optionsPie}
					options2={optionsPie2}
					isLoading={isLoading}
					prefix={<MdSafetyDivider />}
					selectDistributorsInfo={selectDistributorsInfo}
					distributorsInfo={distributorsInfoSelector}
					iconOption={<BiDollar size={20} />}
					iconOption2={<BiBox size={20} />}
					title={
						<Typo texto="light" type="primary">
							{distributorsInfoSelector === "UNITS"
								? "Droguerías: pedidos"
								: distributorsInfoSelector === "SALES"
								? "Droguerías: ventas"
								: ""}
						</Typo>
					}
				/>
			</DoubleChartContainer>
			{/* <BigChartContainer>
				<Box
					type="timeChart"
					options={regionSalesGraph}
					isLoading={isLoading}
					title={
						<Typo texto="light" type="primary">
							Ventas por región
						</Typo>
					}
				/>
			</BigChartContainer> */}
			{!selectedLaboratory && (
				<DoubleChartContainer>
					<Box
						type="timeChartMultipleProduct"
						options={laboratorySalesGraph}
						iconOption={<BiDollar size={20} />}
						option2={laboratoryUnitsGraph}
						iconOption2={<BiCoinStack size={20} />}
						selectProductGraph={selectLaboratoryGraph}
						productGraph={laboratoryGraphSelector}
						isLoading={isLoading}
						title={
							<Typo texto="light" type="primary">
								{laboratoryGraphSelector === "SALES"
									? "Ventas por laboratorio"
									: laboratoryGraphSelector === "UNITS"
									? "Unidades por laboratorio"
									: ""}
							</Typo>
						}
					/>
					<Box
						type="timeChartMultipleProduct"
						options={productSalesGraph}
						iconOption={<BiDollar size={20} />}
						option2={productUnitsGraph}
						iconOption2={<BiCoinStack size={20} />}
						selectProductGraph={selectProductGraph}
						productGraph={productGraphSelector}
						isLoading={isLoading}
						title={
							<Typo texto="light" type="primary">
								{productGraphSelector === "SALES"
									? "Ventas por producto"
									: productGraphSelector === "UNITS"
									? "Unidades por producto"
									: ""}
							</Typo>
						}
					/>
				</DoubleChartContainer>
			)}
			{selectedLaboratory && (
				<BigChartContainer>
					<Box
						type="timeChartMultipleProduct"
						options={productSalesGraph}
						iconOption={<BiDollar size={20} />}
						option2={productUnitsGraph}
						iconOption2={<BiCoinStack size={20} />}
						selectProductGraph={selectProductGraph}
						productGraph={productGraphSelector}
						isLoading={isLoading}
						title={
							<Typo texto="light" type="primary">
								{productGraphSelector === "SALES"
									? "Ventas por producto"
									: productGraphSelector === "UNITS"
									? "Unidades por producto"
									: ""}
							</Typo>
						}
					/>
				</BigChartContainer>
			)}
		</Dashboard1Container>
	);
}

import React, { useState } from "react";
import Layout from "../../../../layout/base/Layout";
import Image from "../../../../common/image/Image";
import { Col, Select } from "antd";
import { Link, useNavigate } from "react-router-dom";
import MarketLogo from "../../../../../assets/images/logo.png";
import MenuIcon from "../../../../../assets/images/bars-solid-2.svg";
import { useFilter } from "../../../../../contexts/filterContext";
import { Typo } from "../../../../layout/theme";

export default function MarketHeader({
	scrollDirection,
	userdata,
	location,
	templateProducts,
	qty,
	setShowDrawer,
	showDrawer,
	browserInfo,
	laboratoriesForHeader,
}) {
	const { setSudoLabUser, setSudoLabClient, sudoLabClient } = useFilter();
	const navigate = useNavigate();

	const handleSelectLaboratory = (e) => {
		try {
			if (e === "ALL") {
				setSudoLabUser(null);
				setSudoLabClient(null);
			} else {
				const selectedLaboratory = laboratoriesForHeader.filter(
					(c) => c.clientId === e
				)[0];
				setSudoLabUser({
					clientId: selectedLaboratory?.clientId,
					firstName: selectedLaboratory?.firstName,
					lastName: selectedLaboratory?.lastName,
					p_client: selectedLaboratory?.p_client,
					p_dashboard: selectedLaboratory?.p_dashboard,
					p_mobile: selectedLaboratory?.p_mobile,
					p_products: selectedLaboratory?.p_products,
					p_sales: selectedLaboratory?.p_sales,
					p_shop: selectedLaboratory?.p_shop,
					p_users: selectedLaboratory?.p_users,
					isSeller: selectedLaboratory?.isSeller,
				});
				setSudoLabClient({
					client: {
						id: selectedLaboratory?.clientId,
						name: selectedLaboratory?.clientName,
						slug: selectedLaboratory?.clientSlug,
						photoUrl: selectedLaboratory?.clientPhotoUrl,
						contract: {
							activeMobileApp: selectedLaboratory?.activeMobileApp,
							activeWebSeller: selectedLaboratory?.activeWebSeller,
							activeExpoSection: selectedLaboratory?.activeExpoSection,
							activeDiscountsForUsers:
								selectedLaboratory?.activeDiscountsForUsers,
							mobileUsersZoneType: selectedLaboratory?.mobileUsersZoneType,
						},
					},
				});
			}
		} catch {
		} finally {
			navigate("/");
		}
	};

	return (
		<Layout.LayoutHeader
			justify="space-evenly"
			align="middle"
			scroll={scrollDirection}
		>
			<Col md={3} lg={3} xl={3}>
				<Link to="/">
					<Image
						src={sudoLabClient ? sudoLabClient.client.photoUrl : MarketLogo}
						alt="Logo Market"
						height={45}
					/>
				</Link>
			</Col>

			<Col md={4} lg={4} xl={4}>
				<Select
					onChange={(e) => handleSelectLaboratory(e)}
					defaultValue={"ALL"}
					style={{ width: 220 }}
				>
					<Select.Option value={"ALL"} key={`client_checkpos_transfer`}>
						<Image src={MarketLogo} alt="Logo Market" height={15} />{" "}
						<Typo level={7}>Checkpos Transfer</Typo>
					</Select.Option>
					{laboratoriesForHeader?.map((i, idx) => (
						<Select.Option
							value={i.clientId}
							key={`client_${idx}_${i.clientId}`}
						>
							<Image src={i.clientPhotoUrl} alt="Logo Lab" height={15} />{" "}
							<Typo level={7}>{i.clientName}</Typo>
						</Select.Option>
					))}
				</Select>
			</Col>

			<Col md={2} lg={2} xl={2}>
				<Layout.HeaderAction
					onClick={() => setShowDrawer(!showDrawer)}
					style={{
						width: "100px",
					}}
				>
					<Layout.HeaderMenuIcon>
						<Layout.HeaderMenuIconContainer firefox={browserInfo.firefox}>
							<Image
								src={MenuIcon}
								alt="Menu"
								style={{
									height: "50%",
									width: "50%",
									marginLeft: "10px",
								}}
							/>
							<Layout.HeaderMenuParagraph>Menú</Layout.HeaderMenuParagraph>
						</Layout.HeaderMenuIconContainer>
					</Layout.HeaderMenuIcon>
				</Layout.HeaderAction>
			</Col>
		</Layout.LayoutHeader>
	);
}

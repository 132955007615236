import axiosInstance from "../axiosInstance";

const CONTROLLER = "dashboards";

const get_sales_dashboard = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetSales`);
	return response.data;
};

const get_buys_dashboard = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetBuys`);
	return response.data;
};

const get_one_market_main = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetOneMarketMain`);
	return response.data;
};

const get_general_dashboard = async (
	distributorId,
	provinceName,
	dateFrom,
	dateTo
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetGeneralDashboardCT`,
		{
			params: {
				distributorId: distributorId,
				provinceName: provinceName,
				dateFrom: dateFrom,
				dateTo: dateTo,
			},
		}
	);
	return response.data;
};

const get_dinamic_sales_dashboard = async (
	distributorId,
	clientId,
	provinceName,
	dateFrom,
	dateTo,
	categoryId,
	pointOfSaleId,
	localityName,
	chainId,
	sellerId
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetSalesDashboardCT`,
		{
			params: {
				distributorId: distributorId,
				clientId: clientId,
				provinceName: provinceName,
				dateFrom: dateFrom,
				dateTo: dateTo,
				categoryId: categoryId,
				pointOfSaleId: pointOfSaleId,
				localityName: localityName,
				chainId: chainId,
				sellerId: sellerId,
			},
		}
	);
	return response.data;
};

const get_dinamic_mobile_dashboard = async (
	distributorId,
	clientId,
	userId,
	provinceName,
	dateFrom,
	dateTo,
	categoryId
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetMobileDashboardCT`,
		{
			params: {
				distributorId: distributorId,
				clientId: clientId,
				userId: userId,
				provinceName: provinceName,
				dateFrom: dateFrom,
				dateTo: dateTo,
				categoryId: categoryId,
			},
		}
	);
	return response.data;
};

const get_pharmacy_general_dashboard = async (
	distributorId,
	clientId,
	pharmacyId,
	pointOfSaleId,
	provinceName,
	dateFrom,
	dateTo
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPharmacyGeneralDashboardCT`,
		{
			params: {
				distributorId: distributorId,
				clientId: clientId,
				pharmacyId: pharmacyId,
				pointOfSaleId: pointOfSaleId,
				provinceName: provinceName,
				dateFrom: dateFrom,
				dateTo: dateTo,
			},
		}
	);
	return response.data;
};

const get_region_dashboard = async (
	distributorId,
	clientId,
	provinceName,
	dateFrom,
	dateTo
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetRegionDashboardCT`,
		{
			params: {
				distributorId: distributorId,
				clientId: clientId,
				provinceName: provinceName,
				dateFrom: dateFrom,
				dateTo: dateTo,
			},
		}
	);
	return response.data;
};

const get_distributor_dashboard = async (
	distributorId,
	clientId,
	provinceName,
	dateFrom,
	dateTo
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetDistributorDashboardCT`,
		{
			params: {
				distributorId: distributorId,
				clientId: clientId,
				provinceName: provinceName,
				dateFrom: dateFrom,
				dateTo: dateTo,
			},
		}
	);
	return response.data;
};

const get_pharmacy_laboratory_dashboard = async (
	distributorId,
	clientId,
	pharmacyId,
	pointOfSaleId,
	provinceName,
	localityName,
	dateFrom,
	dateTo,
	chainId,
	sellerId,
	categoryId
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPharmacyLaboratoryDashboardCT`,
		{
			params: {
				distributorId: distributorId,
				clientId: clientId,
				pharmacyId: pharmacyId,
				pointOfSaleId: pointOfSaleId,
				provinceName: provinceName,
				localityName: localityName,
				dateFrom: dateFrom,
				dateTo: dateTo,
				chainId: chainId,
				sellerId: sellerId,
				categoryId: categoryId,
			},
		}
	);
	return response.data;
};

const get_laboratory_dashboard = async (
	distributorId,
	clientId,
	provinceName,
	dateFrom,
	dateTo
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetLaboratoryDashboardCT`,
		{
			params: {
				distributorId: distributorId,
				clientId: clientId,
				provinceName: provinceName,
				dateFrom: dateFrom,
				dateTo: dateTo,
			},
		}
	);
	return response.data;
};

const export_dashboard_pharmacies = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/ExportDashboardPharmacy`,
		data,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

const get_buys_table_conversion_dashboard = async (typeOfSale) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetBuysTableConversionDashboardCT`,
		{
			params: {
				typeOfSale: typeOfSale,
			},
		}
	);
	return response.data;
};

const export_dashboard_pos_table_conversion = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/ExportDashboardPosConversionTable`,
		data,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

const get_products_laboratory_dashboard = async (
	distributorId,
	clientId,
	pharmacyId,
	pointOfSaleId,
	provinceName,
	localityName,
	dateFrom,
	dateTo,
	brandId,
	categoryId,
	chainId,
	sellerId
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetProductLaboratoryDashboardCT`,
		{
			params: {
				distributorId: distributorId,
				clientId: clientId,
				pharmacyId: pharmacyId,
				pointOfSaleId: pointOfSaleId,
				provinceName: provinceName,
				localityName: localityName,
				dateFrom: dateFrom,
				dateTo: dateTo,
				brandId: brandId,
				categoryId: categoryId,
				chainId: chainId,
				sellerId: sellerId,
			},
		}
	);
	return response.data;
};

const export_dashboard_products = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/ExportDashboardProduct`,
		data,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

const get_initiatives_dashboard = async (
	distributorId,
	clientId,
	pointOfSaleId,
	provinceName,
	localityName,
	dateFrom,
	dateTo,
	categoryId,
	chainId,
	sellerId,
	initiativeId
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetInitiativesDashboardCT`,
		{
			params: {
				distributorId: distributorId,
				clientId: clientId,
				pointOfSaleId: pointOfSaleId,
				provinceName: provinceName,
				localityName: localityName,
				dateFrom: dateFrom,
				dateTo: dateTo,
				categoryId: categoryId,
				chainId: chainId,
				sellerId: sellerId,
				initiativeId: initiativeId,
			},
		}
	);
	return response.data;
};

const get_chain_laboratory_dashboard = async (
	distributorId,
	clientId,
	pharmacyId,
	pointOfSaleId,
	provinceName,
	localityName,
	dateFrom,
	dateTo,
	chainId,
	sellerId,
	categoryId
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetChainLaboratoryDashboardCT`,
		{
			params: {
				distributorId: distributorId,
				clientId: clientId,
				pharmacyId: pharmacyId,
				pointOfSaleId: pointOfSaleId,
				provinceName: provinceName,
				localityName: localityName,
				dateFrom: dateFrom,
				dateTo: dateTo,
				chainId: chainId,
				sellerId: sellerId,
				categoryId: categoryId,
			},
		}
	);
	return response.data;
};

export {
	get_sales_dashboard,
	get_buys_dashboard,
	get_one_market_main,
	get_general_dashboard,
	get_dinamic_sales_dashboard,
	get_dinamic_mobile_dashboard,
	get_pharmacy_general_dashboard,
	get_region_dashboard,
	get_distributor_dashboard,
	get_pharmacy_laboratory_dashboard,
	get_laboratory_dashboard,
	export_dashboard_pharmacies,
	get_buys_table_conversion_dashboard,
	export_dashboard_pos_table_conversion,
	get_products_laboratory_dashboard,
	export_dashboard_products,
	get_initiatives_dashboard,
	get_chain_laboratory_dashboard,
};

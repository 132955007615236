import React, { useState, useEffect } from "react";
import {
	useParams,
	Link,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { Form, Input, Select, Row, Col, message, Modal, Button } from "antd";
import {
	InnerForm,
	ProductData,
	PublishSettings,
	InfoContainer,
	RightContainer,
	Container,
	TopContainer,
	AntdButton,
	ConfigContainer,
} from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Main from "../../../../layout/main/Main";
import COLORS from "../../../../layout/theme/colors/Colors";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import {
	get_distributor_by_id,
	update_distributor,
	update_distributor_config,
} from "../../../../../api/endpoints/distributors";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import { Typo } from "../../../../layout/theme";
import UploadFileImage from "../../../../common/upload-file-with-image/UploadFileImage";
import {
	CheckOutlined,
	CloseCircleOutlined,
	DeleteOutlined,
} from "@ant-design/icons";
import { CONSTANTS } from "../../../../../utils/constants";
import {
	delete_distributor_email,
	get_emails_by_distributorId,
	update_distributor_email,
} from "../../../../../api/endpoints/emails";
import { get_mobile_laboratories_list } from "../../../../../api/endpoints/clients";
import ConfigSection from "./components/config-section/ConfigSection";
import { useFilter } from "../../../../../contexts/filterContext";

const { Option } = Select;
const GUTTER = 10;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

const states = [
	{
		id: "ACTIVE",
		name: "Activo",
	},
	{
		id: "DELETED",
		name: "Inactivo",
	},
];

export default function UpdateDistributor() {
	const { id } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");

	const [submitting, setSubmitting] = useState(false);
	const [imageUrl, setImageUrl] = useState();
	const [initialValues, setInitialValues] = useState(null);
	const [state, setState] = useState();
	const [key, setKey] = useState(null);
	const [mailID, setMailID] = useState(null);
	const [show, setShow] = useState(false);
	const [newEmail, setNewEmail] = useState(null);
	const [availableTypesOfFiles, setAvailableTypesOfFiles] = useState(null);
	const [availableSendTypes, setAvailableSendTypes] = useState(null);
	const [copyEmails, setCopyEmails] = useState(null);
	const [flag, setFlag] = useState(false);
	const [selectedLaboratory, setSelectedLaboratory] = useState(null);
	const [laboratories, setLaboratories] = useState(null);
	const [selectedColumnsMobile, setSelectedColumnsMobile] = useState(null);
	const [availableColumnsMobile, setAvailableColumnsMobile] = useState(null);
	const [updated, setUpdated] = useState(false);

	const columns = [
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Nombre de Contacto</div>
			),
			dataIndex: "name",
		},
		{
			title: () => <div style={{ color: COLORS.White }}>@Mail</div>,
			dataIndex: "mail",
			render: (value, r) => (
				<div
					onClick={() => handleModifyMail(r.id)}
					onMouseOver={() => setKey(r)}
					style={{ cursor: "pointer" }}
				>
					{mailID === r.id && show ? (
						<Container onMouseLeave={() => setShow(false)}>
							<Input
								type="mail"
								style={{}}
								min={0}
								max={4}
								step={1}
								defaultValue={value}
								onChange={(e) => setNewEmail(e.target.value)}
							/>
							<Button
								onClick={() => handleEmailSave(r.id, newEmail, r.name)}
								icon={<CheckOutlined />}
							/>
							<Button
								onClick={() => handleEmailDelete(r.id)}
								icon={<DeleteOutlined style={{ color: COLORS.Danger }} />}
							/>
						</Container>
					) : (
						<Typo type="muted" fontWeight={600} fontSize={14}>
							{value}
						</Typo>
					)}
				</div>
			),
		},
	];

	const handleModifyMail = (id) => {
		setMailID(id);
		setShow(true);
	};

	const handleEmailSave = async (id, mail, name) => {
		const updateData = { name: name, mail: mail, id: id };
		await update_distributor_email(key.id, updateData);
		setFlag((prev) => !prev);
		setShow(false);
	};

	const handleEmailDelete = async (id) => {
		await delete_distributor_email(id);
		setFlag((prev) => !prev);
		setShow(false);
	};

	const fetch_distributor_by_id = async () => {
		const data = await get_distributor_by_id(id);
		const formData = {
			title: data.name,
			cuit: data.cuit,
			glnAnmat: data.glnAnmat,
			state: data.isActive ? "ACTIVE" : "DELETED",
			file: data.photoUrl,
			mails: data.mails,
			email: data.email,
			contactName: data.contactName,
			send: JSON.parse(data.typeOfArchive)?.send ?? "",
			format: JSON.parse(data.typeOfArchive)?.format ?? "",
			server: JSON.parse(data.typeOfArchive)?.server ?? "",
			port: JSON.parse(data.typeOfArchive)?.port ?? "",
			user: JSON.parse(data.typeOfArchive)?.user ?? "",
			password: JSON.parse(data.typeOfArchive)?.password ?? "",
			folder: JSON.parse(data.typeOfArchive)?.folder ?? "",
			columnsToSend: data.columnsToSend
				? data.columnsToSend.split(";").map((colum) => ({
						value: colum,
						label: CONSTANTS.DISTRIBUTORS_COLUMNS.find(
							(col) => col.value === colum
						)?.label,
				  }))
				: [],
			mobileEmail: data.mobileEmail,
			sendMobile: JSON.parse(data.mobileTypeOfArchive)?.send ?? "",
			formatMobile: JSON.parse(data.mobileTypeOfArchive)?.format ?? "",
			serverMobile: JSON.parse(data.mobileTypeOfArchive)?.server ?? "",
			portMobile: JSON.parse(data.mobileTypeOfArchive)?.port ?? "",
			userMobile: JSON.parse(data.mobileTypeOfArchive)?.user ?? "",
			passwordMobile: JSON.parse(data.mobileTypeOfArchive)?.password ?? "",
			folderMobile: JSON.parse(data.mobileTypeOfArchive)?.folder ?? "",
			mobileColumnsToSend: data.mobileColumnsToSend
				? data.mobileColumnsToSend.split(";").map((colum) => ({
						value: colum,
						label: CONSTANTS.DISTRIBUTORS_COLUMNS.find(
							(col) => col.value === colum
						)?.label,
				  }))
				: [],
		};

		setImageUrl(data.photoUrl);

		setAvailableTypesOfFiles(CONSTANTS.TYPE_OF_FILE);

		setAvailableSendTypes(CONSTANTS.TYPE_OF_SEND);

		setSelectedLaboratory(data.assignedLab ?? null);

		setSelectedColumnsMobile(
			data.mobileColumnsToSend
				? data.mobileColumnsToSend.split(";").map((colum) => ({
						value: colum,
						label: CONSTANTS.DISTRIBUTORS_COLUMNS.find(
							(col) => col.value === colum
						)?.label,
				  }))
				: new Array(CONSTANTS.DISTRIBUTORS_COLUMNS.length).fill("")
		);

		setAvailableColumnsMobile(
			data.mobileColumnsToSend ? [] : CONSTANTS.DISTRIBUTORS_COLUMNS
		);

		setInitialValues(formData);
	};

	const fetch_laboratories_mobile = async () => {
		const res = await get_mobile_laboratories_list();
		setLaboratories(
			res?.map((c) => ({
				label: c.name,
				value: c.id,
			}))
		);
	};

	useEffect(() => {
		if (id) {
			fetch_distributor_by_id();
			fetch_laboratories_mobile();
		}
	}, [updated]);

	const fetch_copy_emails = async () => {
		const res = await get_emails_by_distributorId(id);
		setCopyEmails(res?.copyEmails);
	};

	useEffect(() => {
		if (id) {
			fetch_copy_emails();
		}
	}, [flag]);

	const onFinish = async (values) => {
		try {
			if (!selectedColumnsMobile?.some((column) => column.value !== "")) {
				openNotificationWithIcon(
					TYPE.ERROR,
					"Debe existir una estructura de archivo mobile para poder activar la Droguería"
				);
				setSubmitting(false);
				return;
			}

			setSubmitting(true);

			const nonEmptyColumnsMobile = selectedColumnsMobile.filter(
				(column) => column.value !== ""
			);
			const columnsToSendMobile = nonEmptyColumnsMobile
				.map((colum) => colum.value)
				.join(";");

			const sendInfoMobile = {
				send: values.sendMobile,
				format: values.formatMobile,
				server: values.serverMobile ?? "",
				port: values.portMobile ?? "",
				user: values.userMobile ?? "",
				password: values.passwordMobile ?? "",
				folder: values.folderMobile ?? "",
			};

			const postData = {
				id: id,
				state: values.state,
				title: values.title,
				cuit: values.cuit,
				contactName: values.contactName,
				glnAnmat: values.glnAnmat,
				assignedLab: selectedLaboratory,
			};

			const configDataMobile = {
				id: id,
				mobileEmail: values.mobileEmail,
				mobileColumnsToSend: columnsToSendMobile,
				mobileTypeOfArchive: JSON.stringify(sendInfoMobile),
			};

			const distributorFormData = new FormData();

			distributorFormData.append(
				"file",
				values.file?.fileList?.slice(-1)[0]?.originFileObj
			);

			distributorFormData.append("data", JSON.stringify(postData));

			await update_distributor(id, distributorFormData);

			await update_distributor_config(id, configDataMobile);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Droguería editada con éxito",
				"La droguería se editó con éxito"
			);
			setUpdated((prev) => !prev);
			// navigate(`/admin/distributors?uri=${uri}`);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al editar la droguería",
				"Un error ocurrió al intentar editar la droguería, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	if (!id) navigate("/admin/distributors?uri=" + uri);

	if (!initialValues) return <LoadingSkeleton />;

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/distributors?uri=" + uri);
			},
		});
	};

	const handleColumnChange = (
		index,
		value,
		obj,
		state1,
		state2,
		selected,
		available
	) => {
		const updatedSelectedColumns = [...selected];
		updatedSelectedColumns[index] = { value: value, label: obj.children };

		state1(updatedSelectedColumns);

		const updatedAvailableColumns = available.filter(
			(column) => column.value !== value
		);

		state2(updatedAvailableColumns);
	};

	const handleLaboratorySelect = (value) => {
		setSelectedLaboratory(value);
	};

	const handleResetMobile = () => {
		const initialValues = new Array(CONSTANTS.DISTRIBUTORS_COLUMNS.length).fill(
			{ value: "", label: "" }
		);
		setSelectedColumnsMobile(initialValues);

		setAvailableColumnsMobile(CONSTANTS.DISTRIBUTORS_COLUMNS);

		// form.resetFields();
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to={"/admin/distributors?uri=" + uri}>Droguerías</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Editar droguería
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Form
					form={form}
					name="basic"
					onFinish={onFinish}
					autoComplete="off"
					requiredMark={false}
					layout="vertical"
					initialValues={initialValues}
				>
					<InnerForm>
						<TopContainer>
							<ProductData>
								<InfoContainer>
									<Row gutter={GUTTER}>
										<Col span={8}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Nombre
													</label>
												}
												name="title"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
													{
														pattern: new RegExp(
															/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
														),
														message:
															"No se aceptan caracteres especiales. Solo letras y numeros",
													},
												]}
											>
												<Input placeholder="Nombre" />
											</Form.Item>
										</Col>
										<Col span={8}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Cuit
													</label>
												}
												name="cuit"
											>
												<Input placeholder="Cuit" />
											</Form.Item>
										</Col>
										<Col span={8}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														GLN
													</label>
												}
												name="glnAnmat"
											>
												<Input placeholder="GLN" />
											</Form.Item>
										</Col>
									</Row>

									<Row gutter={GUTTER}>
										<Col span={8}>
											<Form.Item
												name="file"
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Logo de la droguería
													</label>
												}
											>
												<UploadFileImage
													onChange={handleImageChange}
													urlImage={imageUrl}
													imageHeight="100%"
													imageWidth="100%"
												/>
											</Form.Item>
										</Col>
										<Col span={16}>
											<Row gutter={GUTTER}>
												<Col span={12}>
													<Form.Item
														label={
															<label
																style={{
																	color: COLORS.Primary,
																	fontWeight: "600",
																}}
															>
																Contacto
															</label>
														}
														name="contactName"
														rules={[
															{
																required: true,
																message: "Este campo es obligatorio",
															},
															{
																pattern: new RegExp(
																	/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/
																),
																message:
																	"No se aceptan caracteres especiales. Solo letras y numeros",
															},
														]}
													>
														<Input placeholder="Nombre" />
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item
														label={
															<label
																style={{
																	color: COLORS.Primary,
																	fontWeight: "600",
																}}
															>
																Cliente asociado
															</label>
														}
														rules={[]}
													>
														<Select
															showSearch
															allowClear={true}
															optionFilterProp="children"
															placeholder="Seleccione un laboratorio"
															onChange={handleLaboratorySelect}
															value={selectedLaboratory}
															filterOption={(input, option) =>
																(option?.label ?? "")
																	.toLowerCase()
																	.includes(input.toLowerCase())
															}
															options={laboratories}
															style={{ width: 226 }}
														/>
													</Form.Item>
												</Col>
											</Row>
										</Col>
										<Col span={12}></Col>
									</Row>
								</InfoContainer>
							</ProductData>
							<RightContainer>
								<PublishSettings>
									<Form.Item
										label={
											<label
												style={{ color: COLORS.Primary, fontWeight: "600" }}
											>
												Estado de la droguería
											</label>
										}
										name="state"
										rules={[
											{
												required: true,
												message: "Este campo es obligatorio",
											},
										]}
									>
										<Select
											allowClear={true}
											placeholder="Estado de la droguería"
										>
											{states.map((item) => (
												<Option key={item.id} value={item.id}>
													{item.name}
												</Option>
											))}
										</Select>
									</Form.Item>

									<Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											color="white"
											bg="Primary"
											htmlType="submit"
											loading={submitting}
											block
										>
											Guardar Cambios
										</SignInTemplate.AntdButton>
									</Form.Item>
									<SignInTemplate.AntdButton
										type="Primary"
										loading={submitting}
										onClick={handleBack}
										block
									>
										<Typo type="danger" level={6}>
											Volver sin guardar
										</Typo>
									</SignInTemplate.AntdButton>
								</PublishSettings>
							</RightContainer>
						</TopContainer>
						<ConfigContainer>
							<ConfigSection
								availableSendTypes={availableSendTypes}
								availableTypesOfFiles={availableTypesOfFiles}
								state={state}
								setState={setState}
								copyEmails={copyEmails}
								columns={columns}
								id={id}
								setFlag={setFlag}
								handleReset={handleResetMobile}
								selectedColumns={selectedColumnsMobile}
								handleColumnChange={handleColumnChange}
								setSelectedColumns={setSelectedColumnsMobile}
								setAvailableColumns={setAvailableColumnsMobile}
								availableColumns={availableColumnsMobile}
								configType={CONSTANTS.CONFIG_MOBILE}
							/>
						</ConfigContainer>
					</InnerForm>
				</Form>
			</Main.Body>
		</Main>
	);
}

import React, { useState, useEffect } from "react";
import { COLORS, Typo } from "../../../layout/theme";
import { DrawerContainer, FilterContainerButton, Inner } from "./styles";
import {
	export_dashboard_products,
	get_chain_laboratory_dashboard,
	get_dinamic_mobile_dashboard,
	get_dinamic_sales_dashboard,
	get_initiatives_dashboard,
	get_pharmacy_laboratory_dashboard,
	get_products_laboratory_dashboard,
} from "../../../../api/endpoints/dashboard";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/authContext";
import {
	BarChartOutlined,
	MobileOutlined,
	CloseCircleOutlined,
	ClusterOutlined,
} from "@ant-design/icons";
import { numberWithDotAndCommas } from "../../../../utils/numberWithDotAndComas";
import { CONSTANTS } from "../../../../utils/constants";
import moment from "moment";
import { Layout, Menu, Tooltip } from "antd";
import SaleDashboard from "./components/dashboards/SaleDashboard";
import MobileDashboard from "./components/dashboards/MobileDashboard";
import { useFilter } from "../../../../contexts/filterContext";
import { FaBoxes, FaInfo } from "react-icons/fa";
import { numberToMillions } from "../../../../utils/numberToMillions";
import { TiPlusOutline } from "react-icons/ti";
import PharmacyDashboard from "./components/dashboards/PharmacyDashboard";
import ProductDashboard from "./components/dashboards/ProductDashboard";
import InitiativeDashboard from "./components/dashboards/InitiativeDashboard";
import { BiTrendingUp } from "react-icons/bi";
import ChainDashboard from "./components/dashboards/ChainDashboard";
const { Sider } = Layout;

export default function DashboardSeller({ clientId, clientUser }) {
	const { user, userdata } = useAuth();
	const {
		selectedDistributor,
		setSelectedDistributor,
		selectedLaboratory,
		setSelectedLaboratory,
		selectedPharmacy,
		setSelectedPharmacy,
		selectedPointOfSale,
		setSelectedPointOfSale,
		selectedProvince,
		setSelectedProvince,
		rangeDate,
		setRangeDate,
		dateFrom,
		setDateFrom,
		dateTo,
		setDateTo,
		mobilePeriod,
		setMobilePeriod,
		selectedFilters,
		setSelectedFilters,
		selectedTypeOfSale,
		setSelectedTypeOfSale,
		selectedSellerList,
		setSelectedSellerList,
		sellerDashboardDisplay,
		setSellerDashboardDisplay,
		selectedCategory,
		setSelectedCategory,
		selectedLocality,
		setSelectedLocality,
		selectedChain,
		setSelectedChain,
		selectedBrand,
		setSelectedBrand,
		selectedInitiative,
		setSelectedInitiative,
	} = useFilter();
	const [collapsed, setCollapsed] = useState(true);
	const [openPanel, setOpenPanel] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [distributorsD2, setDistributorsD2] = useState(null);
	const [provincesD2, setProvincesD2] = useState(null);
	const [localitiesD2, setLocalitiesD2] = useState(null);
	const [categoriesD2, setCategoriesD2] = useState(null);
	const [chainD2, setChainD2] = useState(null);
	const [usersD2, setUsersD2] = useState(null);
	const [pointsOfSaleD2, setPointsOfSaleD2] = useState(null);
	const [generalInfoD2, setGeneralInfoD2] = useState(null);
	const [pieDataD2, setPieDataD2] = useState(null);
	const [timeSalesGraphD2, setTimeSalesGraphD2] = useState(null);
	const [timePharmacyOrdersGraphD2, setTimePharmacyOrdersGraphD2] =
		useState(null);
	const [timeOrdersGraphD2, setTimeOrdersGraphD2] = useState(null);
	const [timeUnitsGraphD2, setTimeUnitsGraphD2] = useState(null);
	const [productSalesGraphD2, setProductSalesGraphD2] = useState(null);
	const [productUnitsGraphD2, setProductUnitsGraphD2] = useState(null);
	const [productGraphSelectorD2, setProductGraphSelectorD2] = useState("SALES");
	const [distributorSalesGraphD2, setDistributorSalesGraphD2] = useState(null);
	// const [VSInfoSelector, setVSInfoSelector] = useState("SALES");
	// const [timeVSSalesGraphD2, setTimeVSSalesGraphD2] = useState(null);
	// const [timeVSUnitsGraphD2, setTimeVSUnitsGraphD2] = useState(null);
	// const [timeVSOrdersGraphD2, setTimeVSOrdersGraphD2] = useState(null);
	const [categorySalesGraphD2, setCategorySalesGraphD2] = useState(null);
	const [categoryOrdersGraphD2, setCategoryOrdersGraphD2] = useState(null);
	const [categoryUnitsGraphD2, setCategoryUnitsGraphD2] = useState(null);
	const [categoryGraphSelectorD2, setCategoryGraphSelectorD2] =
		useState("SALES");
	const [categoryGraphSelector2D2, setCategoryGraphSelector2D2] =
		useState("SALES");
	const [secondPieDataD2, setSecondPieDataD2] = useState(null);
	const [thirdPieDataD2, setThirdPieDataD2] = useState(null);
	const [regionSalesGraphD2, setRegionSalesGraphD2] = useState(null);
	const [localitySalesGraphD2, setLocalitySalesGraphD2] = useState(null);
	const [timeCategoriesSalesGraphD2, setTimeCategoriesSalesGraphD2] =
		useState(null);
	const [timeCategoriesUnitsGraphD2, setTimeCategoriesUnitsGraphD2] =
		useState(null);
	const [timeHistoricSalesGraphD2, setTimeHistoricSalesGraphD2] =
		useState(null);
	const [salesInfoSelectorD2, setSalesInfoSelectorD2] = useState("SALES");

	const [distributorsD3, setDistributorsD3] = useState(null);
	const [provincesD3, setProvincesD3] = useState(null);
	const [laboratoriesD3, setLaboratoriesD3] = useState(null);
	const [usersD3, setUsersD3] = useState(null);
	const [dateFromD3, setDateFromD3] = useState(
		moment().startOf("month").format("MM-DD-YYYY").toString()
	);
	const [dateToD3, setDateToD3] = useState(
		moment().endOf("month").format("MM-DD-YYYY").toString()
	);
	const [rangeDateD3, setRangeDateD3] = useState([
		moment().startOf("month"),
		moment().endOf("month"),
	]);
	const [categoriesD3, setCategoriesD3] = useState(null);
	const [generalInfoD3, setGeneralInfoD3] = useState(null);
	const [pieDataD3, setPieDataD3] = useState(null);
	const [timePharmacyOrdersGraphD3, setTimePharmacyOrdersGraphD3] =
		useState(null);
	const [sellerSalesTableD3, setSellerSalesTableD3] = useState(null);
	const [timeOrdersGraphD3, setTimeOrdersGraphD3] = useState(null);
	const [distributorSalesGraphD3, setDistributorSalesGraphD3] = useState(null);
	const [distributorOrdersGraphD3, setDistributorOrdersGraphD3] =
		useState(null);
	const [distributorUnitsGraphD3, setDistributorUnitsGraphD3] = useState(null);
	const [distributorGraphSelector, setDistributorGraphSelector] =
		useState("SALES");
	const [ordersInfoSelector, setOrdersInfoSelector] = useState("UNITS");
	const [distributorsPieInfoSelector, setDistributorsPieInfoSelector] =
		useState("SALES");
	const [openFilterList, setOpenFilterList] = useState(false);
	const [distributorsD4, setDistributorsD4] = useState(null);
	const [laboratoriesD4, setLaboratoriesD4] = useState(null);
	const [pharmaciesD4, setPharmaciesD4] = useState(null);
	const [provincesD4, setProvincesD4] = useState(null);
	const [localitiesD4, setLocalitiesD4] = useState(null);
	const [chainsD4, setChainsD4] = useState(null);
	const [usersD4, setUsersD4] = useState(null);
	const [categoriesD4, setCategoriesD4] = useState(null);
	const [generalInfoD4, setGeneralInfoD4] = useState(null);
	const [pharmaciesTable, setPharmaciesTable] = useState(null);
	const [productSalesGraphD3, setProductSalesGraphD3] = useState(null);
	const [productUnitsGraphD3, setProductUnitsGraphD3] = useState(null);
	const [productGraphSelectorD3, setProductGraphSelectorD3] = useState("SALES");

	const [generalInfoD5, setGeneralInfoD5] = useState(null);
	const [distributorsD5, setDistributorsD5] = useState(null);
	const [laboratoriesD5, setLaboratoriesD5] = useState(null);
	const [pharmaciesD5, setPharmaciesD5] = useState(null);
	const [provincesD5, setProvincesD5] = useState(null);
	const [localitiesD5, setLocalitiesD5] = useState(null);
	const [categoriesD5, setCategoriesD5] = useState(null);
	const [pointsOfSaleD5, setPointsOfSaleD5] = useState(null);
	const [brandsD5, setBrandsD5] = useState(null);
	const [chainsD5, setChainsD5] = useState(null);
	const [usersD5, setUsersD5] = useState(null);
	const [productsTable, setProductsTable] = useState(null);

	const [generalInfoD6, setGeneralInfoD6] = useState(null);
	const [distributorsD6, setDistributorsD6] = useState(null);
	const [laboratoriesD6, setLaboratoriesD6] = useState(null);
	const [pharmaciesD6, setPharmaciesD6] = useState(null);
	const [provincesD6, setProvincesD6] = useState(null);
	const [localitiesD6, setLocalitiesD6] = useState(null);
	const [categoriesD6, setCategoriesD6] = useState(null);
	const [pointsOfSaleD6, setPointsOfSaleD6] = useState(null);
	const [brandsD6, setBrandsD6] = useState(null);
	const [chainsD6, setChainsD6] = useState(null);
	const [usersD6, setUsersD6] = useState(null);
	const [initiativesD6, setInitiativesD6] = useState(null);
	const [productTotalvsInitiativesD6, setProductTotalvsInitiativesD6] =
		useState(null);
	const [timeInitiativesSalesGraphD6, setTimeInitiativesSalesGraphD6] =
		useState(null);
	const [timeInitiativesUnitsGraphD6, setTimeInitiativesUnitsGraphD6] =
		useState(null);
	const [initiativeGraphSelectorD6, setInitiativeGraphSelectorD6] =
		useState("SALES");

	const [distributorsD7, setDistributorsD7] = useState(null);
	const [laboratoriesD7, setLaboratoriesD7] = useState(null);
	const [pharmaciesD7, setPharmaciesD7] = useState(null);
	const [provincesD7, setProvincesD7] = useState(null);
	const [localitiesD7, setLocalitiesD7] = useState(null);
	const [chainsD7, setChainsD7] = useState(null);
	const [usersD7, setUsersD7] = useState(null);
	const [categoriesD7, setCategoriesD7] = useState(null);
	const [generalInfoD7, setGeneralInfoD7] = useState(null);
	const [chainsTable, setChainsTable] = useState(null);

	const fetch_sales_dashboard = async () => {
		setIsLoading(true);
		try {
			const res = await get_dinamic_sales_dashboard(
				selectedDistributor,
				clientId,
				selectedProvince,
				dateFrom,
				dateTo,
				selectedCategory,
				selectedPointOfSale,
				selectedLocality,
				selectedChain,
				selectedSellerList
			);
			const resDistributorsD2 = JSON.parse(res?.generalInfo?.distributorList);
			const resProvincesD2 = JSON.parse(res?.generalInfo?.provinceList);
			const resLocalitiesD2 = JSON.parse(res?.generalInfo?.localityList);
			const resCategoriesD2 = JSON.parse(res?.generalInfo?.categoryList);
			const resPointsOfSaleD2 = JSON.parse(res?.generalInfo?.pointOfSaleList);
			const slicedProductSales = res?.productSales.slice(0, 40);
			const slicedProductUnits = res?.productSales
				.sort((a, b) => b.totalUnits - a.totalUnits)
				.slice(0, 40);
			const slicedCategorySales = res?.categorySales.slice(0, 40);
			const slicedCategoryUnits = res?.categorySales
				.sort((a, b) => b.totalUnits - a.totalUnits)
				.slice(0, 40);
			const resChainsD2 = JSON.parse(res?.generalInfo?.chainList);
			const resSellersD2 = JSON.parse(res?.generalInfo?.sellerList);
			const slicedRegionSales = res?.regionSales.slice(0, 15);
			const slicedLocalitySales = res?.localitySales.slice(0, 15);

			setDistributorsD2(
				resDistributorsD2?.map((r) => ({
					value: r.DistributorId,
					label: r.DistributorName,
				}))
			);

			setProvincesD2(
				resProvincesD2?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);

			setLocalitiesD2(
				resLocalitiesD2?.map((r) => ({
					value: r.LocalityName,
					label: r.LocalityName,
				}))
			);

			setCategoriesD2(
				resCategoriesD2?.map((r) => ({
					value: r.Id,
					label: r.Name,
				}))
			);

			setPointsOfSaleD2(
				resPointsOfSaleD2?.map((r) => ({
					value: r.PointOfSaleId,
					label: r.PointOfSaleName,
				}))
			);

			setChainD2(
				resChainsD2?.map((r) => ({
					value: r.ChainId,
					label: r.ChainName,
				}))
			);

			setUsersD2(
				resSellersD2?.map((r) => ({
					value: r.SellerId,
					label: r.SellerFirstName + " " + r.SellerLastName,
				}))
			);

			setGeneralInfoD2(res?.generalInfo);

			let counter = 0;

			setPieDataD2({
				chart: {
					type: "variablepie",
					height: "100%",
				},
				title: {
					style: { display: "none" },
				},
				credits: { enabled: false },
				tooltip: {
					pointFormat: "<b>{point.y}</b>",
				},
				legend: {
					layout: "vertical",
					align: "right",
					verticalAlign: "top",
					x: 0,
					y: 10,
					floating: false,
				},
				plotOptions: {
					variablepie: {
						size: "150",
						cursor: "pointer",
						dataLabels: {
							enabled: true,
							color: COLORS.Secondary,
							connectorColor: COLORS.Secondary,
							useHTML: true,
							formatter: function () {
								counter++;
								return (
									'<div key={"label_"' +
									counter +
									'} style={{display: "flex", flexDirection: "column"}}><div style={{width: "100%", whiteSpace: "nowrap", overflow: "hidden !important", textOverflow: "ellipsis"}}><b>' +
									this.point.name +
									"</b>: </div><div>" +
									this.percentage.toFixed(0) +
									" %</div></div>"
								);
							},
						},
						showInLegend: true,
						center: ["55%", "18%"],
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
				},
				series: [
					{
						type: "variablepie",
						zMin: 0,
						name: "Droguerias",
						data: res?.distributorOrders?.map((dist, idx) => ({
							name: dist.distributorName,
							y: dist.totalInOrders,
							z: 100,
						})),
					},
				],
			});

			setTimeSalesGraphD2({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.ordersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							if (
								this.x ===
									this.axis.series[0]?.xData[
										this.axis.series[0]?.xData.length - 2
									] &&
								this.axis.series[0]?.data[this.axis.series[0]?.data.length - 1]
									?.category ===
									CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
							) {
								return numberToMillions(this.total);
							} else {
								return null;
							}
						},
					},
				},
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "",
						data: res?.ordersByYearAndMonth.map((p) => p.totalSales),
						lineWidth: 2,
						lineColor: COLORS.Success,
						marker: {
							fillColor: COLORS.Secondary,
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Success,
							},
							{
								color: COLORS.LightSuccess,
							},
						],
					},
				],
			});

			setTimePharmacyOrdersGraphD2({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br>" + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.pharmacyOrdersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							if (
								this.x ===
									this.axis.series[0]?.xData[
										this.axis.series[0]?.xData.length - 2
									] &&
								this.axis.series[0]?.data[this.axis.series[0]?.data.length - 1]
									?.category ===
									CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
							) {
								return this.total;
							} else {
								return null;
							}
						},
					},
				},
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "",
						data: res?.pharmacyOrdersByYearAndMonth.map((p) => p.totalOrders),
						lineWidth: 2,
						lineColor: COLORS.Success,
						marker: {
							fillColor: COLORS.Secondary,
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.pharmacyOrdersByYearAndMonth[
										res.pharmacyOrdersByYearAndMonth.length - 1
									]?.saleMonth === res.generalInfo.actualMonth
										? res?.pharmacyOrdersByYearAndMonth.length - 2
										: res?.pharmacyOrdersByYearAndMonth.length,
								color: COLORS.Success,
							},
							{
								color: COLORS.LightSuccess,
							},
						],
					},
				],
			});

			setTimeOrdersGraphD2({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br>" + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.totalOrdersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							if (
								this.x ===
									this.axis.series[0]?.xData[
										this.axis.series[0]?.xData.length - 2
									] &&
								this.axis.series[0]?.data[this.axis.series[0]?.data.length - 1]
									?.category ===
									CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
							) {
								return this.total;
							} else {
								return null;
							}
						},
					},
				},
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "",
						data: res?.totalOrdersByYearAndMonth.map((p) => p.totalOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
						marker: {
							fillColor: COLORS.Success,
							lineWidth: 2,
							lineColor: COLORS.Success,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.totalOrdersByYearAndMonth[
										res.totalOrdersByYearAndMonth.length - 1
									]?.saleMonth === res.generalInfo.actualMonth
										? res?.totalOrdersByYearAndMonth.length - 2
										: res?.totalOrdersByYearAndMonth.length,
								color: COLORS.Secondary,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
				],
			});

			setDistributorSalesGraphD2({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.distributorSales.map((p) => p.distributorName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
				},
				series: [
					{
						name: "",
						data: res?.distributorSales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setSecondPieDataD2({
				chart: {
					type: "variablepie",
					height: "100%",
				},
				title: {
					style: { display: "none" },
				},
				credits: { enabled: false },
				tooltip: {
					pointFormat: "<b>{point.y}</b>",
				},
				legend: {
					layout: "vertical",
					align: "right",
					verticalAlign: "top",
					x: 0,
					y: 10,
					floating: false,
				},
				plotOptions: {
					variablepie: {
						size: "150",
						cursor: "pointer",
						dataLabels: {
							enabled: true,
							color: COLORS.Secondary,
							connectorColor: COLORS.Secondary,
							useHTML: true,
							formatter: function () {
								counter++;
								return (
									'<div key={"label_"' +
									counter +
									'} style={{display: "flex", flexDirection: "column"}}><div style={{width: "100%", whiteSpace: "nowrap", overflow: "hidden !important", textOverflow: "ellipsis"}}><b>' +
									this.point.name +
									"</b>: </div><div>" +
									this.percentage.toFixed(0) +
									" %</div></div>"
								);
							},
						},
						showInLegend: true,
						center: ["55%", "18%"],
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
				},
				series: [
					{
						type: "variablepie",
						zMin: 0,
						name: "Droguerias",
						data: res?.distributorSales?.map((dist, idx) => ({
							name: dist.distributorName,
							y: dist.totalInOrders,
							z: 100,
						})),
					},
				],
			});

			setThirdPieDataD2({
				chart: {
					type: "variablepie",
					height: "100%",
				},
				title: {
					style: { display: "none" },
				},
				credits: { enabled: false },
				tooltip: {
					pointFormat: "<b>{point.y}</b>",
				},
				legend: {
					layout: "vertical",
					align: "right",
					verticalAlign: "top",
					x: 0,
					y: 10,
					floating: false,
				},
				plotOptions: {
					variablepie: {
						size: "150",
						cursor: "pointer",
						dataLabels: {
							enabled: true,
							color: COLORS.Secondary,
							connectorColor: COLORS.Secondary,
							useHTML: true,
							formatter: function () {
								counter++;
								return (
									'<div key={"label_"' +
									counter +
									'} style={{display: "flex", flexDirection: "column"}}><div style={{width: "100%", whiteSpace: "nowrap", overflow: "hidden !important", textOverflow: "ellipsis"}}><b>' +
									this.point.name +
									"</b>: </div><div>" +
									this.percentage.toFixed(0) +
									" %</div></div>"
								);
							},
						},
						showInLegend: true,
						center: ["55%", "18%"],
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
				},
				series: [
					{
						type: "variablepie",
						zMin: 0,
						name: "Droguerias",
						data: res?.distributorSales?.map((dist, idx) => ({
							name: dist.distributorName,
							y: dist.totalUnits,
							z: 100,
						})),
					},
				],
			});

			setTimeUnitsGraphD2({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br>" + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.ordersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							if (
								this.x ===
									this.axis.series[0]?.xData[
										this.axis.series[0]?.xData.length - 2
									] &&
								this.axis.series[0]?.data[this.axis.series[0]?.data.length - 1]
									?.category ===
									CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
							) {
								return this.total;
							} else {
								return null;
							}
						},
					},
				},
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "",
						data: res?.ordersByYearAndMonth.map((p) => p.totalUnits),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Secondary,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
				],
			});

			setProductSalesGraphD2({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedProductSales.map(
						(p) => p.productTitle + " (" + p.productEan13 + ")"
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return numberToMillions(this.total);
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedProductSales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setProductUnitsGraphD2({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br> " + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedProductUnits.map(
						(p) => p.productTitle + " (" + p.productEan13 + ")"
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -5,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return this.total;
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedProductUnits.map((p) => p.totalUnits),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setRegionSalesGraphD2({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedRegionSales.map((p) => p.provinceName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return numberToMillions(this.total);
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedRegionSales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setCategorySalesGraphD2({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedCategorySales.map((p) => p.categoryName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return numberToMillions(this.total);
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedCategorySales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setCategoryUnitsGraphD2({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br> " + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedCategoryUnits.map((p) => p.categoryName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -5,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return this.total;
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedCategoryUnits.map((p) => p.totalUnits),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setLocalitySalesGraphD2({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedLocalitySales.map((p) => p.localityName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return numberToMillions(this.total);
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedLocalitySales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			let categories = [];
			let saleSeries = [];
			let unitSeries = [];

			const allDates = res?.categoriesByYearAndMonth
				?.map(
					({ saleYear, saleMonth }) =>
						`${saleYear}-${String(saleMonth).padStart(2, "0")}`
				)
				?.sort();

			const uniqueDates = [...new Set(allDates)];

			let saleDataByCategory = res?.categorySales
				?.map((d) => d.categoryName)
				?.reduce((acc, name) => {
					acc[name] = uniqueDates.map(() => 0);
					return acc;
				}, {});

			let unitDataByCategory = res?.categorySales
				?.map((d) => d.categoryName)
				?.reduce((acc, name) => {
					acc[name] = uniqueDates.map(() => 0);
					return acc;
				}, {});

			res?.categoriesByYearAndMonth?.forEach(
				({ saleYear, saleMonth, totalSales, totalUnits, categoryNameList }) => {
					if (saleDataByCategory[categoryNameList]) {
						const date = `${saleYear}-${String(saleMonth).padStart(2, "0")}`;
						const index = uniqueDates.indexOf(date);
						if (index !== -1) {
							saleDataByCategory[categoryNameList][index] = totalSales;
							unitDataByCategory[categoryNameList][index] = totalUnits;
						}
					}
				}
			);

			categories = uniqueDates;
			saleSeries = Object.keys(saleDataByCategory)?.map((name, index) => ({
				name,
				data: saleDataByCategory[name],
				lineColor: COLORS.ChartColors[index],
				color: COLORS.ChartColors[index],
				lineWidth: 2,
				marker: {
					fillColor: "white",
					lineWidth: 2,
					lineColor: COLORS.ChartColors[index],
					symbol: "circle",
					radius: 3,
				},
			}));

			unitSeries = Object.keys(unitDataByCategory)?.map((name, index) => ({
				name,
				data: unitDataByCategory[name],
				lineColor: COLORS.ChartColors[index],
				color: COLORS.ChartColors[index],
				lineWidth: 2,
				marker: {
					fillColor: "white",
					lineWidth: 2,
					lineColor: COLORS.ChartColors[index],
					symbol: "circle",
					radius: 3,
				},
			}));

			setTimeCategoriesSalesGraphD2({
				chart: { type: "column", zoomType: "x" },
				title: { text: undefined },
				credits: { enabled: false },
				xAxis: { categories: categories, min: 0 },
				yAxis: {
					title: { text: "Total ventas" },
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
					},
					gridLineColor: COLORS.LightGrey,
					gridLineWidth: 1,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
				},
				tooltip: {
					shared: false,
					useHTML: true,
					formatter: function () {
						return `<span style="color:${this.point.color}">\u25CF</span> ${
							this.series.name
						}: $${numberWithDotAndCommas(this.point.y)}<br/><b>(${(
							(this.point.y * 100) /
							this.point.stackTotal
						).toFixed(2)} %)</b><br/>`;
					},
				},
				plotLines: [
					{
						color: "#FF0000",
						width: 1,
						value: 0,
					},
				],
				plotOptions: {
					column: {
						stacking: "normal",
						dataLabels: {
							enabled: true,
							style: {
								fontWeight: "lighter",
								color: COLORS.White,
								fontSize: "8px",
								textOutline: 1,
							},
							formatter: function () {
								const percentage = (
									(this.point.y * 100) /
									this.point.stackTotal
								).toFixed(2);
								return percentage > 10 ? `${percentage}%` : null;
							},
							verticalAlign: "middle",
							inside: true,
							crop: true,
						},
					},
					series: {
						states: {
							hover: {
								enabled: true,
							},
						},
						marker: {
							enabled: true,
						},
					},
				},
				series: saleSeries,
			});

			setTimeCategoriesUnitsGraphD2({
				chart: { type: "column", zoomType: "x" },
				title: { text: undefined },
				credits: { enabled: false },
				xAxis: { categories: categories, min: 0 },
				yAxis: {
					title: { text: "Total ventas" },
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
					},
					gridLineColor: COLORS.LightGrey,
					gridLineWidth: 1,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
				},
				tooltip: {
					shared: false,
					useHTML: true,
					formatter: function () {
						return `<span style="color:${this.point.color}">\u25CF</span> ${
							this.series.name
						}: ${this.point.y}<br/><b>(${(
							(this.point.y * 100) /
							this.point.stackTotal
						).toFixed(2)} %)</b><br/>`;
					},
				},
				plotLines: [
					{
						color: "#FF0000",
						width: 1,
						value: 0,
					},
				],
				plotOptions: {
					column: {
						stacking: "normal",
						dataLabels: {
							enabled: true,
							style: {
								fontWeight: "lighter",
								color: COLORS.White,
								fontSize: "8px",
								textOutline: 1,
							},
							formatter: function () {
								const percentage = (
									(this.point.y * 100) /
									this.point.stackTotal
								).toFixed(2);
								return percentage > 10 ? `${percentage}%` : null;
							},
							verticalAlign: "middle",
							inside: true,
							crop: true,
						},
					},
					series: {
						states: {
							hover: {
								enabled: true,
							},
						},
						marker: {
							enabled: true,
						},
					},
				},
				series: unitSeries,
			});

			setTimeHistoricSalesGraphD2({
				chart: { type: "xy" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					pointFormat:
						'<span style="color:{series.color}">{series.name}<b> {point.y}</b></span><br/>',
					valueDecimals: 0,
					split: false,
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Secondary, COLORS.Primary],
				credits: { enabled: false },
				xAxis: {
					categories: res?.ordersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: [
					{
						labels: {
							formatter: function () {
								return this.value === 0
									? 0
									: (this.value / 1000000).toFixed(2) + "M";
							},
							style: {
								color: COLORS.Primary,
							},
						},
						visible: true,
						title: {
							text: "Ventas",
							style: {
								color: COLORS.Primary,
							},
						},
						gridLineColor: COLORS.LightGrey,
						gridLineWidth: 1,
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
						stackLabels: {
							enabled: true,
							style: {
								color: COLORS.MediumGrey,
								textOutline: 0,
							},
							y: -2,
							overflow: "allow",
							crop: false,
							formatter: function () {
								return numberToMillions(this.total);
							},
						},
					},
					{
						title: {
							text: "Unidades",
							style: {
								color: COLORS.Secondary,
							},
						},
						visible: true,
						labels: {
							style: {
								color: COLORS.Secondary,
							},
						},
						opposite: true,
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
					},
				],
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "Unidades",
						type: "column",
						valueSuffix: "un.",
						data: res?.ordersByYearAndMonth.map((p) => p.totalUnits),
						yAxis: 1,
						lineWidth: 2,
						lineColor: COLORS.Default,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Default,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 1
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Secondary,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
					{
						name: "Ventas $",
						type: "spline",
						valuePrefix: "$",
						data: res?.ordersByYearAndMonth.map((p) => p.totalSales),
						yAxis: 0,
						lineWidth: 2,
						lineColor: COLORS.Primary,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Primary,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
				],
			});

			//Mapeo para completar meses sin ventas mobile
			// const mobileSalesMap = res?.mobileOrdersByYearAndMonth?.reduce(
			// 	(acc, curr) => {
			// 		acc[curr.saleMonth] = curr.totalSales;
			// 		return acc;
			// 	},
			// 	{}
			// );

			// const mobileSalesData = res?.ordersByYearAndMonth?.map((p) => {
			// 	const mobileSales = mobileSalesMap[p.saleMonth] || 0;
			// 	return mobileSales;
			// });

			// const mobileUnitsMap = res?.mobileOrdersByYearAndMonth?.reduce(
			// 	(acc, curr) => {
			// 		acc[curr.saleMonth] = curr.totalUnits;
			// 		return acc;
			// 	},
			// 	{}
			// );

			// const mobileUnitsData = res?.ordersByYearAndMonth?.map((p) => {
			// 	const mobileUnits = mobileUnitsMap[p.saleMonth] || 0;
			// 	return mobileUnits;
			// });

			// const mobileOrdersMap = res?.mobileTotalOrdersByYearAndMonth?.reduce(
			// 	(acc, curr) => {
			// 		acc[curr.saleMonth] = curr.totalOrders;
			// 		return acc;
			// 	},
			// 	{}
			// );

			// const mobileOrdersData = res?.totalOrdersByYearAndMonth?.map((p) => {
			// 	const mobileOrders = mobileOrdersMap[p.saleMonth] || 0;
			// 	return mobileOrders;
			// });

			// setTimeVSSalesGraphD2({
			// 	chart: { type: "spline" },
			// 	title: { text: undefined },
			// 	tooltip: {
			// 		shared: true,
			// 		pointFormat:
			// 			'<span style="color:{series.color}">{series.name}</span><b> {point.y}</b><br/>',
			// 		valueDecimals: 0,
			// 		split: false,
			// 	},
			// 	legend: { enabled: false },
			// 	colors: [COLORS.Primary, COLORS.Default],
			// 	credits: { enabled: false },
			// 	xAxis: {
			// 		categories: res?.ordersByYearAndMonth.map(
			// 			(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
			// 		),
			// 	},
			// 	yAxis: [
			// 		{
			// 			visible: true,
			// 			title: { text: null },
			// 			labels: { enabled: true },
			// 			gridLineColor: COLORS.LightGrey,
			// 			gridLineWidth: 0,
			// 			lineWidth: 1,
			// 			plotLines: [{ color: COLORS.LightGrey, width: 1, value: 0 }],
			// 			stackLabels: {
			// 				enabled: true,
			// 				style: { color: COLORS.MediumGrey, textOutline: 0 },
			// 				y: -8,
			// 				overflow: "allow",
			// 				crop: false,
			// 				formatter: function () {
			// 					if (
			// 						this.x ===
			// 							this.axis.series[0]?.xData[
			// 								this.axis.series[0]?.xData.length - 2
			// 							] &&
			// 						this.axis.series[0]?.data[
			// 							this.axis.series[0]?.data.length - 1
			// 						]?.category ===
			// 							CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
			// 					) {
			// 						return numberToMillions(this.total);
			// 					} else {
			// 						return null;
			// 					}
			// 				},
			// 			},
			// 		},
			// 		{
			// 			visible: false,
			// 			opposite: true,
			// 			title: { text: null },
			// 			labels: { enabled: true },
			// 			gridLineColor: "#197F07",
			// 			gridLineWidth: 0,
			// 			lineWidth: 1,
			// 		},
			// 	],
			// 	plotOptions: {
			// 		series: { stacking: "normal" },
			// 	},
			// 	series: [
			// 		{
			// 			name: "Ventas Totales",
			// 			data: res?.ordersByYearAndMonth.map((p) => p.totalSales),
			// 			lineWidth: 2,
			// 			lineColor: COLORS.Primary,
			// 			marker: {
			// 				fillColor: COLORS.Primary,
			// 				lineWidth: 2,
			// 				lineColor: COLORS.Primary,
			// 				symbol: "circle",
			// 				radius: 3,
			// 			},
			// 			zoneAxis: "x",
			// 			zones: [
			// 				{
			// 					value:
			// 						res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
			// 							?.saleMonth === res.generalInfo.actualMonth
			// 							? res?.ordersByYearAndMonth.length - 2
			// 							: res?.ordersByYearAndMonth.length,
			// 					color: COLORS.Primary,
			// 				},
			// 				{ color: COLORS.LightSecondary },
			// 			],
			// 			yAxis: 0,
			// 		},
			// 		{
			// 			name: "Ventas Mobile",
			// 			data: mobileSalesData,
			// 			type: "area",
			// 			lineWidth: 2,
			// 			lineColor: COLORS.Default,
			// 			marker: {
			// 				fillColor: COLORS.Default,
			// 				lineWidth: 2,
			// 				lineColor: COLORS.Default,
			// 				symbol: "circle",
			// 				radius: 2,
			// 			},
			// 			yAxis: 0,
			// 			zoneAxis: "x",
			// 			zones: [
			// 				{
			// 					value:
			// 						res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
			// 							?.saleMonth === res.generalInfo.actualMonth
			// 							? res?.ordersByYearAndMonth.length - 2
			// 							: res?.ordersByYearAndMonth.length,
			// 					color: COLORS.Default,
			// 				},
			// 				{ color: COLORS.LightDefault },
			// 			],
			// 		},
			// 	],
			// });

			// setTimeVSUnitsGraphD2({
			// 	chart: { type: "spline" },
			// 	title: { text: undefined },
			// 	tooltip: {
			// 		shared: true,
			// 		pointFormat:
			// 			'<span style="color:{series.color}">{series.name}</span><b> {point.y}</b><br/>',
			// 		valueDecimals: 0,
			// 		split: false,
			// 	},
			// 	legend: { enabled: false },
			// 	colors: [COLORS.Primary, COLORS.Default],
			// 	credits: { enabled: false },
			// 	xAxis: {
			// 		categories: res?.ordersByYearAndMonth.map(
			// 			(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
			// 		),
			// 	},
			// 	yAxis: [
			// 		{
			// 			visible: true,
			// 			title: { text: null },
			// 			labels: { enabled: true },
			// 			gridLineColor: "#197F07",
			// 			gridLineWidth: 0,
			// 			lineWidth: 1,
			// 			plotLines: [{ color: "#FF0000", width: 1, value: 0 }],
			// 			stackLabels: {
			// 				enabled: true,
			// 				style: { color: COLORS.MediumGrey, textOutline: 0 },
			// 				y: -8,
			// 				overflow: "allow",
			// 				crop: false,
			// 				formatter: function () {
			// 					if (
			// 						this.x ===
			// 							this.axis.series[0]?.xData[
			// 								this.axis.series[0]?.xData.length - 2
			// 							] &&
			// 						this.axis.series[0]?.data[
			// 							this.axis.series[0]?.data.length - 1
			// 						]?.category ===
			// 							CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
			// 					) {
			// 						return this.total;
			// 					} else {
			// 						return null;
			// 					}
			// 				},
			// 			},
			// 		},
			// 		{
			// 			visible: false,
			// 			opposite: true,
			// 			title: { text: null },
			// 			labels: { enabled: true },
			// 			gridLineColor: "#197F07",
			// 			gridLineWidth: 0,
			// 			lineWidth: 1,
			// 		},
			// 	],
			// 	plotOptions: {
			// 		series: { stacking: "normal" },
			// 	},
			// 	series: [
			// 		{
			// 			name: "Unidades Totales",
			// 			data: res?.ordersByYearAndMonth.map((p) => p.totalUnits),
			// 			lineWidth: 2,
			// 			lineColor: COLORS.Primary,
			// 			marker: {
			// 				fillColor: COLORS.Primary,
			// 				lineWidth: 2,
			// 				lineColor: COLORS.Primary,
			// 				symbol: "circle",
			// 				radius: 3,
			// 			},
			// 			zoneAxis: "x",
			// 			zones: [
			// 				{
			// 					value:
			// 						res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
			// 							?.saleMonth === res.generalInfo.actualMonth
			// 							? res?.ordersByYearAndMonth.length - 2
			// 							: res?.ordersByYearAndMonth.length,
			// 					color: COLORS.Primary,
			// 				},
			// 				{ color: COLORS.LightSecondary },
			// 			],
			// 			yAxis: 0,
			// 		},
			// 		{
			// 			name: "Unidades Mobile",
			// 			data: mobileUnitsData,
			// 			type: "area",
			// 			lineWidth: 2,
			// 			lineColor: COLORS.Secondary,
			// 			marker: {
			// 				fillColor: COLORS.Secondary,
			// 				lineWidth: 2,
			// 				lineColor: COLORS.Secondary,
			// 				symbol: "circle",
			// 				radius: 2,
			// 			},
			// 			yAxis: 0,
			// 			zoneAxis: "x",
			// 			zones: [
			// 				{
			// 					value:
			// 						res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
			// 							?.saleMonth === res.generalInfo.actualMonth
			// 							? res?.ordersByYearAndMonth.length - 2
			// 							: res?.ordersByYearAndMonth.length,
			// 					color: COLORS.Default,
			// 				},
			// 				{ color: COLORS.LightDefault },
			// 			],
			// 		},
			// 	],
			// });

			// setTimeVSOrdersGraphD2({
			// 	chart: { type: "spline" },
			// 	title: { text: undefined },
			// 	tooltip: {
			// 		shared: true,
			// 		pointFormat:
			// 			'<span style="color:{series.color}">{series.name}</span><b> {point.y}</b><br/>',
			// 		valueDecimals: 0,
			// 		split: false,
			// 	},
			// 	legend: { enabled: false },
			// 	colors: [COLORS.Primary, COLORS.Default],
			// 	credits: { enabled: false },
			// 	xAxis: {
			// 		categories: res?.totalOrdersByYearAndMonth.map(
			// 			(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
			// 		),
			// 	},
			// 	yAxis: [
			// 		{
			// 			visible: true,
			// 			title: { text: null },
			// 			labels: { enabled: true },
			// 			gridLineColor: "#197F07",
			// 			gridLineWidth: 0,
			// 			lineWidth: 1,
			// 			plotLines: [{ color: "#FF0000", width: 1, value: 0 }],
			// 			stackLabels: {
			// 				enabled: true,
			// 				style: { color: COLORS.MediumGrey, textOutline: 0 },
			// 				y: -8,
			// 				overflow: "allow",
			// 				crop: false,
			// 				formatter: function () {
			// 					if (
			// 						this.x ===
			// 							this.axis.series[0]?.xData[
			// 								this.axis.series[0]?.xData.length - 2
			// 							] &&
			// 						this.axis.series[0]?.data[
			// 							this.axis.series[0]?.data.length - 1
			// 						]?.category ===
			// 							CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
			// 					) {
			// 						return this.total;
			// 					} else {
			// 						return null;
			// 					}
			// 				},
			// 			},
			// 		},
			// 		{
			// 			visible: false,
			// 			opposite: true,
			// 			title: { text: null },
			// 			labels: { enabled: true },
			// 			gridLineColor: "#197F07",
			// 			gridLineWidth: 0,
			// 			lineWidth: 1,
			// 		},
			// 	],
			// 	plotOptions: {
			// 		series: { stacking: "normal" },
			// 	},
			// 	series: [
			// 		{
			// 			name: "Ordenes Totales",
			// 			data: res?.totalOrdersByYearAndMonth.map((p) => p.totalOrders),
			// 			lineWidth: 2,
			// 			lineColor: COLORS.Primary,
			// 			marker: {
			// 				fillColor: COLORS.Primary,
			// 				lineWidth: 2,
			// 				lineColor: COLORS.Primary,
			// 				symbol: "circle",
			// 				radius: 3,
			// 			},
			// 			zoneAxis: "x",
			// 			zones: [
			// 				{
			// 					value:
			// 						res?.totalOrdersByYearAndMonth[
			// 							res.totalOrdersByYearAndMonth.length - 1
			// 						]?.saleMonth === res.generalInfo.actualMonth
			// 							? res?.totalOrdersByYearAndMonth.length - 2
			// 							: res?.totalOrdersByYearAndMonth.length,
			// 					color: COLORS.Primary,
			// 				},
			// 				{ color: COLORS.LightSecondary },
			// 			],
			// 			yAxis: 0,
			// 		},
			// 		{
			// 			name: "Ordenes Mobile",
			// 			data: mobileOrdersData,
			// 			type: "area",
			// 			lineWidth: 2,
			// 			lineColor: COLORS.Secondary,
			// 			marker: {
			// 				fillColor: COLORS.Secondary,
			// 				lineWidth: 2,
			// 				lineColor: COLORS.Secondary,
			// 				symbol: "circle",
			// 				radius: 2,
			// 			},
			// 			yAxis: 0,
			// 			zoneAxis: "x",
			// 			zones: [
			// 				{
			// 					value:
			// 						res?.totalOrdersByYearAndMonth[
			// 							res.totalOrdersByYearAndMonth.length - 1
			// 						]?.saleMonth === res.generalInfo.actualMonth
			// 							? res?.totalOrdersByYearAndMonth.length - 2
			// 							: res?.totalOrdersByYearAndMonth.length,
			// 					color: COLORS.Default,
			// 				},
			// 				{ color: COLORS.LightDefault },
			// 			],
			// 		},
			// 	],
			// });
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetch_mobile_dashboard = async () => {
		setIsLoading(true);
		try {
			const res = await get_dinamic_mobile_dashboard(
				selectedDistributor,
				clientId,
				selectedSellerList,
				selectedProvince,
				dateFromD3,
				dateToD3,
				selectedCategory
			);
			const resDistributorsD3 = JSON.parse(res?.generalInfo?.distributorList);
			const resProvincesD3 = JSON.parse(res?.generalInfo?.provinceList);
			const resSellersD3 = JSON.parse(res?.generalInfo?.sellerList);
			const slicedProductSales = res?.productSales.slice(0, 25);
			const slicedProductUnits = res?.productSales
				.sort((a, b) => b.totalUnits - a.totalUnits)
				.slice(0, 25);
			const distributorsUnits = res?.distributorSales.sort(
				(a, b) => b.totalUnits - a.totalUnits
			);
			const resCategoriesD3 = JSON.parse(res?.generalInfo?.categoryList);

			setDistributorsD3(
				resDistributorsD3?.map((r) => ({
					value: r.DistributorId,
					label: r.DistributorName,
				}))
			);

			setProvincesD3(
				resProvincesD3?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);

			setUsersD3(
				resSellersD3?.map((r) => ({
					value: r.SellerId,
					label: r.SellerFirstName + " " + r.SellerLastName,
				}))
			);

			setCategoriesD3(
				resCategoriesD3?.map((r) => ({
					value: r.Id,
					label: r.Name,
				}))
			);

			setGeneralInfoD3(res?.generalInfo);

			setTimePharmacyOrdersGraphD3({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br>" + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.posOrderingByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							if (
								this.x ===
									this.axis.series[0]?.xData[
										this.axis.series[0]?.xData.length - 2
									] &&
								this.axis.series[0]?.data[this.axis.series[0]?.data.length - 1]
									?.category ===
									CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
							) {
								return numberToMillions(this.total);
							} else {
								return null;
							}
						},
					},
				},
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "",
						data: res?.posOrderingByYearAndMonth.map((p) => p.totalPosOrdering),
						lineWidth: 2,
						lineColor: COLORS.Success,
						marker: {
							fillColor: COLORS.Secondary,
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.posOrderingByYearAndMonth[
										res.posOrderingByYearAndMonth.length - 1
									]?.saleMonth === res.generalInfo.actualMonth
										? res?.posOrderingByYearAndMonth.length - 2
										: res?.posOrderingByYearAndMonth.length,
								color: COLORS.Success,
							},
							{
								color: COLORS.LightSuccess,
							},
						],
					},
				],
			});

			setSellerSalesTableD3(res?.sellerSales);

			setTimeOrdersGraphD3({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					pointFormat:
						'<span style="color:{series.color}">{series.name}</span><b> {point.y}</b><br/>',
					valueDecimals: 0,
					split: false,
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Primary, COLORS.Secondary],
				credits: { enabled: false },
				xAxis: {
					categories: res?.ordersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: [
					{
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						gridLineWidth: 0,
						minorGridLineWidth: 0,
						lineColor: "transparent",
						visible: true,
						gridLineColor: "#197F07",
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
						stackLabels: {
							enabled: true,
							style: {
								color: COLORS.MediumGrey,
								textOutline: 0,
							},
							y: -8,
							overflow: "allow",
							crop: false,
							formatter: function () {
								if (
									this.x ===
										this.axis.series[0]?.xData[
											this.axis.series[0]?.xData.length - 2
										] &&
									this.axis.series[0]?.data[
										this.axis.series[0]?.data.length - 1
									]?.category ===
										CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
								) {
									return numberToMillions(this.total);
								} else {
									return null;
								}
							},
						},
					},
					{
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						gridLineWidth: 0,
						minorGridLineWidth: 0,
						lineColor: "transparent",
						visible: false,
						gridLineColor: "#197F07",
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
					},
				],
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "Ventas $",
						valuePrefix: "$",
						data: res?.ordersByYearAndMonth.map((p) => p.totalSales),
						yAxis: 0,
						lineWidth: 2,
						lineColor: COLORS.Primary,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Primary,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
					{
						name: "Unidades",
						valueSuffix: "un.",
						data: res?.ordersByYearAndMonth.map((p) => p.totalUnits),
						yAxis: 1,
						lineWidth: 2,
						lineColor: COLORS.Default,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Default,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Default,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
				],
			});

			setDistributorSalesGraphD3({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.distributorSales.map((p) => p.distributorName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						formatter: function () {
							return numberToMillions(this.total);
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: res?.distributorSales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setDistributorUnitsGraphD3({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br> " + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: distributorsUnits.map((p) => p.distributorName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						formatter: function () {
							return this.total;
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: distributorsUnits.map((p) => p.totalUnits),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setDistributorOrdersGraphD3({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br> " + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.distributorOrders.map((p) => p.distributorName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						formatter: function () {
							return this.total;
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: res?.distributorOrders.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setProductSalesGraphD3({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedProductSales.map(
						(p) => p.productTitle + " (" + p.productEan13 + ")"
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return numberToMillions(this.total);
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedProductSales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setProductUnitsGraphD3({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br> " + this.y + " un.";
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedProductUnits.map(
						(p) => p.productTitle + " (" + p.productEan13 + ")"
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return this.total;
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedProductUnits.map((p) => p.totalUnits),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetch_pharmacy_dashboard = async () => {
		setIsLoading(true);
		try {
			const res = await get_pharmacy_laboratory_dashboard(
				selectedDistributor,
				clientId,
				selectedPharmacy,
				selectedPointOfSale,
				selectedProvince,
				selectedLocality,
				dateFrom,
				dateTo,
				selectedChain,
				selectedSellerList,
				selectedCategory
			);
			const resDistributorsD4 = JSON.parse(res?.generalInfo?.distributorList);
			const resProvincesD4 = JSON.parse(res?.generalInfo?.provinceList);
			const resLocalitiesD4 = JSON.parse(res?.generalInfo?.localityList);
			const resLaboratoriesD4 = JSON.parse(res?.generalInfo?.laboratoryList);
			const resPharmaciesD4 = JSON.parse(res?.generalInfo?.pharmacyList);
			const slicedPharmacyTable = res?.pharmacyOrdersList.slice(0, 50);
			const resChainsD4 = JSON.parse(res?.generalInfo?.chainList);
			const resUsersD4 = JSON.parse(res?.generalInfo?.sellerList);
			const resCategoriesD4 = JSON.parse(res?.generalInfo?.categoryList);

			setDistributorsD4(
				resDistributorsD4?.map((r) => ({
					value: r.DistributorId,
					label: r.DistributorName,
				}))
			);

			setProvincesD4(
				resProvincesD4?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);

			setLocalitiesD4(
				resLocalitiesD4?.map((r) => ({
					value: r.LocalityName,
					label: r.LocalityName,
				}))
			);

			setLaboratoriesD4(
				resLaboratoriesD4?.map((r) => ({
					value: r.LaboratoryId,
					label: r.LaboratoryName,
				}))
			);

			setPharmaciesD4(
				resPharmaciesD4?.map((r) => ({
					value: r.PharmacyId,
					label: r.PharmacyName,
				}))
			);

			setChainsD4(
				resChainsD4?.map((r) => ({
					value: r.ChainId,
					label: r.ChainName,
				}))
			);

			setUsersD4(
				resUsersD4?.map((r) => ({
					value: r.SellerId,
					label: r.SellerFirstName + " " + r.SellerLastName,
				}))
			);

			setCategoriesD4(
				resCategoriesD4?.map((r) => ({
					value: r.Id,
					label: r.Name,
				}))
			);

			setGeneralInfoD4(res?.generalInfo);

			setPharmaciesTable(slicedPharmacyTable);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetch_product_dashboard = async () => {
		setIsLoading(true);
		try {
			const res = await get_products_laboratory_dashboard(
				selectedDistributor,
				clientId,
				selectedPharmacy,
				selectedPointOfSale,
				selectedProvince,
				selectedLocality,
				dateFrom,
				dateTo,
				selectedBrand,
				selectedCategory,
				selectedChain,
				selectedSellerList
			);
			const resDistributorsD5 = JSON.parse(res?.generalInfo?.distributorList);
			const resProvincesD5 = JSON.parse(res?.generalInfo?.provinceList);
			const resLocalitiesD5 = JSON.parse(res?.generalInfo?.localityList);
			const resLaboratoriesD5 = JSON.parse(res?.generalInfo?.laboratoryList);
			const resPharmaciesD5 = JSON.parse(res?.generalInfo?.pharmacyList);
			const resBrandsD5 = JSON.parse(res?.generalInfo?.brandList);
			const resCategoriesD5 = JSON.parse(res?.generalInfo?.categoryList);
			const resChainsD5 = JSON.parse(res?.generalInfo?.chainList);
			const resPointsOfSaleD5 = JSON.parse(res?.generalInfo?.pointOfSaleList);
			const slicedProductTable = res?.productOrdersList;
			const resSellersD5 = JSON.parse(res?.generalInfo?.sellerList);

			setDistributorsD5(
				resDistributorsD5?.map((r) => ({
					value: r.DistributorId,
					label: r.DistributorName,
				}))
			);

			setProvincesD5(
				resProvincesD5?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);

			setLocalitiesD5(
				resLocalitiesD5?.map((r) => ({
					value: r.LocalityName,
					label: r.LocalityName,
				}))
			);

			setLaboratoriesD5(
				resLaboratoriesD5?.map((r) => ({
					value: r.LaboratoryId,
					label: r.LaboratoryName,
				}))
			);

			setPharmaciesD5(
				resPharmaciesD5?.map((r) => ({
					value: r.PharmacyId,
					label: r.PharmacyName,
				}))
			);

			setBrandsD5(
				resBrandsD5?.map((r) => ({
					value: r.BrandId,
					label: r.BrandName,
				}))
			);

			setCategoriesD5(
				resCategoriesD5?.map((r) => ({
					value: r.Id,
					label: r.Name,
				}))
			);

			setPointsOfSaleD5(
				resPointsOfSaleD5?.map((r) => ({
					value: r.PointOfSaleId,
					label: r.PointOfSaleName,
				}))
			);

			setChainsD5(
				resChainsD5?.map((r) => ({
					value: r.ChainId,
					label: r.ChainName,
				}))
			);

			setUsersD5(
				resSellersD5?.map((r) => ({
					value: r.SellerId,
					label: r.SellerFirstName + " " + r.SellerLastName,
				}))
			);

			setGeneralInfoD5(res?.generalInfo);

			setProductsTable(slicedProductTable);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetch_initiative_dashboard = async () => {
		setIsLoading(true);
		try {
			const res = await get_initiatives_dashboard(
				selectedDistributor,
				clientId,
				selectedPointOfSale,
				selectedProvince,
				selectedLocality,
				dateFrom,
				dateTo,
				selectedCategory,
				selectedChain,
				selectedSellerList,
				selectedInitiative
			);
			const resDistributorsD6 = JSON.parse(res?.generalInfo?.distributorList);
			const resLaboratoriesD6 = JSON.parse(res?.generalInfo?.laboratoryList);
			const resProvincesD6 = JSON.parse(res?.generalInfo?.provinceList);
			const resLocalitiesD6 = JSON.parse(res?.generalInfo?.localityList);
			const resCategoriesD6 = JSON.parse(res?.generalInfo?.categoryList);
			const resPointsOfSaleD6 = JSON.parse(res?.generalInfo?.pointOfSaleList);
			const resChainsD6 = JSON.parse(res?.generalInfo?.chainList);
			const resSellersD6 = JSON.parse(res?.generalInfo?.sellerList);
			const resInitiativesD6 = JSON.parse(res?.generalInfo?.initiativeList);

			setDistributorsD6(
				resDistributorsD6?.map((r) => ({
					value: r.DistributorId,
					label: r.DistributorName,
				}))
			);

			setProvincesD6(
				resProvincesD6?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);

			setLocalitiesD6(
				resLocalitiesD6?.map((r) => ({
					value: r.LocalityName,
					label: r.LocalityName,
				}))
			);

			setLaboratoriesD6(
				resLaboratoriesD6?.map((r) => ({
					value: r.LaboratoryId,
					label: r.LaboratoryName,
				}))
			);

			setCategoriesD6(
				resCategoriesD6?.map((r) => ({
					value: r.Id,
					label: r.Name,
				}))
			);

			setPointsOfSaleD6(
				resPointsOfSaleD6?.map((r) => ({
					value: r.PointOfSaleId,
					label: r.PointOfSaleName,
				}))
			);

			setChainsD6(
				resChainsD6?.map((r) => ({
					value: r.ChainId,
					label: r.ChainName,
				}))
			);

			setUsersD6(
				resSellersD6?.map((r) => ({
					value: r.SellerId,
					label: r.SellerFirstName + " " + r.SellerLastName,
				}))
			);

			setInitiativesD6(
				resInitiativesD6?.map((r) => ({
					value: r.Id,
					label: r.Title,
				}))
			);

			setGeneralInfoD6(res?.generalInfo);

			setProductTotalvsInitiativesD6({
				chart: { zoomType: "xy" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					pointFormat:
						'<span style="color:{series.color}">{series.name}:  <b>$ {point.y}</b></span><br/>' +
						'<span style="color:{series.color}">Porcentaje sobre Ventas Totales:  <b>{point.percentage}%</b></span><br/>',
					valueDecimals: 0,
					split: false,
					formatter: function () {
						const totalPeriod = res?.generalInfo?.totalYearSales;
						const initiativeValue = this.points[0].y;

						const percentage =
							totalPeriod === 0 ? 0 : (initiativeValue / totalPeriod) * 100;

						return `<span style="color:${this.points[1].series.color}">&nbsp${
							this.points[0].series.name
						}: <b>$${numberWithDotAndCommas(
							parseFloat(initiativeValue)
						)}</b></span>
              <br/>
              <span style="color:${
								COLORS.Default
							}">Porcentaje sobre Ventas Período: <b>${percentage.toFixed(
							2
						)}%</b></span><br/>
            `;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Secondary, COLORS.Secondary],
				credits: { enabled: false },
				xAxis: {
					categories: res?.productSales?.map(
						(d) => d.productTitle + " (" + d.productEan13 + ")"
					),
				},
				yAxis: [
					{
						labels: {
							formatter: function () {
								return this.value === 0
									? 0
									: (this.value / 1000000).toFixed(2) + "M";
							},
							style: {
								color: COLORS.Secondary,
							},
						},
						visible: true,
						title: {
							text: "Ventas",
							style: {
								color: COLORS.Secondary,
							},
						},
						gridLineColor: COLORS.LightGrey,
						gridLineWidth: 1,
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
						stackLabels: {
							enabled: true,
							style: {
								color: COLORS.MediumGrey,
								textOutline: 0,
							},
							y: -2,
							overflow: "allow",
							crop: false,
							formatter: function () {
								return numberToMillions(this.total);
							},
						},
					},
					{
						title: {
							text: "",
							style: {
								color: COLORS.White,
							},
						},
						min: 0.0,
						max: 100.0,
						visible: true,
						labels: {
							style: {
								color: COLORS.White,
							},
						},
						opposite: true,
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
						stackLabels: {
							enabled: true,
							style: {
								color: COLORS.Default,
								textOutline: 0,
							},
							y: -2,
							overflow: "allow",
							crop: false,
							formatter: function () {
								return "%" + this.total.toFixed(2);
							},
						},
					},
				],
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "Iniciativa",
						type: "column",
						valuePrefix: "$",
						data: res?.productSales?.map((d) => d.totalInOrderWithInitiative),
						yAxis: 0,
						lineWidth: 2,
						lineColor: COLORS.Secondary,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							symbol: "circle",
							radius: 3,
						},
					},
					{
						name: "Porcentaje",
						type: "spline",
						valuePrefix: "%",
						data: res?.productSales?.map((d) =>
							res?.generalInfo?.totalYearSales === 0
								? 0
								: (d.totalInOrderWithInitiative /
										res?.generalInfo?.totalYearSales) *
								  100
						),
						yAxis: 1,
						lineWidth: 2,
						lineColor: COLORS.Default,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Default,
							symbol: "circle",
							radius: 3,
						},
						// zoneAxis: "x",
						// zones: [
						// 	{
						// 		color: COLORS.Default,
						// 	},
						// 	{
						// 		color: COLORS.LightDefault,
						// 	},
						// ],
					},
				],
			});

			setTimeInitiativesSalesGraphD6({
				chart: { zoomType: "xy" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					pointFormat:
						'<span style="color:{series.color}">{series.name}:  <b>$ {point.y}</b></span><br/>' +
						'<span style="color:{series.color}">Porcentaje sobre Ventas Totales:  <b>{point.percentage}%</b></span><br/>',
					valueDecimals: 0,
					split: false,
					formatter: function () {
						const totalSalesPoint = this.points.find(
							(p) => p.series.name === "Ventas Totales"
						);
						const initiativePoint = this.points.find(
							(p) => p.series.name === "Iniciativas"
						);

						if (totalSalesPoint && initiativePoint) {
							const totalSalesValue = this.points[0].y;
							const initiativeValue = this.points[1].y;

							const percentage =
								totalSalesValue === 0
									? 0
									: (initiativeValue / totalSalesValue) * 100;

							return `<span style="color:${this.points[0].series.color}">&nbsp${
								this.points[0].series.name
							}: <b>$${numberWithDotAndCommas(
								parseFloat(totalSalesValue)
							)}</b></span><br/><span style="color:${
								this.points[1].series.color
							}"> ${this.points[1].series.name}: <b>$${numberWithDotAndCommas(
								parseFloat(initiativeValue)
							)}</b></span>
                    <br/>
                    <span style="color:${
											COLORS.Default
										}">Porcentaje sobre Ventas Totales: <b>${percentage.toFixed(
								2
							)}%</b></span><br/>
                  `;
						} else {
							const point = this.points[0];
							return `
                  <span style="color:${point.series.color}">${
								point.series.name
							}: <b>$ ${numberWithDotAndCommas(
								parseFloat(point.y)
							)}</b></span><br/>
                `;
						}
					},
				},
				legend: {
					enabled: true,
				},
				colors: [COLORS.Secondary, COLORS.Primary],
				credits: { enabled: false },
				xAxis: {
					categories: res?.ordersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: [
					{
						labels: {
							formatter: function () {
								return this.value === 0
									? 0
									: (this.value / 1000000).toFixed(2) + "M";
							},
							style: {
								color: COLORS.Secondary,
							},
						},
						visible: true,
						title: {
							text: "Ventas",
							style: {
								color: COLORS.Secondary,
							},
						},
						gridLineColor: COLORS.LightGrey,
						gridLineWidth: 1,
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
						// stackLabels: {
						// 	enabled: true,
						// 	style: {
						// 		color: COLORS.MediumGrey,
						// 		textOutline: 0,
						// 	},
						// 	y: -2,
						// 	overflow: "allow",
						// 	crop: false,
						// 	formatter: function () {
						// 		return numberToMillions(this.total);
						// 	},
						// },
					},
					{
						title: {
							text: "Iniciativas",
							style: {
								color: COLORS.Secondary,
							},
						},
						visible: false,
						labels: {
							style: {
								color: COLORS.Secondary,
							},
						},
						opposite: true,
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
					},
				],
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "Ventas Totales",
						type: "spline",
						valuePrefix: "$",
						data: res?.ordersByYearAndMonth.map(
							(d) => d.totalSalesWithoutInitiative
						),
						yAxis: 0,
						lineWidth: 2,
						lineColor: COLORS.Secondary,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							symbol: "circle",
							radius: 3,
						},
					},
					{
						name: "Iniciativas",
						type: "spline",
						valuePrefix: "$",
						data: res?.ordersByYearAndMonth?.map(
							(d) => d.totalSalesWithInitiative
						),
						yAxis: 0,
						lineWidth: 2,
						lineColor: COLORS.Primary,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							symbol: "circle",
							radius: 3,
						},
					},
				],
			});

			setTimeInitiativesUnitsGraphD6({
				chart: { zoomType: "xy" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					pointFormat:
						'<span style="color:{series.color}">{series.name}:  <b>{point.y}</b></span><br/>' +
						'<span style="color:{series.color}">Porcentaje sobre Unidades Totales:  <b>{point.percentage}%</b></span><br/>',
					valueDecimals: 0,
					split: false,
					formatter: function () {
						const totalUnitsPoint = this.points.find(
							(p) => p.series.name === "Unidades Totales"
						);
						const initiativePoint = this.points.find(
							(p) => p.series.name === "Iniciativas"
						);

						if (totalUnitsPoint && initiativePoint) {
							const totalUnitsValue = this.points[0].y;
							const initiativeValue = this.points[1].y;

							const percentage =
								totalUnitsValue === 0
									? 0
									: (initiativeValue / totalUnitsValue) * 100;

							return `<span style="color:${this.points[0].series.color}">&nbsp${
								this.points[0].series.name
							}: <b>${totalUnitsValue}</b></span><br/><span style="color:${
								this.points[1].series.color
							}"> ${
								this.points[1].series.name
							}: <b>${initiativeValue}</b></span>
                    <br/>
                    <span style="color:${
											COLORS.Default
										}">Porcentaje sobre Ventas Totales: <b>${percentage.toFixed(
								2
							)}%</b></span><br/>
                  `;
						} else {
							const point = this.points[0];
							return `
                  <span style="color:${point.series.color}">${point.series.name}: <b>$ ${point.y}</b></span><br/>
                `;
						}
					},
				},
				legend: {
					enabled: true,
				},
				colors: [COLORS.Secondary, COLORS.Primary],
				credits: { enabled: false },
				xAxis: {
					categories: res?.ordersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: [
					{
						labels: {
							formatter: function () {
								return this.value === 0 ? 0 : this.value;
							},
							style: {
								color: COLORS.Secondary,
							},
						},
						visible: true,
						title: {
							text: "Unidades",
							style: {
								color: COLORS.Secondary,
							},
						},
						gridLineColor: COLORS.LightGrey,
						gridLineWidth: 1,
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
						// stackLabels: {
						// 	enabled: true,
						// 	style: {
						// 		color: COLORS.MediumGrey,
						// 		textOutline: 0,
						// 	},
						// 	y: -2,
						// 	overflow: "allow",
						// 	crop: false,
						// 	formatter: function () {
						// 		return this.total;
						// 	},
						// },
					},
					{
						title: {
							text: "Iniciativas",
							style: {
								color: COLORS.Secondary,
							},
						},
						visible: false,
						labels: {
							style: {
								color: COLORS.Secondary,
							},
						},
						opposite: true,
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
					},
				],
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "Unidades Totales",
						type: "spline",
						valuePrefix: "$",
						data: res?.ordersByYearAndMonth.map(
							(d) => d.totalUnitsWithoutInitiative
						),
						yAxis: 0,
						lineWidth: 2,
						lineColor: COLORS.Secondary,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							symbol: "circle",
							radius: 3,
						},
					},
					{
						name: "Iniciativas",
						type: "spline",
						valuePrefix: "$",
						data: res?.ordersByYearAndMonth?.map(
							(d) => d.totalUnitsWithInitiative
						),
						yAxis: 0,
						lineWidth: 2,
						lineColor: COLORS.Primary,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							symbol: "circle",
							radius: 3,
						},
					},
				],
			});
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetch_chain_dashboard = async () => {
		setIsLoading(true);
		try {
			const res = await get_chain_laboratory_dashboard(
				selectedDistributor,
				clientId,
				selectedPharmacy,
				selectedPointOfSale,
				selectedProvince,
				selectedLocality,
				dateFrom,
				dateTo,
				selectedChain,
				selectedSellerList,
				selectedCategory
			);
			const resDistributorsD7 = JSON.parse(res?.generalInfo?.distributorList);
			const resProvincesD7 = JSON.parse(res?.generalInfo?.provinceList);
			const resLocalitiesD7 = JSON.parse(res?.generalInfo?.localityList);
			const resLaboratoriesD7 = JSON.parse(res?.generalInfo?.laboratoryList);
			const resPharmaciesD7 = JSON.parse(res?.generalInfo?.pharmacyList);
			const slicedChainTable = res?.chainOrdersList?.map((c) => ({
				...c,
				sellerList: (c.sellerFirstName?.split(",") || []).map(
					(firstName, index) =>
						`${firstName} ${(c.sellerLastName?.split(",") || [])[index] || ""}`
				),
			}));
			const resChainsD7 = JSON.parse(res?.generalInfo?.chainList);
			const resUsersD7 = JSON.parse(res?.generalInfo?.sellerList);
			const resCategoriesD7 = JSON.parse(res?.generalInfo?.categoryList);

			setDistributorsD7(
				resDistributorsD7?.map((r) => ({
					value: r.DistributorId,
					label: r.DistributorName,
				}))
			);

			setProvincesD7(
				resProvincesD7?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);

			setLocalitiesD7(
				resLocalitiesD7?.map((r) => ({
					value: r.LocalityName,
					label: r.LocalityName,
				}))
			);

			setLaboratoriesD7(
				resLaboratoriesD7?.map((r) => ({
					value: r.LaboratoryId,
					label: r.LaboratoryName,
				}))
			);

			setPharmaciesD7(
				resPharmaciesD7?.map((r) => ({
					value: r.PharmacyId,
					label: r.PharmacyName,
				}))
			);

			setChainsD7(
				resChainsD7?.map((r) => ({
					value: r.ChainId,
					label: r.ChainName,
				}))
			);

			setUsersD7(
				resUsersD7?.map((r) => ({
					value: r.SellerId,
					label: r.SellerFirstName + " " + r.SellerLastName,
				}))
			);

			setCategoriesD7(
				resCategoriesD7?.map((r) => ({
					value: r.Id,
					label: r.Name,
				}))
			);

			setGeneralInfoD7(res?.generalInfo);

			setChainsTable(slicedChainTable);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (sellerDashboardDisplay === CONSTANTS.DASHBOARD_SALES)
			fetch_sales_dashboard();
	}, [
		selectedDistributor,
		selectedLaboratory,
		selectedProvince,
		dateFrom,
		dateTo,
		sellerDashboardDisplay,
		selectedCategory,
		selectedPointOfSale,
		selectedLocality,
		selectedChain,
		selectedSellerList,
	]);

	useEffect(() => {
		if (sellerDashboardDisplay === CONSTANTS.DASHBOARD_MOBILE)
			fetch_mobile_dashboard();
	}, [
		selectedDistributor,
		selectedLaboratory,
		selectedSellerList,
		selectedProvince,
		dateFromD3,
		dateToD3,
		sellerDashboardDisplay,
		selectedCategory,
	]);

	useEffect(() => {
		if (sellerDashboardDisplay === CONSTANTS.DASHBOARD_PHARMACY)
			fetch_pharmacy_dashboard();
	}, [
		selectedDistributor,
		selectedLaboratory,
		selectedPharmacy,
		selectedPointOfSale,
		selectedProvince,
		selectedLocality,
		dateFrom,
		dateTo,
		sellerDashboardDisplay,
		selectedChain,
		selectedSellerList,
		selectedCategory,
	]);

	useEffect(() => {
		if (sellerDashboardDisplay === CONSTANTS.DASHBOARD_PRODUCTS)
			fetch_product_dashboard();
	}, [
		selectedDistributor,
		selectedLaboratory,
		selectedPharmacy,
		selectedPointOfSale,
		selectedProvince,
		selectedLocality,
		dateFrom,
		dateTo,
		sellerDashboardDisplay,
		selectedBrand,
		selectedCategory,
		selectedChain,
		selectedSellerList,
	]);

	useEffect(() => {
		if (sellerDashboardDisplay === CONSTANTS.DASHBOARD_INITIATIVES)
			fetch_initiative_dashboard();
	}, [
		selectedDistributor,
		selectedLaboratory,
		selectedPointOfSale,
		selectedProvince,
		selectedLocality,
		dateFrom,
		dateTo,
		sellerDashboardDisplay,
		selectedCategory,
		selectedChain,
		selectedSellerList,
		selectedInitiative,
	]);

	useEffect(() => {
		if (sellerDashboardDisplay === CONSTANTS.DASHBOARD_CHAIN)
			fetch_chain_dashboard();
	}, [
		selectedDistributor,
		selectedLaboratory,
		selectedPharmacy,
		selectedPointOfSale,
		selectedProvince,
		selectedLocality,
		dateFrom,
		dateTo,
		sellerDashboardDisplay,
		selectedChain,
		selectedSellerList,
		selectedCategory,
	]);
	// const handleBackToTheGuide = () => {
	// 	updateStartGuideProgress(user.uid, 6);
	// 	navigate("/startGuide");
	// };

	const handleSelectDistributor = (e, o) => {
		setSelectedDistributor(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Distributor" },
		]);
	};

	const handleSelectProvince = (e, o) => {
		setSelectedProvince(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Province" },
		]);
	};

	const handleSelectLocality = (e, o) => {
		setSelectedLocality(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Locality" },
		]);
	};

	const handleSelectCategory = (e, o) => {
		setSelectedCategory(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Category" },
		]);
	};

	const handleSelectPointOfSale = (e, o) => {
		setSelectedPointOfSale(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "PointOfSale" },
		]);
	};

	const handleSelectChain = (e, o) => {
		setSelectedChain(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Chain" },
		]);
	};

	const handleSelectBrand = (e, o) => {
		setSelectedBrand(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Brand" },
		]);
	};

	const handleSelectInitiative = (e, o) => {
		setSelectedInitiative(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Initiative" },
		]);
	};

	const handleSelectedDate = (dateRange) => {
		const firstDate = moment(dateRange[0]).format("MM-DD-YYYY").toString();
		const lastDate = moment(dateRange[1]).format("MM-DD-YYYY").toString();
		setDateFrom(firstDate);
		setDateTo(lastDate);
		setRangeDate([dateRange[0], dateRange[1]]);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: "Fecha", key: "DateRange" },
		]);
	};

	const handleDeleteFilters = () => {
		setSelectedDistributor(null);
		setSelectedProvince(null);
		setSelectedLocality(null);
		setSelectedLaboratory(null);
		setSelectedPharmacy(null);
		setSelectedSellerList(
			userdata.isOneMarket ||
				(userdata.isSeller &&
					(userdata.isAdmin ||
						(!userdata.p_shop && !userdata.p_mobile && userdata.dashboard)))
				? []
				: user.uid
		);
		setSelectedCategory(null);
		setSelectedPointOfSale(null);
		setSelectedChain(null);
		setSelectedBrand(null);
		setSelectedInitiative(null);
		setDateFrom(null);
		setDateTo(null);
		setRangeDate(null);
		setSelectedFilters([]);
		setMobilePeriod(CONSTANTS.PERIOD_CURRENT);
		setDateFromD3(moment().startOf("month").format("MM-DD-YYYY").toString());
		setDateToD3(moment().endOf("month").format("MM-DD-YYYY").toString());
		setRangeDateD3(null);
		setSelectedTypeOfSale(null);
		onCloseFilterList();
	};

	const handleSelectSeller = (object) => {
		const sellersValue = object?.map((o) => o.value)?.join(",") ?? [];
		const sellersLabel = object?.map((o) => o.label)?.join(",") ?? null;
		setSelectedSellerList(sellersValue);
		sellersLabel &&
			setSelectedFilters((prevFilters) => {
				const filtered = prevFilters.filter(
					(filter) => filter.key !== "Seller"
				);
				return [...filtered, { label: sellersLabel, key: "Seller" }];
			});
	};

	const handleSelectPharmacy = (e, o) => {
		setSelectedPharmacy(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Pharmacy" },
		]);
	};

	const handleSelectedDateD3 = (dateRange) => {
		const firstDate = moment(dateRange[0]).format("MM-DD-YYYY").toString();
		const lastDate = moment(dateRange[1]).format("MM-DD-YYYY").toString();
		setDateFromD3(firstDate);
		setDateToD3(lastDate);
		setDateFrom(firstDate);
		setDateTo(lastDate);
		setRangeDate([dateRange[0], dateRange[1]]);
	};

	const handleDistributorGraphSelect = (value) => {
		setDistributorGraphSelector(value);
	};

	const handleProductGraphSelectD2 = (value) => {
		setProductGraphSelectorD2(value);
	};

	const handleCategoryGraphSelectD2 = (value) => {
		setCategoryGraphSelectorD2(value);
	};

	const handleCategoryGraphSelect2D2 = (value) => {
		setCategoryGraphSelector2D2(value);
	};

	const handleInitiativeGraphSelectD6 = (value) => {
		setInitiativeGraphSelectorD6(value);
	};

	const handlePeriodChange = (value) => {
		console.log(value);
		setMobilePeriod(value);
		if (value === CONSTANTS.PERIOD_CURRENT) {
			const startOfMonth = moment()
				.startOf("month")
				.format("MM-DD-YYYY")
				.toString();
			const endOfMonth = moment()
				.endOf("month")
				.format("MM-DD-YYYY")
				.toString();
			setDateFromD3(startOfMonth);
			setDateToD3(endOfMonth);
			setRangeDateD3([moment().startOf("month"), moment().endOf("month")]);
		}

		if (value === CONSTANTS.PERIOD_HISTORICAL) {
			setDateFromD3(dateFrom);
			setDateToD3(dateTo);
			setRangeDateD3(rangeDate);
		}
	};

	const handleMenuClick = ({ key }) => {
		setSellerDashboardDisplay(key);
	};

	// const handleVSGraphD2 = (value) => {
	// 	setVSInfoSelector(value);
	// };

	const handleTypeOfSaleD2 = (value, o) => {
		setSelectedTypeOfSale(value);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "TypeOfSale" },
		]);
	};

	const handleFilterRemove = (filterToRemove) => {
		setSelectedFilters((prevFilters) =>
			prevFilters.filter((filter) => filter !== filterToRemove)
		);
		//onCloseFilterList();

		switch (filterToRemove.key) {
			case "Distributor":
				setSelectedDistributor(null);
				break;
			case "Province":
				setSelectedProvince(null);
				break;
			case "Locality":
				setSelectedLocality(null);
				break;
			case "Laboratory":
				setSelectedLaboratory(null);
				break;
			case "DateRange":
				setDateFrom(null);
				setDateTo(null);
				setRangeDate(null);
				break;
			case "Seller":
				setSelectedSellerList([]);
				break;
			case "Pharmacy":
				setSelectedPharmacy(null);
				break;
			case "TypeOfSale":
				setSelectedTypeOfSale(null);
				break;
			case "Category":
				setSelectedCategory(null);
				break;
			case "PointOfSale":
				setSelectedPointOfSale(null);
				break;
			case "Chain":
				setSelectedChain(null);
				break;
			case "Brand":
				setSelectedBrand(null);
				break;
			case "Initiative":
				setSelectedInitiative(null);
				break;
			default:
				break;
		}
	};

	const showFilterList = (value) => {
		setOpenFilterList(value);
	};

	const onCloseFilterList = () => {
		setOpenFilterList(false);
	};

	const handleOrdersInfoSelect = (value) => {
		setOrdersInfoSelector(value);
	};

	const handleDistributorsPieInfoSelect = (value) => {
		setDistributorsPieInfoSelector(value);
	};

	const handleSalesInfoSelectD2 = (value) => {
		setSalesInfoSelectorD2(value);
	};

	const handleProductGraphSelectD3 = (value) => {
		setProductGraphSelectorD3(value);
	};

	const handleDownloadProductsTable = async () => {
		setIsLoading(true);
		try {
			const dashboardData = {
				productsToExport: productsTable,
			};
			const res = await export_dashboard_products(dashboardData);
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const fileName =
				"ProductsDashboard_" + moment().format("DDMMYYYYhhmmss") + ".xlsx";
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Layout style={{ minHeight: "100vh" }}>
			<Sider
				theme="light"
				trigger={null}
				collapsible
				collapsed={collapsed}
				// onMouseEnter={() => setCollapsed(false)}
				// onMouseLeave={() => setCollapsed(true)}
			>
				<div></div>
				<Menu
					theme="light"
					mode="inline"
					defaultSelectedKeys={[CONSTANTS.DASHBOARD_SALES]}
					style={{ paddingTop: 20 }}
					onClick={handleMenuClick}
				>
					<Tooltip
						placement="right"
						title={"Filtros aplicados"}
						trigger={selectedFilters.length > 0 ? "hover" : ""}
					>
						<FilterContainerButton
							key={CONSTANTS.DASHBOARD_FILTERS}
							onClick={
								selectedFilters.length > 0
									? (prev) => showFilterList((prev) => !prev)
									: null
							}
							conditionFullfilled={selectedFilters.length > 0}
							height={45}
						>
							{selectedFilters.length > 0 ? (
								<FaInfo color={COLORS.Default} size={16} />
							) : null}
						</FilterContainerButton>{" "}
					</Tooltip>
					{
						//!(!userdata.p_shop && userdata.p_mobile)
						userdata && (
							<Menu.Item
								key={CONSTANTS.DASHBOARD_SALES}
								icon={<BarChartOutlined />}
								onClick={() =>
									setSellerDashboardDisplay(CONSTANTS.DASHBOARD_SALES)
								}
							>
								Ventas
							</Menu.Item>
						)
					}
					{clientUser?.client?.contract &&
						clientUser?.client?.contract?.activeMobileApp &&
						clientUser?.client?.contract?.mobileUsersZoneType ===
							CONSTANTS.USER_ZONE_TYPE_ROUTE && (
							<Menu.Item
								key={CONSTANTS.DASHBOARD_MOBILE}
								icon={<MobileOutlined />}
								onClick={() =>
									setSellerDashboardDisplay(CONSTANTS.DASHBOARD_MOBILE)
								}
							>
								Fuerza de venta
							</Menu.Item>
						)}
					{
						//!(!userdata.p_shop && userdata.p_mobile) && (
						userdata && (
							<Menu.Item
								key={CONSTANTS.DASHBOARD_CHAIN}
								icon={<ClusterOutlined />}
								onClick={() =>
									setSellerDashboardDisplay(CONSTANTS.DASHBOARD_CHAIN)
								}
							>
								Cadenas
							</Menu.Item>
						)
					}
					{
						//!(!userdata.p_shop && userdata.p_mobile) && (
						userdata && (
							<Menu.Item
								key={CONSTANTS.DASHBOARD_PHARMACY}
								icon={<TiPlusOutline />}
								onClick={() =>
									setSellerDashboardDisplay(CONSTANTS.DASHBOARD_PHARMACY)
								}
							>
								Farmacias
							</Menu.Item>
						)
					}
					{
						//!(!userdata.p_shop && userdata.p_mobile) && (
						userdata && (
							<Menu.Item
								key={CONSTANTS.DASHBOARD_PRODUCTS}
								icon={<FaBoxes />}
								onClick={() =>
									setSellerDashboardDisplay(CONSTANTS.DASHBOARD_PRODUCTS)
								}
							>
								Productos
							</Menu.Item>
						)
					}
					{userdata && (
						<Menu.Item
							key={CONSTANTS.DASHBOARD_INITIATIVES}
							icon={<BiTrendingUp />}
							onClick={() =>
								setSellerDashboardDisplay(CONSTANTS.DASHBOARD_INITIATIVES)
							}
						>
							Iniciativas
						</Menu.Item>
					)}
				</Menu>
				{selectedFilters.length > 0 && (
					<DrawerContainer
						title={
							<Typo type="primary" level={6}>
								Filtros aplicados
							</Typo>
						}
						placement="left"
						closable={false}
						onClose={onCloseFilterList}
						open={openFilterList}
						getContainer={false}
					>
						<div style={{ height: 170 }}>
							{selectedFilters.map((filter, index) => (
								<div
									key={index}
									onClick={() => handleFilterRemove(filter)}
									style={{
										cursor: "pointer",
										height: 30,
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Typo level={7} type="muted">
										{filter.label}
									</Typo>
									<CloseCircleOutlined style={{ color: COLORS.Danger }} />
								</div>
							))}
						</div>
						<div
							onClick={() => onCloseFilterList()}
							style={{
								position: "absolute",
								bottom: 5,
								display: "flex",
								justifyContent: "center",
								alignItems: "flex-end",
								width: "75%",
								cursor: "pointer",
							}}
						>
							<Typo type="muted" level={7}>
								Cerrar
							</Typo>
						</div>
					</DrawerContainer>
				)}
			</Sider>
			<Layout className="site-layout">
				<Inner>
					{/* {userdata?.guideProgress === 5 && (
						<SignInTemplate.AntdButton
							type="primary"
							bg="Primary"
							color="White"
							icon={<QuestionCircleOutlined />}
							onClick={handleBackToTheGuide}
							style={{
								width: "250px",
								fontWeight: "400",
								marginTop: "40px",
								marginLeft: "20px",
							}}
						>
							Volver a la Guia de Inicio
						</SignInTemplate.AntdButton>
					)} */}
					{sellerDashboardDisplay === CONSTANTS.DASHBOARD_SALES &&
						(generalInfoD2 ? (
							<SaleDashboard
								dashboard={generalInfoD2}
								distributorList={distributorsD2}
								provinceList={provincesD2}
								optionsPie={pieDataD2}
								distributors={distributorsD2}
								selectDistributor={handleSelectDistributor}
								provinces={provincesD2}
								selectProvince={handleSelectProvince}
								handleDateChange={handleSelectedDate}
								deleteFilters={handleDeleteFilters}
								isLoading={isLoading}
								timeSalesGraph={timeSalesGraphD2}
								timePharmacyOrdersGraph={timePharmacyOrdersGraphD2}
								timeOrderGraph={timeOrdersGraphD2}
								distributorSalesGraph={distributorSalesGraphD2}
								timeUnitsGraph={timeUnitsGraphD2}
								selectedDistributor={selectedDistributor}
								selectedProvince={selectedProvince}
								productSalesGraph={productSalesGraphD2}
								productUnitsGraph={productUnitsGraphD2}
								regionSalesGraph={regionSalesGraphD2}
								rangeDate={rangeDate}
								ordersInfoSelector={ordersInfoSelector}
								selectOrdersInfo={handleOrdersInfoSelect}
								distributorsInfoSelector={distributorsPieInfoSelector}
								selectDistributorsInfo={handleDistributorsPieInfoSelect}
								optionsPie2={secondPieDataD2}
								productGraphSelector={productGraphSelectorD2}
								selectProductGraph={handleProductGraphSelectD2}
								optionsPie3={thirdPieDataD2}
								selectedPointOfSale={selectedPointOfSale}
								selectPointOfSale={handleSelectPointOfSale}
								pointsOfSale={pointsOfSaleD2}
								selectedCategory={selectedCategory}
								selectCategory={handleSelectCategory}
								categories={categoriesD2}
								categorySalesGraph={categorySalesGraphD2}
								categoryUnitsGraph={categoryUnitsGraphD2}
								categoryGraphSelector={categoryGraphSelectorD2}
								selectCategoryGraph={handleCategoryGraphSelectD2}
								selectedLocality={selectedLocality}
								selectLocality={handleSelectLocality}
								localities={localitiesD2}
								selectedChain={selectedChain}
								selectChain={handleSelectChain}
								chains={chainD2}
								localitiesSalesGraph={localitySalesGraphD2}
								sellers={usersD2}
								selectSeller={handleSelectSeller}
								selectedSeller={selectedSellerList}
								userdata={userdata}
								timeCategoriesSalesGraph={timeCategoriesSalesGraphD2}
								timeCategoriesUnitsGraph={timeCategoriesUnitsGraphD2}
								categoryGraphSelector2={categoryGraphSelector2D2}
								selectCategoryGraph2={handleCategoryGraphSelect2D2}
								timeHistoricSalesGraph={timeHistoricSalesGraphD2}
								selectSalesInfo={salesInfoSelectorD2}
								salesInfoSelector={handleSalesInfoSelectD2}
							/>
						) : (
							<LoadingSkeleton />
						))}
					{sellerDashboardDisplay === CONSTANTS.DASHBOARD_MOBILE &&
						(generalInfoD3 ? (
							<MobileDashboard
								dashboard={generalInfoD3}
								distributorList={distributorsD3}
								provinceList={provincesD3}
								optionsPie={pieDataD3}
								distributors={distributorsD3}
								selectDistributor={handleSelectDistributor}
								provinces={provincesD3}
								selectProvince={handleSelectProvince}
								laboratories={laboratoriesD3}
								sellers={usersD3}
								selectSeller={handleSelectSeller}
								handleDateChange={handleSelectedDateD3}
								deleteFilters={handleDeleteFilters}
								isLoading={isLoading}
								posOrderingGraph={timePharmacyOrdersGraphD3}
								sellersTable={sellerSalesTableD3}
								timeOrdersGraph={timeOrdersGraphD3}
								distributorSalesGraph={distributorSalesGraphD3}
								distributorUnitsGraph={distributorUnitsGraphD3}
								distributorOrdersGraph={distributorOrdersGraphD3}
								selectedSeller={selectedSellerList}
								distributorGraphSelector={distributorGraphSelector}
								selectDistributorGraph={handleDistributorGraphSelect}
								selectPeriod={handlePeriodChange}
								rangeDate={rangeDateD3}
								mobilePeriod={mobilePeriod}
								productSalesGraph={productSalesGraphD3}
								productUnitsGraph={productUnitsGraphD3}
								productGraphSelector={productGraphSelectorD3}
								selectProductGraph={handleProductGraphSelectD3}
								userdata={userdata}
								selectedCategory={selectedCategory}
								selectCategory={handleSelectCategory}
								categories={categoriesD3}
							/>
						) : (
							<LoadingSkeleton />
						))}
					{sellerDashboardDisplay === CONSTANTS.DASHBOARD_PHARMACY &&
						(generalInfoD4 ? (
							<PharmacyDashboard
								dashboard={generalInfoD4}
								provinceList={provincesD4}
								pharmacies={pharmaciesD4}
								selectPharmacy={handleSelectPharmacy}
								distributors={distributorsD4}
								selectDistributor={handleSelectDistributor}
								provinces={provincesD4}
								selectProvince={handleSelectProvince}
								handleDateChange={handleSelectedDate}
								deleteFilters={handleDeleteFilters}
								selectedDistributor={selectedDistributor}
								selectedProvince={selectedProvince}
								selectedPharmacy={selectedPharmacy}
								isLoading={isLoading}
								pharmaciesTable={pharmaciesTable}
								rangeDate={rangeDate}
								selectedLocality={selectedLocality}
								selectLocality={handleSelectLocality}
								localities={localitiesD4}
								selectedChain={selectedChain}
								selectChain={handleSelectChain}
								chains={chainsD4}
								selectedCategory={selectedCategory}
								selectCategory={handleSelectCategory}
								categories={categoriesD4}
								sellers={usersD4}
								selectSeller={handleSelectSeller}
								selectedSeller={selectedSellerList}
								userdata={userdata}
							/>
						) : (
							<LoadingSkeleton />
						))}
					{sellerDashboardDisplay === CONSTANTS.DASHBOARD_PRODUCTS &&
						(generalInfoD5 ? (
							<ProductDashboard
								dashboard={generalInfoD5}
								provinceList={provincesD5}
								pharmacies={pharmaciesD5}
								selectPharmacy={handleSelectPharmacy}
								distributors={distributorsD5}
								selectDistributor={handleSelectDistributor}
								provinces={provincesD5}
								selectProvince={handleSelectProvince}
								handleDateChange={handleSelectedDate}
								deleteFilters={handleDeleteFilters}
								selectedDistributor={selectedDistributor}
								selectedProvince={selectedProvince}
								selectedPharmacy={selectedPharmacy}
								isLoading={isLoading}
								productsTable={productsTable}
								rangeDate={rangeDate}
								selectedLocality={selectedLocality}
								selectLocality={handleSelectLocality}
								localities={localitiesD5}
								selectedChain={selectedChain}
								selectChain={handleSelectChain}
								chains={chainsD5}
								selectedCategory={selectedCategory}
								selectCategory={handleSelectCategory}
								categories={categoriesD5}
								selectedBrand={selectedBrand}
								selectBrand={handleSelectBrand}
								brands={brandsD5}
								selectedPointOfSale={selectedPointOfSale}
								selectPointOfSale={handleSelectPointOfSale}
								pointsOfSale={pointsOfSaleD5}
								handleDownloadProductsTable={handleDownloadProductsTable}
								sellers={usersD5}
								selectSeller={handleSelectSeller}
								selectedSeller={selectedSellerList}
								userdata={userdata}
							/>
						) : (
							<LoadingSkeleton />
						))}
					{sellerDashboardDisplay === CONSTANTS.DASHBOARD_INITIATIVES &&
						(generalInfoD6 ? (
							<InitiativeDashboard
								dashboard={generalInfoD6}
								provinceList={provincesD6}
								distributors={distributorsD6}
								selectDistributor={handleSelectDistributor}
								provinces={provincesD6}
								selectProvince={handleSelectProvince}
								handleDateChange={handleSelectedDate}
								deleteFilters={handleDeleteFilters}
								selectedDistributor={selectedDistributor}
								selectedProvince={selectedProvince}
								isLoading={isLoading}
								rangeDate={rangeDate}
								selectedLocality={selectedLocality}
								selectLocality={handleSelectLocality}
								localities={localitiesD6}
								selectedChain={selectedChain}
								selectChain={handleSelectChain}
								chains={chainsD6}
								selectedCategory={selectedCategory}
								selectCategory={handleSelectCategory}
								categories={categoriesD6}
								selectedPointOfSale={selectedPointOfSale}
								selectPointOfSale={handleSelectPointOfSale}
								pointsOfSale={pointsOfSaleD6}
								handleDownloadProductsTable={handleDownloadProductsTable}
								sellers={usersD6}
								selectSeller={handleSelectSeller}
								selectedSeller={selectedSellerList}
								userdata={userdata}
								initiatives={initiativesD6}
								selectInitiative={handleSelectInitiative}
								selectedInitiative={selectedInitiative}
								productTotalvsInitiatives={productTotalvsInitiativesD6}
								timeInitiativesSalesGraph={timeInitiativesSalesGraphD6}
								timeInitiativesUnitsGraph={timeInitiativesUnitsGraphD6}
								initiativeGraphSelector={initiativeGraphSelectorD6}
								selectInitiativeGraph={handleInitiativeGraphSelectD6}
							/>
						) : (
							<LoadingSkeleton />
						))}
					{sellerDashboardDisplay === CONSTANTS.DASHBOARD_CHAIN &&
						(generalInfoD7 ? (
							<ChainDashboard
								dashboard={generalInfoD7}
								provinceList={provincesD7}
								pharmacies={pharmaciesD7}
								selectPharmacy={handleSelectPharmacy}
								distributors={distributorsD7}
								selectDistributor={handleSelectDistributor}
								provinces={provincesD7}
								selectProvince={handleSelectProvince}
								handleDateChange={handleSelectedDate}
								deleteFilters={handleDeleteFilters}
								selectedDistributor={selectedDistributor}
								selectedProvince={selectedProvince}
								selectedPharmacy={selectedPharmacy}
								isLoading={isLoading}
								chainsTable={chainsTable}
								rangeDate={rangeDate}
								selectedLocality={selectedLocality}
								selectLocality={handleSelectLocality}
								localities={localitiesD7}
								selectedChain={selectedChain}
								selectChain={handleSelectChain}
								chains={chainsD7}
								selectedCategory={selectedCategory}
								selectCategory={handleSelectCategory}
								categories={categoriesD7}
								sellers={usersD7}
								selectSeller={handleSelectSeller}
								selectedSeller={selectedSellerList}
								userdata={userdata}
							/>
						) : (
							<LoadingSkeleton />
						))}
				</Inner>
			</Layout>
		</Layout>
	);
}

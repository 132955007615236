import React from "react";
import { COLORS, Typo } from "../../../../../layout/theme";
import {
	AntdTable,
	BigChartContainer,
	Container,
	Dashboard1Container,
	FilterContainer,
} from "./styles";
import { Button, DatePicker, Select, Tag } from "antd";
import DebounceSelectSeller from "../../../../../common/debounce-select-seller/DebounceSelectSeller";

export default function ChainDashboard({
	dashboard,
	distributors,
	selectDistributor,
	provinces,
	selectProvince,
	pharmacies,
	selectPharmacy,
	handleDateChange,
	deleteFilters,
	isLoading,
	selectedDistributor,
	selectedProvince,
	selectedPharmacy,
	chainsTable,
	rangeDate,
	selectedLocality,
	selectLocality,
	localities,
	selectedChain,
	selectChain,
	chains,
	userdata,
	selectedCategory,
	selectCategory,
	categories,
	sellers,
	selectSeller,
	selectedSeller,
}) {
	const columns = [
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Nombre</div>,
			width: 350,
			dataIndex: "chainName",
			render: (value, record) => <Typo level={5}>{value}</Typo>,
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Vendedores</div>,
			key: "sellerList",
			dataIndex: "sellerList",
			render: (_, { sellerList }) => (
				<>
					{sellerList.map((tag) => {
						let color = tag.length > 16 ? "geekblue" : "green";
						if (tag === "loser") {
							color = "volcano";
						}
						return (
							<Tag color={color} key={tag}>
								{tag.toUpperCase()}
							</Tag>
						);
					})}
				</>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey }}>Ordenes totales</div>
			),
			dataIndex: "totalOrders",
			width: 140,
			render: (value, record) => (
				<Typo type="primary" level={3}>
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey }}>Unidades totales</div>
			),
			dataIndex: "totalUnits",
			width: 140,
			render: (value, record) => (
				<Typo type="secondary" level={3}>
					{value}
				</Typo>
			),
		},
	];

	return (
		<Dashboard1Container>
			<FilterContainer>
				<Container>
					<Typo type="primary" level={6}>
						Fecha
					</Typo>
					{/* <DatePicker.RangePicker onChange={(e) => handleDateChange(e)} /> */}
					{rangeDate ? (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							value={rangeDate}
							onChange={(e) => handleDateChange(e)}
						/>
					) : (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							onChange={(e) => handleDateChange(e)}
							placeholder={["Desde", "Hasta"]}
						/>
					)}
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Droguería
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Droguería"
						optionFilterProp="children"
						value={selectedDistributor}
						// defaultValue={selectedDistributor ?? null}
						onChange={(e, o) => selectDistributor(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={distributors}
						style={{ width: 150 }}
					/>
				</Container>
				{!selectedProvince && (
					<Container>
						<Typo type="primary" level={6}>
							Provincia
						</Typo>
						<Select
							showSearch
							//allowClear
							placeholder="Provincia"
							optionFilterProp="children"
							value={selectedProvince}
							// defaultValue={selectedProvince ?? null}
							onChange={(e, o) => selectProvince(e, o)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={provinces}
							style={{ width: 160 }}
						/>
					</Container>
				)}
				{selectedProvince && (
					<Container>
						<Typo type="primary" level={6}>
							Localidad
						</Typo>
						<Select
							showSearch
							//allowClear
							placeholder="Localidad"
							optionFilterProp="children"
							value={selectedLocality}
							// defaultValue={selectedProvince ?? null}
							onChange={(e, o) => selectLocality(e, o)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={localities}
							style={{ width: 160 }}
						/>
					</Container>
				)}
				<Container>
					<Typo type="primary" level={6}>
						Cadena
					</Typo>
					<Select
						showSearch
						placeholder="Cadena"
						optionFilterProp="children"
						value={selectedChain}
						onChange={(e, o) => selectChain(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={chains}
						style={{ width: 140 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Farmacia
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Farmacia"
						optionFilterProp="children"
						value={selectedPharmacy}
						// defaultValue={selectedPharmacy ?? null}
						onChange={(e, o) => selectPharmacy(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={pharmacies}
						style={{ width: 160 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Categoria
					</Typo>
					<Select
						showSearch
						// allowClear
						placeholder="Categoria"
						optionFilterProp="children"
						value={selectedCategory}
						// defaultValue={selectedProvince ?? null}
						onChange={(e, o) => selectCategory(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={categories}
						style={{ width: 180 }}
					/>
				</Container>
				{!(!userdata.p_shop && userdata.p_mobile) && (
					<Container>
						<Typo type="primary" level={6}>
							Vendedor
						</Typo>
						<DebounceSelectSeller
							fetchCallback={sellers}
							placeholder="Vendedor"
							onValuesChange={selectSeller}
							initialValues={selectedSeller}
						/>
					</Container>
				)}
				<Container style={{ justifyContent: "flex-end", width: "160px" }}>
					<Button type="primary" onClick={deleteFilters}>
						Borrar filtros
					</Button>
				</Container>
			</FilterContainer>
			<BigChartContainer>
				<AntdTable
					dataSource={chainsTable}
					loading={isLoading}
					columns={columns}
					pagination={false}
					scroll={{ y: "1020px" }}
				/>
			</BigChartContainer>
		</Dashboard1Container>
	);
}

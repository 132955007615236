import axiosInstance from "../axiosInstance";

const CONTROLLER = "import";

const import_products = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/ImportCheckposProducts`,
		data,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const import_brands = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/ImportBrands`,
		data,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const export_products = async (id) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/ExportProductsCT/${id}`,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

const import_photos = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/ImportPhotos`,
		data,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const import_book = async (data) => {
	const response = await axiosInstance.post(`${CONTROLLER}/ImportBook`, data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const import_distributor_file = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/ImportDistributorFile`,
		data,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

export {
	import_products,
	import_brands,
	export_products,
	import_photos,
	import_book,
	import_distributor_file,
};

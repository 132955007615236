import { FilterOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Space } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { get_products_by_laboratory_and_brand } from "../../../api/endpoints/products";
import {
	AntdSelector,
	ClientContainer,
	Container,
	Inner,
	OptionSection,
	SearchContainer,
	Section,
} from "./styles";
import debounce from "lodash/debounce";
import { CONSTANTS } from "../../../utils/constants";
import ProductTable from "./components/product-table/ProductTable";
import Image from "../image/Image";
import AntdSearch from "../antd-search/AntdSearch";
import { COLORS, Typo } from "../../layout/theme";
import { get_clients_name_list_filtered } from "../../../api/endpoints/clients";
let productIndex = null;

export default function AddProductsInitiativeTable({
	templateProducts,
	setTemplateProducts,
	userId,
	clientId,
	laboratoryId,
	isMultiLab,
	includeCombo = false,
}) {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchByStore, setSearchByStore] = useState("");
	const [skip, setSkip] = useState(1);
	const [page, setPage] = useState(CONSTANTS.PAGE_SIZE_ALL);
	const [searchInfo, setSearchInfo] = useState(null);
	const [paginationInputValue, setPaginationInputValue] = useState(null);
	const [brandList, setBrandList] = useState(null);
	const [brandId, setBrandId] = useState("");
	const [originalData, setOriginalData] = useState([]);
	const [allProductsIsActive, setAllProductsIsActive] = useState(false);
	const [clients, setClients] = useState(null);
	const [selectedClient, setSelectedClient] = useState(null);

	const brandColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Marca</div>,
			dataIndex: "photoUrl",
			render: (value, record, index) => (
				<Container key={`${value}`}>
					{record && (
						<div>
							{value ? (
								<Image width={30} height={30} src={value} alt={record.name} />
							) : (
								<div
									style={{
										width: 30,
										height: 30,
										backgroundColor: COLORS.Primary,
										borderRadius: "50%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<span style={{ color: COLORS.White, fontSize: 18 }}>
										{record.name.charAt(0).toUpperCase()}
									</span>
								</div>
							)}
						</div>
					)}
					<Typo type="primary" level={6}>
						{record.name}
					</Typo>
				</Container>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}></div>,
			key: "action",
			render: (_, record, index) => (
				<Space size="middle">
					<Button
						type="primary"
						ghost={record.id !== brandId}
						onClick={() => {
							handleSelectBrand(record.id);
						}}
					>
						<FilterOutlined />
					</Button>
				</Space>
			),
		},
	];

	const productColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			width: 80,
			render: (value, record, index) => (
				<div key={`${value}`}>
					<Image width={40} height={40} src={value} alt={record.title} />
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			key: "title",
			render: (value, record, index) => (
				<Typo type="primary" level={6}>
					{value} {record.presentationName}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Agregar</div>,
			key: "action",
			width: 80,
			render: (_, record, index) => (
				<Space size="middle">
					<Button
						type="primary"
						ghost
						onClick={() => {
							handleAddToTemplate(index);
						}}
					>
						<RightCircleOutlined />
					</Button>
				</Space>
			),
		},
	];

	useEffect(() => {
		if (laboratoryId || selectedClient) fetch_products();
	}, [laboratoryId, selectedClient]);

	useEffect(() => {
		if (isMultiLab) fetch_market_client_list();
	}, []);

	const fetch_market_client_list = async () => {
		const res = await get_clients_name_list_filtered();
		setClients(
			res
				?.filter((c) => c.isSeller && c.isApproved)
				?.map((r) => ({
					value: r.id,
					label: r.name,
					isApproved: r.isApproved,
				}))
		);
	};

	const fetch_products = async (skipValue) => {
		setLoading(true);
		try {
			const clientId = laboratoryId ?? selectedClient ?? "";
			const res = await get_products_by_laboratory_and_brand(
				"",
				clientId,
				"",
				userId,
				includeCombo,
				skipValue != null ? skipValue : skip,
				page
			);
			setOriginalData(
				res.items.map((r) => ({
					...r,
					quantity: 1,
					key: r.id,
				}))
			);
			setData(
				res.items.map((r) => ({
					...r,
					quantity: 1,
					key: r.id,
				}))
			);
			setSearchInfo(res?.searchInfo);
			setPaginationInputValue(res?.searchInfo?.actualPage);
			const brands = JSON.parse(res?.searchInfo?.brandList);
			setBrandList([
				{ id: null, name: "TODOS", photoUrl: null },
				...brands?.map((r) => ({
					id: r.BrandId,
					name: r.BrandName,
					photoUrl: r.BrandPhotoUrl,
				})),
			]);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (productIndex) {
			setData(data?.filter((p, idx) => p.id !== productIndex[0]?.id));
		}
	}, [productIndex]);

	const handleAddToTemplate = async (e, q) => {
		const _template = data?.filter((p, idx) => idx === e);
		productIndex = _template;
		const auxTemplate = templateProducts.concat(_template);

		const TemplateSinDuplicados = auxTemplate.reduce(
			(acumulador, valorActual) => {
				const elementoYaExiste = acumulador.find(
					(elemento) => elemento.id === valorActual.id
				);
				if (elementoYaExiste) {
					return acumulador.map((elemento) => {
						if (elemento.id === valorActual.id) {
							return {
								...elemento,
								quantity: elemento.quantity + valorActual.quantity,
							};
						}
						return elemento;
					});
				}

				return [...acumulador, valorActual];
			},
			[]
		);

		setTemplateProducts(
			TemplateSinDuplicados.sort((a, b) => {
				const titleA = a.title.toLowerCase();
				const titleB = b.title.toLowerCase();
				if (titleA < titleB) {
					return -1;
				}
				if (titleA > titleB) {
					return 1;
				}

				return 0;
			})
		);
	};

	const handleAddAllProductsToTemplate = async (allChecked) => {
		if (allChecked) {
			const auxTemplate = templateProducts.concat(originalData);

			const TemplateSinDuplicados = auxTemplate.reduce(
				(acumulador, valorActual) => {
					const elementoYaExiste = acumulador.find(
						(elemento) => elemento.id === valorActual.id
					);
					if (elementoYaExiste) {
						return acumulador.map((elemento) => {
							if (elemento.id === valorActual.id) {
								return {
									...elemento,
									quantity: elemento.quantity + valorActual.quantity,
								};
							}
							return elemento;
						});
					}

					return [...acumulador, valorActual];
				},
				[]
			);

			setTemplateProducts(
				TemplateSinDuplicados.sort((a, b) => {
					const titleA = a.title.toLowerCase();
					const titleB = b.title.toLowerCase();
					if (titleA < titleB) {
						return -1;
					}
					if (titleA > titleB) {
						return 1;
					}

					return 0;
				})
			);
		} else {
			setTemplateProducts([]);
		}
	};

	const handleDelete = (index) => {
		const _template = templateProducts.filter((p, idx) => idx !== index);
		setTemplateProducts(_template);
	};

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		setBrandId(null);
		const filteredData = originalData.filter((product) =>
			product.title.toLowerCase().includes(e.target.value.toLowerCase())
		);
		setData(filteredData);
	};

	const debouncedEventHandler = useMemo(
		() => debounce(eventHandler, 250),
		[originalData]
	);

	const handleSelectBrand = (id) => {
		if (id) {
			setBrandId(id);
			const filteredData = originalData.filter(
				(product) => product.brandId === id
			);
			setData(filteredData);
		} else {
			setBrandId(null);
			const filteredData = originalData.filter((product) =>
				product.title.toLowerCase().includes("")
			);
			setData(filteredData);
		}
	};

	const handleChange = (value, type) => {
		setLoading(true);
		setSelectedClient(value);
		setLoading(false);
		setLoading(false);
	};

	return (
		<Inner>
			<OptionSection>
				<Checkbox
					onChange={(e) => {
						setAllProductsIsActive(e.target.checked);
						handleAddAllProductsToTemplate(e.target.checked);
					}}
					checked={allProductsIsActive}
				>
					{!allProductsIsActive
						? "Agregar todos los productos"
						: "Quitar todos los productos"}
				</Checkbox>
				{isMultiLab && (
					<ClientContainer>
						<AntdSelector
							showSearch
							placeholder="Buscar laboratorio"
							optionFilterProp="children"
							onChange={(e) => handleChange(e)}
							value={selectedClient?.length > 0 ? selectedClient : []}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={clients}
							style={{ width: 200 }}
						/>
					</ClientContainer>
				)}
				<SearchContainer>
					<AntdSearch
						allowClear
						placeholder="Buscar productos"
						width={390}
						height={33}
						onChange={(e) => {
							setSearchByStore(e.target.value);
							debouncedEventHandler(e);
						}}
						value={searchByStore}
						disabled={loading}
					/>
				</SearchContainer>
			</OptionSection>
			<Container>
				<Section style={{ flex: "1 1 15%" }}>
					<ProductTable
						data={brandList}
						columns={brandColumns}
						loading={loading}
					/>
				</Section>
				<Section
					style={{
						flex: "1 1 35%",
						justifyContent: "center",
						flexDirection: "column",
					}}
				>
					<ProductTable
						data={data}
						columns={productColumns}
						loading={loading}
					/>
				</Section>
				<Section>
					<ProductTable data={templateProducts} onDelete={handleDelete} />
				</Section>
			</Container>
		</Inner>
	);
}

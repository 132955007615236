import React, { useContext, useState, useEffect } from "react";
import { CONSTANTS } from "../utils/constants";
import { useAuth } from "./authContext";

const FilterContext = React.createContext();

export function useFilter() {
	return useContext(FilterContext);
}

export function FilterProvider({ children }) {
	const { user, userdata } = useAuth();
	const [selectedDistributor, setSelectedDistributor] = useState(null);
	const [selectedLaboratory, setSelectedLaboratory] = useState(null);
	const [selectedPharmacy, setSelectedPharmacy] = useState(null);
	const [selectedPointOfSale, setSelectedPointOfSale] = useState(null);
	const [selectedProvince, setSelectedProvince] = useState(null);
	const [selectedLocality, setSelectedLocality] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [selectedBrand, setSelectedBrand] = useState(null);
	const [selectedChain, setSelectedChain] = useState(null);
	const [rangeDate, setRangeDate] = useState(null);
	const [dateFrom, setDateFrom] = useState(null);
	const [dateTo, setDateTo] = useState(null);
	const [mobilePeriod, setMobilePeriod] = useState(CONSTANTS.PERIOD_CURRENT);
	const [selectedFilters, setSelectedFilters] = useState([]);
	const [featuredProductIndexFilter, setFeaturedProductIndexFilter] = useState(
		CONSTANTS.FEATURED_CATALOG
	);
	const [marketOrdersLaboratoryId, setMarketOrdersLaboratoryId] =
		useState(null);
	const [marketOrdersDistributorId, setMarketOrdersDistributorId] =
		useState(null);
	const [marketOrdersPharmacyId, setMarketOrdersPharmacyId] = useState(null);
	const [marketOrdersPointOfSaleId, setMarketOrdersPointOfSaleId] =
		useState(null);
	const [marketOrdersSellerId, setMarketOrdersSellerId] = useState(null);
	const [marketOrdersProvinceName, setMarketOrdersProvinceName] =
		useState(null);
	const [marketOrdersTypeOfSale, setMarketOrdersTypeOfSale] = useState(null);
	const [marketOrdersOrderState, setMarketOrdersOrderState] = useState(null);
	const [marketOrdersOrdersRange, setMarketOrdersOrdersRange] = useState(null);
	const [marketOrdersOrdersFrom, setMarketOrdersOrdersFrom] = useState(null);
	const [marketOrdersOrdersTo, setMarketOrdersOrdersTo] = useState(null);
	const [selectedTypeOfSale, setSelectedTypeOfSale] = useState(null);
	const [selectedInitiative, setSelectedInitiative] = useState(null);
	const [selectedSeller, setSelectedSeller] = useState(
		userdata.isOneMarket ||
			(userdata.isSeller &&
				(userdata.isAdmin ||
					(!userdata.p_shop && !userdata.p_mobile && userdata.dashboard)))
			? null
			: user.uid
	);
	const [selectedSellerList, setSelectedSellerList] = useState(
		userdata.isOneMarket ||
			(userdata.isSeller &&
				(userdata.isAdmin ||
					(!userdata.p_shop && !userdata.p_mobile && userdata.dashboard)))
			? []
			: user.uid
	);
	const [sellerDashboardDisplay, setSellerDashboardDisplay] = useState(
		CONSTANTS.DASHBOARD_SALES
	);
	const [ordersLabSellerId, setOrdersLabSellerId] = useState(null);
	const [adminUsersType, setAdminUsersType] = useState(CONSTANTS.USERS_MOBILE);
	const [marketUsersType, setMarketUsersType] = useState(
		CONSTANTS.USERS_MOBILE
	);
	const [generalConfigOT, setGeneralConfigOT] = useState(null);
	const [generalConfigLab, setGeneralConfigLab] = useState(null);
	const [userRol, setUserRol] = useState(null);
	const [userSellerId, setUserSellerId] = useState(null);
	const [configType, setConfigType] = useState(CONSTANTS.CONFIG_MOBILE);
	const [sudoLabUser, setSudoLabUser] = useState(null);
	const [sudoLabClient, setSudoLabClient] = useState(null);

	const value = {
		selectedDistributor,
		setSelectedDistributor,
		selectedLaboratory,
		setSelectedLaboratory,
		selectedPharmacy,
		setSelectedPharmacy,
		selectedPointOfSale,
		setSelectedPointOfSale,
		selectedProvince,
		setSelectedProvince,
		selectedLocality,
		setSelectedLocality,
		selectedCategory,
		setSelectedCategory,
		rangeDate,
		setRangeDate,
		dateFrom,
		setDateFrom,
		dateTo,
		setDateTo,
		mobilePeriod,
		setMobilePeriod,
		selectedFilters,
		setSelectedFilters,
		featuredProductIndexFilter,
		setFeaturedProductIndexFilter,
		marketOrdersLaboratoryId,
		setMarketOrdersLaboratoryId,
		marketOrdersDistributorId,
		setMarketOrdersDistributorId,
		marketOrdersPharmacyId,
		setMarketOrdersPharmacyId,
		marketOrdersPointOfSaleId,
		setMarketOrdersPointOfSaleId,
		marketOrdersSellerId,
		setMarketOrdersSellerId,
		marketOrdersTypeOfSale,
		marketOrdersProvinceName,
		setMarketOrdersProvinceName,
		setMarketOrdersTypeOfSale,
		marketOrdersOrderState,
		setMarketOrdersOrderState,
		marketOrdersOrdersFrom,
		setMarketOrdersOrdersFrom,
		marketOrdersOrdersTo,
		setMarketOrdersOrdersTo,
		marketOrdersOrdersRange,
		setMarketOrdersOrdersRange,
		selectedTypeOfSale,
		setSelectedTypeOfSale,
		selectedSeller,
		setSelectedSeller,
		sellerDashboardDisplay,
		setSellerDashboardDisplay,
		ordersLabSellerId,
		setOrdersLabSellerId,
		adminUsersType,
		setAdminUsersType,
		marketUsersType,
		setMarketUsersType,
		generalConfigOT,
		setGeneralConfigOT,
		generalConfigLab,
		setGeneralConfigLab,
		userRol,
		setUserRol,
		userSellerId,
		setUserSellerId,
		configType,
		setConfigType,
		selectedChain,
		setSelectedChain,
		selectedBrand,
		setSelectedBrand,
		selectedInitiative,
		setSelectedInitiative,
		sudoLabUser,
		setSudoLabUser,
		sudoLabClient,
		setSudoLabClient,
		selectedSellerList,
		setSelectedSellerList,
	};

	return (
		<FilterContext.Provider value={value}>{children}</FilterContext.Provider>
	);
}

import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Form, Input, Row, Col, Select, Typography, Modal } from "antd";
import Main from "../../../../layout/main/Main";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import {
	AntdCheckbox,
	AntdModal,
	ClientData,
	InnerForm,
	ModalText,
	PublishSettings,
	SectionData,
	Container,
	UncheckedOrderAlert,
	CuitInfoContainer,
	Section,
	AntdButton,
} from "./styles";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import DistributorsSelector from "../../../../common/distributos-selector/DistributorsSelector";
import { COLORS, Typo } from "../../../../layout/theme";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import {
	update_approved,
	update_client,
} from "../../../../../api/endpoints/clients";
import { create_history_approval } from "../../../../../api/endpoints/historyApprovals";
import { useAuth } from "../../../../../contexts/authContext";
import {
	get_cuit_info,
	send_approved_mobile_request_email,
} from "../../../../../api/endpoints/auth";
import { get_order_request_by_id } from "../../../../../api/endpoints/orders";
import { get_sale_departments } from "../../../../../api/endpoints/saledepartments";
import ClientCard from "./components/client-card/ClientCard";
import { GoAlert } from "react-icons/go";
import PointOfSaleCard from "./components/point-of-sale-card/PointOfSaleCard";
import OrderCard from "./components/order-card/OrderCard";
import {
	update_general_pos_by_cuit_and_address,
	update_point_of_sale,
} from "../../../../../api/endpoints/pointsofsale";
import { CONSTANTS } from "../../../../../utils/constants";
import { validateCUIT } from "../../../../../utils/cuitValidator";

const { Option } = Select;
const { confirm } = Modal;
const { Paragraph } = Typography;

const fetch_sale_departments = async (term) => {
	const res = await get_sale_departments(term);

	return res.map((r) => ({
		label: r.name,
		value: r.id,
	}));
};

export default function UpdateMobileRequest() {
	const { id } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const { updateRequestValidation, userdata } = useAuth();

	const stateValues = Form.useWatch(["state"], form);
	const orderNumberValue = Form.useWatch(["orders", "orderNumId"], form);
	const orderSellerValue = Form.useWatch(["sellerName"], form);
	const orderLaboratoryNameValue = Form.useWatch(
		["orders", "orderLaboratoryName"],
		form
	);

	const [submitting, setSubmitting] = useState(false);
	const [distributors, setDistributors] = useState(null);
	const [saleDepartments, setSaleDepartments] = useState(null);
	const [initialValues, setInitialValues] = useState(null);
	const [idProvincia, setIdProvincia] = useState(null);
	const [provincia, setProvincia] = useState(null);
	const [localidad, setLocalidad] = useState();
	const [imageUrl, setImageUrl] = useState();
	const [stateList, setStateList] = useState([]);
	const [showPointOfSaleModal, setShowPointOfSaleModal] = useState(false);
	const [showOrderModal, setShowOrderModal] = useState(false);
	const [orderItems, setOrderItems] = useState([]);
	const [photoGallery, setPhotoGallery] = useState([]);
	const [orderCheck, setOrderCheck] = useState(false);
	const [loading, setLoading] = useState(false);
	const [afipData, setAfipData] = useState(null);
	const [isCuitValid, setIsCuitValid] = useState(null);

	useEffect(() => {
		const fetch_client_by_id = async () => {
			const data = await get_order_request_by_id(id);
			const order = data.order;

			if (!data.client.users[0].approved && !data.client.deleted) {
				setStateList([
					{
						id: "APPROVED",
						name: "Aprobada",
					},
					{
						id: "REJECTED",
						name: "Rechazada",
					},
					{
						id: "PENDING",
						name: "Pendiente",
					},
				]);
			} else {
				setStateList([
					{
						id: "APPROVED",
						name: "Aprobada",
					},
					{
						id: "REJECTED",
						name: "Rechazada",
					},
				]);
			}

			const client = {
				name: data.client.name,
				businessName: data.client.businessName,
				cuit: data.client.cuit,
				address: data.client.address,
				postalCode: data.client.postalCode,
				glnAnmat: data.client.glnAnmat,
				isSeller: data.client.isSeller,
				isChain: data.client.isChain,
				contactEmail: data.client.contactEmail,
				contactFirstName: data.client.contactName.split(/(\s+)/)[0],
				contactLastName: data.client.contactName.split(/(\s+)/)[2],
				phone: data.client.contactPhone.slice(
					3,
					data.client.contactPhone.length
				),
				phoneAreaCode: data.client.contactPhone.slice(0, 2),
				contactId: data.client.users ? data.client.users[0].id : null,
				provinceName: data.region?.provinceName,
				localityName: data.region?.localityName,
				firstUserApproved: data.client.users
					? data.client.users[0].approved
					: null,
				deleted: data.client.deleted,
				orders: {
					...order,
					orderNumId:
						order.items[0]?.orderNumMobile > 0
							? order.items[0]?.orderNumMobile
							: order.items[0]?.orderNumId,
					orderLaboratoryName: order.items[0]?.product?.client?.name,
					orderDistributorName: order.items[0]?.distributor?.name,
					orderUserName: order.user.firstName + " " + order.user.lastName,
					orderUserEmail: order.user.email,
				},
				pointOfSale: order?.pointOfSale,
				state: data.client.users[0].approved
					? "APPROVED"
					: data.client.deleted
					? "REJECTED"
					: "PENDING",
				sellerName:
					data.sellerData?.firstName + " " + data.sellerData?.lastName,
			};

			setOrderItems({ items: order.items.map((i) => ({ ...i, key: i.id })) });

			setDistributors(
				data.distributors.map((d) => ({ ...d, checked: d.selected, key: d.id }))
			);

			setSaleDepartments(
				data.client.saleDepartments?.map((i) => ({
					value: i.saleDepartment.id,
					label: i.saleDepartment.name,
				}))
			);

			setProvincia(
				order.pointOfSale.locality_Codes[0]
					? {
							key: order?.pointOfSale.locality_Codes[0]?.locality.province[0]
								?.provinceId,
							value:
								order?.pointOfSale.locality_Codes[0]?.locality.province[0]
									?.provinceId,
							label:
								order?.pointOfSale.locality_Codes[0]?.locality.province[0]
									?.province.title,
					  }
					: null
			);

			setLocalidad(
				order?.pointOfSale.locality_Codes.length !== 0
					? [
							{
								key: order?.pointOfSale.locality_Codes[0].localityId,
								value: order?.pointOfSale.locality_Codes[0].localityId,
								label: order?.pointOfSale.locality_Codes[0].locality.title,
							},
					  ]
					: undefined
			);
			setIdProvincia(
				order?.pointOfSale.locality_Codes[0]
					? order?.pointOfSale.locality_Codes[0]?.locality.province[0]
							?.provinceId
					: null
			);

			setImageUrl(data.client.photoUrl);

			setInitialValues(client);
		};

		if (id) {
			fetch_client_by_id();
		}
	}, []);

	const onFinish = async (values) => {
		const continueWithApproval = async () => {
			try {
				const requestState = {
					id: id,
					userId: initialValues.contactId,
					approve: values.state === "APPROVED" ? true : false,
					deleted: values.state === "REJECTED" ? true : false,
					orderState:
						values.state === "APPROVED"
							? "Active"
							: values.state === "REJECTED"
							? "Canceled"
							: "Pending",
				};
				const _history = {
					clientId: id,
					userId: values.contactId,
					changedState: values.state,
					approvalStatus: values.state === "APPROVED" ? true : false,
				};

				await update_approved(requestState);
				await updateRequestValidation(
					requestState.userId,
					requestState.approve
				);
				await create_history_approval(_history);
				// if (requestState.approve)
				// 	await send_approved_mobile_request_email(
				// 		values.contactEmail,
				// 		orderSellerValue,
				// 		orderLaboratoryNameValue
				// 	);
				openNotificationWithIcon(
					TYPE.SUCCESS,
					"La farmacia se editó con éxito"
				);
				navigate(`/admin/mobileRequests`);
			} catch (error) {
				console.error(error);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Error al actualizar la farmacia",
					"Un error ocurrió al intentar actualizar la farmacia, contacte con soporte a soporte@checkpos.com."
				);
			} finally {
				setSubmitting(false);
			}
		};

		if (!!isCuitValid) {
			try {
				// const postData = {
				// 	id: id,
				// 	userId: initialValues.contactId,
				// 	approve: false,
				// 	deleted: false,
				// 	orderState: "Pending",
				// };
				// await update_approved(postData);
				// await updateRequestValidation(postData.userId, postData.approve);

				setSubmitting(true);

				const newDistributor = values.distributors.map((d) =>
					d.checked === true ? { ...d, code: "si" } : { ...d, code: null }
				);

				const clientData = {
					id: id,
					name: values.name,
					cuit: values.cuit,
					businessName: values.businessName,
					isSeller: false,
					photoUrl: values.photoUrl,
					address: values.address,
					postalCode: values.postalCode,
					deleted: values.state === "REJECTED" ? true : false,
					contactEmail: values.contactEmail,
					contactName: values.contactFirstName + " " + values.contactLastName,
					contactPhone: `${values.phoneAreaCode}${values.phone}`,
					distributors: newDistributor,
					megaDistributorId: null,
					activeBannerStore: false,
					existingPhotoGallery: photoGallery,
					typeOfSale: values.typeOfSale,
					generalDiscount: values.generalDiscount,
					contactId: initialValues.contactId,
					isChain: initialValues.isChain,
					//saleDepartments: saleDepartments?.map((i) => ({ id: i.value })),
				};

				const pointOfSaleData = {
					id: initialValues.orders.pointOfSaleId,
					name: values.pointOfSale.name ? values.pointOfSale.name : values.name,
					address: values.pointOfSale.address
						? values.pointOfSale.address
						: values.address,
					postalCode:
						values.pointOfSale.postalCode === undefined ||
						values.pointOfSale.postalCode === null
							? values.postalCode
							: values.pointOfSale.postalCode,
					contactName:
						values.pointOfSale.contactName === undefined ||
						values.pointOfSale.contactName === null
							? values.contactFirstName
							: values.pointOfSale.contactName,
					contactEmail:
						values.pointOfSale.contactEmail === undefined ||
						values.pointOfSale.contactEmail === null
							? values.contactEmail
							: values.pointOfSale.contactEmail,
					contactPhone: `${values.phoneAreaCode}${values.phone}`,
					cuit:
						values.pointOfSale.cuit === undefined ||
						values.pointOfSale.cuit === null
							? values.cuit
							: values.pointOfSale.cuit,
					businessName:
						values.pointOfSale.businessName === undefined ||
						values.pointOfSale.businessName === null
							? values.businessName
							: values.pointOfSale.businessName,
					distributors: distributors?.map((d) => ({
						id: d.id,
						code: d.code,
					})),
					localities: [{ localityId: localidad[0].value, pointOfSaleId: id }],
					pointOfSaleRegion: `ARG-${idProvincia}-${localidad[0].value}`,
					userId: initialValues.orders.user.id,
				};

				const generalPosData = {
					predefinedCuit: initialValues.cuit,
					predefinedAddress: initialValues.address,
					requestPosCuit: values.cuit ? values.cuit : values.pointOfSale.cuit,
					requestPosAddress: values.address
						? values.address
						: values.pointOfSale.address,
					requestPosName: values.name ? values.name : values.pointOfSale.name,
					requestPosBusinessName: values.businessName
						? values.businessName
						: values.pointOfSale.businessName,
					requestPosEmail: values.contactEmail
						? values.contactEmail
						: values.pointOfSale.contactEmail,
					requestPosPhone: `${values.phoneAreaCode}${values.phone}`,
					requestPosSellerId: initialValues.sellerData?.id,
				};

				const formData = new FormData();
				formData.append(
					"file",
					values.file?.fileList?.slice(-1)[0]?.originFileObj
				);
				photoGallery.forEach((f) => {
					formData.append("files", f.originFileObj);
				});
				formData.append("data", JSON.stringify(clientData));

				await update_client(id, formData);

				await update_point_of_sale(
					initialValues.orders.pointOfSaleId,
					pointOfSaleData
				);

				await update_general_pos_by_cuit_and_address(generalPosData);

				if (values.state === "PENDING") {
					openNotificationWithIcon(
						TYPE.SUCCESS,
						"La farmacia se actualizó con éxito"
					);
					navigate(`/admin/mobileRequests`);
				} else if (values.state === "APPROVED") {
					confirm({
						title: "Confirmación de aprobación",
						content: (
							<div>
								{isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR && (
									<Paragraph>
										<strong>El Cuit no posee una estructura válida</strong>
									</Paragraph>
								)}
								{isCuitValid === CONSTANTS.CUIT_STRUCTURE_OK &&
									afipData?.condicionIVA !== CONSTANTS.AFIP_STATUS_OK && (
										<Paragraph>
											<strong>
												Este Cuit no corresponde a un Responsable Inscripto
											</strong>
										</Paragraph>
									)}
								{values.businessName !== afipData?.razonSocial &&
									afipData?.condicionIVA === CONSTANTS.AFIP_STATUS_OK && (
										<Paragraph>
											<strong>Razón Social:</strong> {values.businessName}{" "}
											<br />
											<strong>Dato Afip:</strong> {afipData?.razonSocial}
										</Paragraph>
									)}
								{values.address !== afipData?.fiscalDireccion &&
									afipData?.condicionIVA === CONSTANTS.AFIP_STATUS_OK && (
										<Paragraph>
											<strong>Dirección:</strong> {values.address} <br />
											<strong>Dato Afip:</strong> {afipData?.fiscalDireccion}
										</Paragraph>
									)}
								{values.postalCode !== afipData?.fiscalCodPostal &&
									afipData?.condicionIVA === CONSTANTS.AFIP_STATUS_OK && (
										<Paragraph>
											<strong>CP:</strong> {values.postalCode} <br />
											<strong>Dato Afip:</strong> {afipData?.fiscalCodPostal}
										</Paragraph>
									)}
								{isCuitValid === CONSTANTS.CUIT_STRUCTURE_OK &&
									afipData?.condicionIVA === CONSTANTS.AFIP_STATUS_OK &&
									values.businessName === afipData?.razonSocial &&
									values.address === afipData?.fiscalDireccion &&
									values.postalCode === afipData?.fiscalCodPostal && (
										<Paragraph>
											<strong>No hay advertencias</strong>
										</Paragraph>
									)}
							</div>
						),
						okText: "Confirmar",
						okType: "danger",
						cancelText: "Cancelar",
						onOk: continueWithApproval,
						onCancel: () => {
							setSubmitting(false);
							return;
						},
					});
				} else {
					await continueWithApproval();
				}
			} catch (error) {
				console.log(error);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Error al actualizar la farmacia",
					"Un error ocurrio al intentar actualizar la farmacia, contacte con soporte a soporte@checkpos.com."
				);
			} finally {
				setSubmitting(false);
			}
		} else {
			alert("Debe validar el Cuit antes de continuar");
			setSubmitting(false);
			return;
		}
	};

	const handleClick = () => {
		setShowPointOfSaleModal(true);
	};

	const handleOrderClick = () => {
		setShowOrderModal(true);
	};

	const handleOk = () => {
		setShowPointOfSaleModal(false);
		setShowOrderModal(false);
	};

	const handleCancel = () => {
		setShowPointOfSaleModal(false);
		setShowOrderModal(false);
	};

	const orderCheckHandler = () => {
		setOrderCheck((value) => !value);
	};

	const handleBack = () => {
		navigate(`/admin/mobileRequests`);
	};

	const fetch_afip_check = async (cuit) => {
		const data = await get_cuit_info(cuit);
		setAfipData(data?.cuitInfo);
	};

	const handleCuitCheck = () => {
		setLoading(true);
		try {
			if (validateCUIT(initialValues.cuit)) {
				setIsCuitValid(CONSTANTS.CUIT_STRUCTURE_OK);
				fetch_afip_check(initialValues.cuit);
			} else {
				setIsCuitValid(CONSTANTS.CUIT_STRUCTURE_ERROR);
			}
		} catch (error) {
			console.log(error);
			setIsCuitValid(CONSTANTS.CUIT_STRUCTURE_ERROR);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to="/admin/mobileRequests">Solicitudes Mobile</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Editar Solicitud
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				{!initialValues ? (
					<LoadingSkeleton />
				) : (
					<Form
						form={form}
						name="basic"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
					>
						<InnerForm>
							<ClientData>
								<SectionData>
									<ClientCard
										form={form}
										imageUrl={imageUrl}
										setImageUrl={setImageUrl}
										saleDepartments={saleDepartments}
										setSaleDepartments={setSaleDepartments}
										fetch_sale_departments={fetch_sale_departments}
										isCuitValid={isCuitValid}
										afipData={afipData}
									/>
								</SectionData>
								<SectionData>
									<Row>
										<Col span={22}>
											<Form.Item name="distributors">
												<DistributorsSelector
													onChange={setDistributors}
													initialValues={distributors}
													noBuyer={initialValues.isSeller}
												/>
											</Form.Item>
										</Col>
									</Row>
								</SectionData>
							</ClientData>
							<PublishSettings>
								<SectionData>
									<Typo level={5} type="primary">
										Estado
									</Typo>
									<Form.Item
										label={
											<label
												style={{ color: COLORS.Primary, fontWeight: "600" }}
											>
												Solicitud de Farmacia
											</label>
										}
										name="state"
										rules={[
											{
												required: true,
												message: "Este campo es obligatorio",
											},
										]}
									>
										<Select
											allowClear={true}
											placeholder="Estado de la farmacia"
										>
											{stateList.map((item) => (
												<Option key={item.id} value={item.id}>
													{item.name}
												</Option>
											))}
										</Select>
									</Form.Item>

									<Form.Item>
										{initialValues.state === "PENDING" ? (
											<SignInTemplate.AntdButton
												type="Primary"
												color="white"
												bg="Primary"
												htmlType="submit"
												loading={submitting}
												block
												disabled={
													stateValues === "PENDING"
														? false
														: orderCheck
														? false
														: true
												}
											>
												{stateValues === "APPROVED"
													? "Aprobar Cliente y Pedido"
													: stateValues === "REJECTED"
													? "Rechazar Cliente y Pedido"
													: "Guardar Cambios"}
											</SignInTemplate.AntdButton>
										) : (
											<SignInTemplate.AntdButton
												type="Primary"
												color="white"
												bg="Primary"
												onClick={handleBack}
												//htmlType="submit"
												//loading={submitting}
												block
											>
												Volver
											</SignInTemplate.AntdButton>
										)}
									</Form.Item>
								</SectionData>
								<SectionData>
									<AntdButton onClick={handleCuitCheck} loading={loading}>
										Verificar Cuit
									</AntdButton>
									{isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR && (
										<Typo level={6}>No es una estructura de Cuit válida</Typo>
									)}
									{isCuitValid === CONSTANTS.CUIT_STRUCTURE_OK && afipData && (
										<CuitInfoContainer>
											<Section>
												<Typo type="primary" level={6}>
													Condición:{" "}
												</Typo>
												<Typo
													type={
														afipData.condicionIVA === CONSTANTS.AFIP_STATUS_OK
															? "success"
															: "warning"
													}
													level={6}
												>
													{afipData.condicionIVA}
												</Typo>
											</Section>
											<Section>
												<Typo type="primary" level={6}>
													Cuit:{" "}
												</Typo>
												<Typo level={6}>{afipData.cuit}</Typo>
											</Section>
											<Section>
												<Typo type="primary" level={6}>
													Razón Social:{" "}
												</Typo>
												<Typo level={6}>{afipData.razonSocial}</Typo>
											</Section>
											<Section>
												<Typo type="primary" level={6}>
													Dirección Fiscal:{" "}
												</Typo>
												<Typo level={6}>{afipData.fiscalDireccion}</Typo>
											</Section>
											<Section>
												<Typo type="primary" level={6}>
													CP Fiscal:{" "}
												</Typo>
												<Typo level={6}>{afipData.fiscalCodPostal}</Typo>
											</Section>
										</CuitInfoContainer>
									)}
								</SectionData>
								<SectionData>
									<Typo level={5} type="primary">
										Punto de venta
									</Typo>
									<Form.Item
										label={
											<label
												style={{ color: COLORS.Primary, fontWeight: "600" }}
											>
												Nombre
											</label>
										}
										name={["pointOfSale", "name"]}
									>
										<Input />
									</Form.Item>
									<Form.Item
										label={
											<label
												style={{ color: COLORS.Primary, fontWeight: "600" }}
											>
												Dirección
											</label>
										}
										name={["pointOfSale", "address"]}
									>
										<Input />
									</Form.Item>
									<ModalText onClick={handleClick} textColor={1}>
										<GoAlert /> Ver información completa
									</ModalText>
									<AntdModal
										title={`Punto de venta`}
										visible={showPointOfSaleModal}
										onOk={handleOk}
										onCancel={handleCancel}
										width={600}
										centered={true}
										destroyOnClose={false}
									>
										<PointOfSaleCard
											form={form}
											distributors={distributors}
											setDistributors={setDistributors}
											idProvincia={idProvincia}
											setIdProvincia={setIdProvincia}
											provincia={provincia}
											setProvincia={setProvincia}
											localidad={localidad}
											setLocalidad={setLocalidad}
											id={id}
										/>
									</AntdModal>
								</SectionData>
								<SectionData>
									<Container style={{ marginBottom: 10 }}>
										<Typo level={5} type="primary">
											Pedido pendiente
										</Typo>
										{initialValues?.state === "PENDING" &&
											stateValues !== "PENDING" &&
											!orderCheck && (
												<UncheckedOrderAlert>{`confirmar pedido pendiente ->`}</UncheckedOrderAlert>
											)}
										<AntdCheckbox
											style={{
												color: COLORS.Secondary,
												fontWeight: "600",
											}}
											onClick={orderCheckHandler}
										></AntdCheckbox>
									</Container>
									<Container>
										<Typo level={6} type="primary">
											Número de pedido
										</Typo>
										<Form.Item
											name={["orders", "orderNumId"]}
											style={{ marginBottom: 10 }}
										>
											{orderNumberValue}
										</Form.Item>
									</Container>
									<Container>
										<Typo level={6} type="primary">
											Nombre vendedor
										</Typo>
										<Form.Item
											name={["sellerName"]}
											style={{ marginBottom: 10 }}
										>
											{orderSellerValue}
										</Form.Item>
									</Container>
									<Container>
										<Typo level={6} type="primary">
											Empresa
										</Typo>
										<Form.Item
											name={["orders", "orderLaboratoryName"]}
											style={{ marginBottom: 10 }}
										>
											{orderLaboratoryNameValue}
										</Form.Item>
									</Container>
									<ModalText onClick={handleOrderClick} textColor={1}>
										<GoAlert /> Ver información completa
									</ModalText>
									<AntdModal
										title={`Orden Pendiente`}
										visible={showOrderModal}
										onOk={handleOk}
										onCancel={handleCancel}
										width={600}
										centered={true}
										destroyOnClose={false}
									>
										<OrderCard form={form} initialValues={orderItems} />
									</AntdModal>
								</SectionData>
							</PublishSettings>
						</InnerForm>
					</Form>
				)}
			</Main.Body>
		</Main>
	);
}

import React from "react";
import { COLORS, Typo } from "../../../../../layout/theme";
import {
	AntdTable,
	BigChartContainer,
	Container,
	Dashboard1Container,
	FilterContainer,
	HeaderContainer,
	Section,
	TextContainer,
} from "./styles";
import { Button, DatePicker, Select } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import CustomAntdTooltip from "../../../../../common/custom-antd-tooltip/CustomAntdTooltip";
import Box from "../box/Box";
import { BiCoin, BiCoinStack, BiDollar } from "react-icons/bi";
import DebounceSelectSeller from "../../../../../common/debounce-select-seller/DebounceSelectSeller";

export default function InitiativeDashboard({
	dashboard,
	distributors,
	selectDistributor,
	provinces,
	selectProvince,
	handleDateChange,
	deleteFilters,
	isLoading,
	selectedDistributor,
	selectedProvince,
	rangeDate,
	selectedLocality,
	selectLocality,
	localities,
	selectedChain,
	selectChain,
	chains,
	selectedPointOfSale,
	selectPointOfSale,
	pointsOfSale,
	selectedCategory,
	selectCategory,
	categories,
	handleDownloadProductsTable,
	sellers,
	selectSeller,
	selectedSeller,
	userdata,
	initiatives,
	selectInitiative,
	selectedInitiative,
	productTotalvsInitiatives,
	timeInitiativesSalesGraph,
	timeInitiativesUnitsGraph,
	initiativeGraphSelector,
	selectInitiativeGraph,
}) {
	return (
		<Dashboard1Container>
			<FilterContainer>
				<Container>
					<Typo type="primary" level={6}>
						Fecha
					</Typo>
					{rangeDate ? (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							value={rangeDate}
							onChange={(e) => handleDateChange(e)}
						/>
					) : (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							onChange={(e) => handleDateChange(e)}
							placeholder={["Desde", "Hasta"]}
						/>
					)}
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Droguería
					</Typo>
					<Select
						showSearch
						placeholder="Droguería"
						optionFilterProp="children"
						value={selectedDistributor}
						onChange={(e, o) => selectDistributor(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={distributors}
						style={{ width: 140 }}
					/>
				</Container>
				{!selectedProvince && (
					<Container>
						<Typo type="primary" level={6}>
							Provincia
						</Typo>
						<Select
							showSearch
							placeholder="Provincia"
							optionFilterProp="children"
							value={selectedProvince}
							onChange={(e, o) => selectProvince(e, o)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={provinces}
							style={{ width: 160 }}
						/>
					</Container>
				)}
				{selectedProvince && (
					<Container>
						<Typo type="primary" level={6}>
							Localidad
						</Typo>
						<Select
							showSearch
							//allowClear
							placeholder="Localidad"
							optionFilterProp="children"
							value={selectedLocality}
							// defaultValue={selectedProvince ?? null}
							onChange={(e, o) => selectLocality(e, o)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={localities}
							style={{ width: 160 }}
						/>
					</Container>
				)}
				<Container>
					<Typo type="primary" level={6}>
						Cadena
					</Typo>
					<Select
						showSearch
						placeholder="Cadena"
						optionFilterProp="children"
						value={selectedChain}
						onChange={(e, o) => selectChain(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={chains}
						style={{ width: 120 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Punto de venta
					</Typo>
					<Select
						showSearch
						placeholder="Punto de venta"
						optionFilterProp="children"
						value={selectedPointOfSale}
						onChange={(e, o) => selectPointOfSale(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={pointsOfSale}
						style={{ width: 140 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Categoria
					</Typo>
					<Select
						showSearch
						placeholder="Categoria"
						optionFilterProp="children"
						value={selectedCategory}
						onChange={(e, o) => selectCategory(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={categories}
						style={{ width: 130 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Iniciativa
					</Typo>
					<Select
						showSearch
						placeholder="Iniciativa"
						optionFilterProp="children"
						value={selectedInitiative}
						onChange={(e, o) => selectInitiative(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={initiatives}
						style={{ width: 130 }}
					/>
				</Container>
				{!(!userdata.p_shop && userdata.p_mobile) && (
					<Container>
						<Typo type="primary" level={6}>
							Vendedor
						</Typo>
						<DebounceSelectSeller
							fetchCallback={sellers}
							placeholder="Vendedor"
							onValuesChange={selectSeller}
							initialValues={selectedSeller}
						/>
					</Container>
				)}
				<Container style={{ justifyContent: "flex-end", width: "160px" }}>
					<Button type="primary" onClick={deleteFilters}>
						Borrar filtros
					</Button>
				</Container>
			</FilterContainer>
			<HeaderContainer>
				<Box
					data={numberWithDotAndCommas((dashboard?.totalYearSales).toFixed(0))}
					dataPrefix={"$"}
					type="headerBoxInitiatives"
					title={
						<Typo texto="light" type="primary">
							Total ventas
						</Typo>
					}
				/>
				<Box
					data={numberWithDotAndCommas(
						(dashboard?.totalInitiativesSales).toFixed(0)
					)}
					dataPrefix={"$"}
					type="headerBoxInitiatives"
					title={
						<Typo texto="light" type="primary">
							Total iniciativa
						</Typo>
					}
				/>
				<Box
					data={numberWithDotAndCommas(
						(dashboard?.totalYearSales === 0
							? 0
							: (dashboard?.totalInitiativesSales / dashboard?.totalYearSales) *
							  100
						).toFixed(2)
					)}
					dataPrefix={"%"}
					type="headerBoxInitiatives"
					title={
						<Typo texto="light" type="primary">
							Porcentaje de participación
						</Typo>
					}
				/>
			</HeaderContainer>
			<BigChartContainer>
				<Box
					type="timeChart"
					options={productTotalvsInitiatives}
					isLoading={isLoading}
					title={
						<Typo texto="light" type="primary">
							Ventas vs iniciativas
						</Typo>
					}
				/>
			</BigChartContainer>
			<BigChartContainer>
				<Box
					type="timeChartMultipleProduct"
					options={timeInitiativesSalesGraph}
					iconOption={<BiDollar size={20} />}
					option2={timeInitiativesUnitsGraph}
					iconOption2={<BiCoinStack size={20} />}
					selectProductGraph={selectInitiativeGraph}
					productGraph={initiativeGraphSelector}
					isLoading={isLoading}
					title={
						<Typo texto="light" type="primary">
							{initiativeGraphSelector === "SALES"
								? "Histórico ventas"
								: initiativeGraphSelector === "UNITS"
								? "Histórico unidades"
								: ""}
						</Typo>
					}
				/>
			</BigChartContainer>
		</Dashboard1Container>
	);
}

import axiosInstance from "../axiosInstance";

const CONTROLLER = "clients";

const get_client = async (id) => {
	const response = await axiosInstance.get(CONTROLLER);
	return response.data;
};

const update_client = async (id, client) => {
	const response = await axiosInstance.put(`${CONTROLLER}/${id}`, client);
	return response.data;
};

const get_clients_page = async (search, state, page) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPage?search=${
			search || ""
		}&state=${state}&pageNumber=${page}`
	);
	return response.data;
};

const get_clients_laboratories_page = async (search, state, page) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetLaboratoriesPage?search=${
			search || ""
		}&state=${state}&pageNumber=${page}`
	);
	return response.data;
};

const get_clients_pharmacies_page = async (search, state, page) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPharmaciesPage?search=${
			search || ""
		}&state=${state}&pageNumber=${page}`
	);
	return response.data;
};

const get_clients_requests = async (search, state, page) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPageRequests?search=${
			search || ""
		}&state=${state}&pageNumber=${page}`
	);
	return response.data;
};

const get_clients_header_info = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetClientHeader`);
	return response.data;
};

const get_clients_request_header_info = async () => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetClientRequestHeader`
	);
	return response.data;
};

const get_clients = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetTotal`);
	return response.data;
};

const get_initial_percentage = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetPercentage`);
	return response.data;
};

const get_clients_name_list = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetNameListCT`);
	return response.data;
};

const get_clients_simplified = async (os) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetTotalShort?os=${os}`
	);
	return response.data;
};

const get_clients_simplified_filtered = async (os) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetTotalShortFiltered?os=${os}`
	);
	return response.data;
};

const get_client_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetById/${id}`);
	return response.data;
};

const get_client_contract = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetContract/${id}`);
	return response.data;
};

const create_client = async (client) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, client, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const update_approved = async (approved) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateApproved`,
		approved
	);
	return response.data;
};

const update_client_state = async (id, state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateClientState/${id}`,
		state
	);
	return response.data;
};

const update_client_store = async (id, store) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateStore/${id}`,
		store,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const update_client_contract = async (id, contract) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateContract/${id}`,
		contract,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const update_client_megaDistributor = async (data) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateClientMegaDistributor`,
		data
	);
	return response.data;
};

const update_client_tier = async (data) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateClientTier`,
		data
	);
	return response.data;
};

const update_client_isApproved = async (data) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateClientIsApproved`,
		data
	);
	return response.data;
};

const update_client_distributors = async (data) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateDistributors`,
		data
	);
	return response.data;
};

const get_clients_users = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetClientsUsers`);
	return response.data;
};

const get_laboratories = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetLaboratories`);
	return response.data;
};

const update_client_photo = async (id, client) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateClientPhoto/${id}`,
		client,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const update_client_distributor_list = async (updateObject) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateClientsDistributors`,
		updateObject
	);
	return response.data;
};

const get_pharmacies_with_totalPos_list_dapper = async (
	term,
	skip,
	take,
	state
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPharmaciesWithPosList`,
		{
			params: {
				searchTerm: term,
				skip: skip,
				take: take,
				state: state,
			},
		}
	);
	return response.data;
};

const export_pharmacies = async () => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/ExportPharmaciesList`,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

const get_client_list_dapper = async (
	searchTerm,
	state,
	clientType,
	province,
	locality,
	skip,
	page
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetClientListDapper`,
		{
			params: {
				searchTerm: searchTerm,
				state: state,
				clientType: clientType,
				province: province,
				locality: locality,
				skip: skip,
				page: page,
			},
		}
	);
	return response.data;
};

const update_client_pos = async (data) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateClientPos`,
		data
	);
	return response.data;
};

const get_clients_name_list_filtered = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetNameListFiltered`);
	return response.data;
};

const get_pharmacies_for_laboratory_seller = async (
	searchTerm,
	clientId,
	skip,
	page
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetLaboratorySellerPointsOfSale`,
		{
			params: {
				searchTerm: searchTerm,
				clientId: clientId,
				skip: skip,
				page: page,
			},
		}
	);
	return response.data;
};

const create_client_from_predefined_pointOfSale = async (pointOfSaleData) => {
	try {
		const response = await axiosInstance.post(
			`${CONTROLLER}/CreateClientFromPredefinedPos`,
			pointOfSaleData
		);

		return response.data;
	} catch (err) {
		return err.response.data;
	}
};

const get_clients_name_list_filtered_expo = async () => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetNameListFilteredExpo`
	);
	return response.data;
};

const get_pharmacies_for_expo_seller = async (searchTerm, skip, page) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetExpoSellerPointsOfSale`,
		{
			params: {
				searchTerm: searchTerm,
				skip: skip,
				page: page,
			},
		}
	);
	return response.data;
};

const get_client_name = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetName/${id}`);
	return response.data;
};

const update_client_contract_web_seller_route = async (id, object) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateClientContractWebSellerRoute/${id}`,
		object
	);
	return response.data;
};

const get_clients_name_list_filtered_coupon = async () => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetNameListFilteredCoupon`
	);
	return response.data;
};

const get_clients_mobile_configs = async () => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetClientMobileConfigs`
	);
	return response.data;
};

const create_client_mobile_config = async (object) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/CreateMobileConfig`,
		object
	);
	return response.data;
};

const update_client_mobile_config = async (id, client) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateClientMobileConfig/${id}`,
		client,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const get_clients_list_for_featureds = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetListForFeatureds`);
	return response.data;
};

const get_mobile_laboratories_list = async () => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetMobileLaboratories`
	);
	return response.data;
};

const get_pharmacies_for_laboratory_mobile_seller = async (
	searchTerm,
	clientId,
	userId,
	skip,
	page
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetLaboratoryMobileSellerPointsOfSale`,
		{
			params: {
				searchTerm: searchTerm,
				clientId: clientId,
				userId: userId,
				skip: skip,
				page: page,
			},
		}
	);
	return response.data;
};

const get_clients_simplified_checkpos = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetTotalShortCT`);
	return response.data;
};

const get_pharmacies_names_list = async () => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPharmaciesNameList`
	);
	return response.data;
};

const get_laboratories_for_header = async () => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetLaboratoriesForHeaderCT`
	);
	return response.data;
};

export {
	get_client,
	update_client,
	get_clients_page,
	get_clients_requests,
	get_clients_header_info,
	get_clients_request_header_info,
	get_clients,
	get_initial_percentage,
	create_client,
	get_client_by_id,
	update_approved,
	update_client_state,
	update_client_store,
	get_clients_laboratories_page,
	get_clients_simplified,
	update_client_contract,
	update_client_megaDistributor,
	get_clients_name_list,
	update_client_distributors,
	get_clients_pharmacies_page,
	get_clients_users,
	get_client_contract,
	get_laboratories,
	update_client_tier,
	update_client_isApproved,
	update_client_photo,
	update_client_distributor_list,
	get_pharmacies_with_totalPos_list_dapper,
	export_pharmacies,
	get_client_list_dapper,
	update_client_pos,
	get_clients_name_list_filtered,
	get_clients_simplified_filtered,
	get_pharmacies_for_laboratory_seller,
	create_client_from_predefined_pointOfSale,
	get_clients_name_list_filtered_expo,
	get_pharmacies_for_expo_seller,
	get_client_name,
	update_client_contract_web_seller_route,
	get_clients_name_list_filtered_coupon,
	get_clients_mobile_configs,
	create_client_mobile_config,
	update_client_mobile_config,
	get_clients_list_for_featureds,
	get_mobile_laboratories_list,
	get_pharmacies_for_laboratory_mobile_seller,
	get_clients_simplified_checkpos,
	get_pharmacies_names_list,
	get_laboratories_for_header,
};

import React, { useEffect } from "react";
import Main from "../../../layout/main/Main";
import { Link, useSearchParams } from "react-router-dom";
import { ChildrenContainer } from "./styles";
import MegaDistributorList from "./megaDistributor/list/MegaDistributorList";

export default function IndexPage() {
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Distribuidores</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<ChildrenContainer>
					<MegaDistributorList uri={uri} />
				</ChildrenContainer>
			</Main.Body>
		</Main>
	);
}
